import React from "react";
import { useState , useEffect} from "react";
import { useLocation } from "react-router-dom";
import { checkEmailIsAdmin } from "../../util/adminRequest";

export default function SignedInNavbar() {

  const currentPath = useLocation().pathname;

  const [adminButton, setAdminButton] = useState(<></>);

  function submitFilmInsert() {
    if (window.localStorage["accountType"] === "filmmaker" && !currentPath?.toLowerCase()?.includes('film-submission-form')) {
      return (
        <a href="/film-submission-form.html" className="nav-link-2 w-nav-link">
          <button>SUBMIT FILM</button>
        </a>
      );
    } else {
      return null;
    }
  }

  function adminLink() {
    checkEmailIsAdmin().then(isAdmin => {
      console.log('Promise', isAdmin);

      if (!currentPath?.toLowerCase()?.includes('admin') && isAdmin)
        setAdminButton(
          <a href="/admin/all-films"
            className="button-1 action-button-white action-button-blue w-button">
            ADMIN
          </a>
        );

      else
        setAdminButton(<></>);


    });
  }

  useEffect( ()=>{
    adminLink() ;
  }, []);

  return (
    <div
      data-collapse="medium"
      data-animation="default"
      data-duration="400"
      role="banner"
      className="navbar-custom navbar-home w-nav"
      style={{ marginTop: 0 }}
    >
      <a
        href="/index.html"
        aria-current="page"
        className="brand brand-2 w-nav-brand w--current"
      >
        <img
          src="/images/blue-festiv-logo-with-text.png"
          width="105"
          sizes="(max-width: 479px) 85px, 105px"
          srcset="/images/blue-festiv-logo-with-text-p-500.png 500w, /images/blue-festiv-logo-with-text-p-800.png 800w, /images/blue-festiv-logo-with-text-p-1080.png 1080w, /images/blue-festiv-logo-with-text.png 1280w"
          alt="festiv logo"
          className="image-33"
        />
      </a>
      <nav role="navigation" className="nav-menu w-nav-menu">
        {adminButton}
        {submitFilmInsert()}
        <a href="/account.html" className="link-block-11 w-inline-block">
          <img
            src="/images/user-icon.png"
            width="28"
            alt="user icon"
            className="image-13"
          />
          <div
            className="navbar-username"
            style={{ textTransform: "uppercase" }}
          >
            {localStorage["firstName"]}
          </div>
        </a>
      </nav>
      <div className="menu-button-3 w-nav-button">
        <div className="w-icon-nav-menu"></div>
      </div>
      <div className="submit-films-js w-embed w-script"></div>
    </div>
  );
}
