const GenerateFrontendUrl = ()=>{
    switch(process.env.REACT_APP_MODE){
        case 'staging': return "http://192.119.91.91:3000/";
        case 'production': return "https://festivmedia.com/";
        default: return "http://localhost:3000/";
    }
};
const GenerateBackendUrl = ()=>{
    switch(process.env.REACT_APP_MODE){
        case 'staging': return "http://192.119.91.91/";
        case 'production': return "https://dashboard.festivmedia.com/";
        default: return 'http://192.119.91.91/'; // "http://192.119.91.91/"; // 'http://127.0.0.1:8000/';
    }
};

export const FRONTEND_URL = GenerateFrontendUrl();
export const BACKEND_URL = GenerateBackendUrl();