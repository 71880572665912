import axios from "axios";
import { BACKEND_URL } from "../constants/Strings";

export async function checkEmailIsAdmin() {
  // confirm email is admin
  // return localStorage.getItem('email') === 'rc@cenedex.com';
  const email =  localStorage.getItem('email') ;

  if (email == null )
    return false;
  
  try{

    const response = await axios.post(
      // `${BACKEND_URL}api/films/${getIdParam()}`
      `${BACKEND_URL}api/admin`,
      {email : email }
    );
    // response.data;
    console.log('IsAdmin resposne', response);

    if (response.status === 200 && response?.data?.isAdmin === true) 
      return true ;

    else
      return false ; 

  }
  catch ( e ){
    console.log('Admin request error:' , e);
    return false ;
  }
}