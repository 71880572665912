import jwtDecode from "jwt-decode";
import SignedInNavbar from "./signed-in-navbar";
import SignedOutNavbar from "./signed-out-navbar";



export default function AppNavBar() {

  let authenticated;
  const token = window.localStorage.FBIdToken;

  if (token) {
    const decodedToken = jwtDecode(token);
    if (decodedToken.exp * 1000 < Date.now()) {
      // window.location.href = "/sign-in"
      authenticated = false;
    } else {
      authenticated = true;
    }
  } else {
    authenticated = false;
  }

  return authenticated ? <SignedInNavbar /> : <SignedOutNavbar />

}