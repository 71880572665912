import React, { useEffect } from "react";
import SignedOutNavbar from "../components/navbar/signed-out-navbar";
import Footer from "../components/footer";
export default function AboutUs() {
  useEffect(() => {
    window.addEventListener("scroll", function () {
      let navbar = document.getElementById("navbar-home-transparent");
      // Set starting position
      if (window.pageYOffset >= 60) {
        navbar.style.backgroundColor = "rgba(255,255,255,1)";
      } else {
        navbar.style.backgroundColor = "rgba(255,255,255,0)";
      }
    });
  });

  return (
    <div>
      <SignedOutNavbar></SignedOutNavbar>
      <div>
        <div className="extra-documents-container w-container">
          <div className="extra-documents-title-wrapper">
            <div className="div-block-54">
              <img
                src="images/festiv-logo-full-original.png"
                loading="lazy"
                sizes="100.00000762939453px"
                srcSet="/images/festiv-logo-full-original-p-500.png 500w, /images/festiv-logo-full-original-p-800.png 800w, /images/festiv-logo-full-original-p-1080.png 1080w, /images/festiv-logo-full-original-p-1600.png 1600w, /images/festiv-logo-full-original-p-2000.png 2000w, /images/festiv-logo-full-original.png 2499w"
                alt=""
                className="extra-documents-festiv-icon"
              />
            </div>
            <h1 className="extra-documents-page-title">Dreams, Meet Reality</h1>
          </div>
          <h2 className="extra-documents-small-header about-us-small-header">
            Mission
          </h2>
          <p className="extra-documents-text extra-documents-text-centered">
            Promoting Magnificent Concepts Thoughtfully Executed
          </p>
          <h2 className="extra-documents-small-header about-us-small-header">
            Vision
          </h2>
          <p className="extra-documents-text extra-documents-text-centered">
            Our 24/7 global film marketplace showcases amazing independent
            feature films, shorts and episodic content from the brightest
            filmmakers and creators from around the world.
          </p>
          {/* <a name="samuel-real"></a> */}
          <h2 className="extra-documents-small-header about-us-small-header">
            Purpose
          </h2>
          <p className="extra-documents-text extra-documents-text-centered">
            We provide a platform for filmmakers, distribution executives, and
            viewers to engage and build strong relationships. Each year we host
            interactive online panel discussions featuring filmmakers,
            screenwriters, actors, and business leaders from around the world
            sharing their insights on various industry topics and we will host
            an annual event where our top films are showcased on the big screen
            for live audiences.
          </p>
          <h2 className="extra-documents-small-header about-us-small-header">
             Selection Committee
          </h2>
          {/* <a name="allison-gibson"></a> */}
          <div className="w-layout-grid bio-grid bio-grid-flip">
            <div>
              <h2 className="advisor-name">Samuel Real</h2>
              <h6 className="advisor-occupation">
                Film Distribution Executive
              </h6>
              <p className="advisor-description">
                Samuel, as Vice President of Theatrical Distribution at Warner
                Bros Pictures, developed, implemented and supervised
                distribution strategies of Warner Bros. films in Latin America
                and the Caribbean. He not only worked on every noteworthy WB
                franchise including; the <em>DC Universe</em>,{" "}
                <em>Conjuring Universe</em>, <em>Harry Potter</em>,{" "}
                <em>Lord of the Rings</em>/<em>Hobbit</em>, Christopher Nolan’s{" "}
                <em>Batman</em> trilogy, and <em>The Matrix t</em>rilogy, but
                also on specialty/alternative/targeted films, such as &quot;
                <em>
                  Crazy Rich Asians&quot;, &quot;Goldfinch&quot;, &quot;Blinded
                  by the Light&quot;, &quot;Motherless Brooklyn&quot;{" "}
                </em>
                and every<em> </em>Clint Eastwood<em> </em>produced film over
                the past 25 years. As an industry leader in finding creative
                ways to release these films in cinemas, he managed the
                operations of 40 countries across Latin America, selecting the
                best distribution partners, vendors and agencies, allowing for
                the incredible growth of 350% over his 25 years at WB.
              </p>
            </div>
            <img
              src="images/samuel-real.png"
              loading="lazy"
              sizes="(max-width: 479px) 100vw, (max-width: 991px) 22vw, 218.80209350585938px"
              srcSet="/images/samuel-real-p-500.png 500w, /images/samuel-real.png 599w"
              alt="samuel-real"
            />
          </div>
          {/* <a name="larry-bates"></a> */}
          <div className="w-layout-grid bio-grid">
            <img
              src="images/allison-gibson.png"
              loading="lazy"
              alt="allison-gibson"
            />
            <div>
              <h2 className="advisor-name">Allison M. Gibson</h2>
              <h6 className="advisor-occupation">Writer, Producer</h6>
              <p className="advisor-description">
                Allison grew up in Houston, Texas and attended The University of
                Texas at Austin, where she earned a B.S. in Radio-TV-Film and an
                M.A. in Screenwriting. In Los Angeles she has written for over a
                dozen television series including “Parenthood”, “Home
                Improvement”, “Boy Meets World”, and MTV’s “Awkward.” She
                created the series “Reba” which ran for six seasons and is still
                running around the world in syndication. For the strong female
                role at the center of the series Reba McEntire earned a People’s
                Choice Award and Golden Globe Nomination. Currently, Allison is
                pursuing development in television and features.
              </p>
            </div>
          </div>
          {/* <a name="rachel-lewis"></a> */}
          <div className="w-layout-grid bio-grid bio-grid-flip">
            <div>
              <h2 className="advisor-name">Larry Bates</h2>
              <h6 className="advisor-occupation">Actor, Producer</h6>
              <p className="advisor-description">
                Larry is an award-winning actor whose work spans the Stage,
                Television and Big Screen with roles in HBO’s Emmy Award winning{" "}
                <em>Big Little Lies</em>, the Amazon Prime Original{" "}
                <em>The Romanoffs</em> written and directed by Matt Weiner, and
                most recently in the Ava DuVernay and Oprah Winfrey produced
                anthology series “Cherish The Day.&quot;
              </p>
            </div>
            <img
              src="images/larry-bates.png"
              loading="lazy"
              alt="larry-bates"
            />
          </div>
          {/* <a name="danielle-iman"></a> */}
          <div className="w-layout-grid bio-grid">
            <img
              src="images/rachel-lewis.png"
              loading="lazy"
              alt="rachel-lewis"
            />
            <div>
              <h2 className="advisor-name">Rachel Lewis</h2>
              <h6 className="advisor-occupation">Writer, Producer</h6>
              <p className="advisor-description">
                Rachel is a three-time Emmy nominated writer/producer living in
                Los Angeles. She has worked on several television shows, most
                notably Odd Squad (PBS), Bizaardvark (Disney Channel), and
                Helpsters (Apple TV+).{" "}
                <a href="http://www.jonandeddieproductions.com/">
                  www.jonandeddieproductions.com
                </a>
              </p>
            </div>
          </div>
          {/* <a name="brian-kimmet"></a> */}
          <div className="w-layout-grid bio-grid bio-grid-flip">
            <div>
              <h2 className="advisor-name">Danielle Iman</h2>
              <h6 className="advisor-occupation">Writer, Producer</h6>
              <p className="advisor-description">
                A 2018 graduate of the prestigious MFA Screenwriting Program at
                USC’s School of Cinematic Arts, Danielle started as a Writers’
                PA on ABC’s multiple Emmy Award winning primetime hit “Grey’s
                Anatomy” where she was later selected as a 2019 HBO All Access
                Writers Fellow. Following that selection, Danielle was a writer
                on Marvel’s Disney+ series “Moon Knight”. She recently completed
                her run as Co-Producer on “Pretty Little Liars: Original Sins”
                for HBO Max and is now a Producer on The CW’s “Riverdale”.
                <p />
              </p>
            </div>
            <img
              src="/images/danielle-iman.png"
              loading="lazy"
              alt="danielle-iman"
            />
          </div>
          {/* <a name="kobie-jackson"></a> */}
          <div className="w-layout-grid bio-grid">
            <img
              src="/images/brian-kimmet.png"
              loading="lazy"
              alt="brian-kimmet"
            />
            <div>
              <h2 className="advisor-name">Brian Kimmet</h2>
              <h6 className="advisor-occupation">Actor, Writer</h6>
              <p className="advisor-description">
                Brian is a Los Angeles-based actor, writer and dad. He earned
                his BFA and found his love of improv at The Theatre School at
                DePaul University in Chicago. Since in LA he has worked with
                various theatre companies including; Tim Robbins&#x27; The
                Actors Gang where performed in <em>Love&#x27;s Labours Lost</em>
                , <em>Our Town </em>and <em>1984</em>. Brian has been in huge
                studio movies like Clint Eastwood&#x27;s{" "}
                <em>Flags of our Fathers</em>, as well as festival favorites
                like 2016&#x27;s <em>Tilly</em>. He has graced the screen of
                every major network and streaming service including{" "}
                <em>NCIS</em> on CBS, <em>Heroes</em> on NBC, <em>Lucifer</em>{" "}
                on Netflix, <em>Feud</em> on FX, <em>Six Feet Under </em>on HBO
                and even hosting Hulu&#x27;s <em>The Morning After </em>for over
                2 years. Lately he has been working in all arenas of voiceover
                including video game credits like <em>BioShock Infinite</em>,{" "}
                <em>Hitman &amp; The Last of US Part II </em>and he was the
                voice of Harold throughout the Warner Bros. animated series{" "}
                <em>Bunnicula</em>.
              </p>
            </div>
          </div>
          {/* <a name="armand-vasquez"></a> */}
          <div className="w-layout-grid bio-grid bio-grid-flip">
            <div>
              <h2 className="advisor-name">Kobie Jackson</h2>
              <h6 className="advisor-occupation">Studio Executive, Producer</h6>
              <p className="advisor-description">
                Prior to joining the ranks at Disney+, Kobie leveraged
                industry-wide resources to lead brand management of select
                console and mobile gaming titles at The Walt Disney Company,
                with a focus on externally licensed games and
                vertically-produced original intellectual properties including;{" "}
                <em>Cars 2</em> and <em>Toy Story 3</em>. He has led global
                strategic brand and marketing plans for category leading
                evergreen franchises like <em>Star Wars</em>,{" "}
                <em>Spider-Man</em>, and <em>Avengers</em> and devised
                annualized franchise events like{" "}
                <em>Marvel Super Hero September</em>, and <em>Star Wars Day</em>{" "}
                and led direct to consumer product launches (e.g.{" "}
                <em>Playmation</em>). Prior to Disney, Kobie served as a
                Producer at MGM Interactive managing all aspects of franchise
                development within games and interactive entertainment for the
                James Bond global hits <em>Everything or Nothing</em> and{" "}
                <em>Quantum of Solace and </em>key MGM catalog properties
                including <em>Rocky Balboa</em> and <em>Stargate.</em>
              </p>
            </div>
            <img
              src="/images/kobie-jackson-photo.png"
              loading="lazy"
              sizes="(max-width: 479px) 21vw, (max-width: 991px) 22vw, 218.80209350585938px"
              srcSet="/images/kobie-jackson-photo-p-500.png 500w, /images/kobie-jackson-photo.png 803w"
              alt="kobie-jackson"
            />
          </div>
          <div className="w-layout-grid bio-grid">
            <img
              src="/images/armand-vasquez.png"
              loading="lazy"
              alt="armand-vasquez"
            />
            <div>
              <h2 className="advisor-name">Armand Vasquez</h2>
              <h6 className="advisor-occupation">Actor, Producer</h6>
              <p className="advisor-description">
                Armand is an actor who has been featured in television, film,
                and several national commercials. He’s recurred on Netflix’s{" "}
                <em>Gilmore Girls</em> reboot, with appearances on ABC’s{" "}
                <em>Modern Family</em>, Showtime’s <em>Masters of Sex</em>, and
                Fox’s <em>Brooklyn Nine-Nine</em>. With a training background in
                theatre, Armand is an alum of the University of Illinois in
                Urbana-Champaign as well as the CBS Diversity Comedy Showcase.
                An active member of SAG-AFTRA, he is a proud union performer.{" "}
              </p>
            </div>
          </div>
          <h2 className="extra-documents-small-header about-us-small-header">
            The Team: Advisory Board
          </h2>
          <div className="w-layout-grid bio-grid">
            <img
              src="/images/paul-culberg.png"
              loading="lazy"
              alt="paul-culberg"
            />
            <div>
              <h2 className="advisor-name">Paul Culberg</h2>
              <h6 className="advisor-occupation">
                Founder & CEO Lobo Creek Advisory, Inc.
              </h6>
              <p className="advisor-description">
                Paul was a principal in building Paramount Pictures’ original
                Home Video division creating the $22B Home Entertainment/Video
                industry, Paul, the former COO of Columbia Tri-Star Home Video
                (Sony Pictures) has been an innovator within the entertainment
                industry for decades. Paul currently sits on the boards of
                companies ranging from traditional distribution to e-commerce.
              </p>
            </div>
          </div>
          <div className="w-layout-grid bio-grid bio-grid-flip">
            <div>
              <h2 className="advisor-name">Samuel Real</h2>
              <h6 className="advisor-occupation">
                Film Distribution Executive
              </h6>
              <p className="advisor-description">
                Samuel, as Vice President of Theatrical Distribution at Warner
                Bros Pictures, developed, implemented and supervised
                distribution strategies of Warner Bros. films in Latin America
                and the Caribbean. He not only worked on every noteworthy WB
                franchise including; the <em>DC Universe</em>,{" "}
                <em>Conjuring Universe</em>, <em>Harry Potter</em>,{" "}
                <em>Lord of the Rings</em>/<em>Hobbit</em>, Christopher Nolan’s{" "}
                <em>Batman</em> trilogy, and <em>The Matrix t</em>rilogy, but
                also on specialty/alternative/targeted films, such as &quot;
                <em>
                  Crazy Rich Asians&quot;, &quot;Goldfinch&quot;, &quot;Blinded
                  by the Light&quot;, &quot;Motherless Brooklyn&quot;{" "}
                </em>
                and every<em> </em>Clint Eastwood<em> </em>produced film over
                the past 25 years. As an industry leader in finding creative
                ways to release these films in cinemas, he managed the
                operations of 40 countries across Latin America, selecting the
                best distribution partners, vendors and agencies, allowing for
                the incredible growth of 350% over his 25 years at WB.
              </p>
            </div>
            <img
              src="/images/samuel-real.png"
              loading="lazy"
              alt="samuel-real"
            />
          </div>
          <div className="w-layout-grid bio-grid">
            <img
              src="/images/john-aggrey.png"
              loading="lazy"
              alt="john-aggrey"
            />
            <div>
              <h2 className="advisor-name">John Aggrey</h2>
              <h6 className="advisor-occupation">
                Founder & CEO, The Unicorn Group, LLC
              </h6>
              <p className="advisor-description">
                John, with 20yrs as a leading tech entrepreneur was the Founder
                & CEO of Serious2Learn, a streaming media corporate portal and
                later he founded and led 2phlo.com, the first internet-based
                platform to provide business development opportunities and sales
                lead curation to corporations. A seasoned veteran within the
                technology sector, John is responsible for the vision and
                long-range strategic plan for The Unicorn Group, LLC.
              </p>
            </div>
          </div>
          <div className="w-layout-grid bio-grid bio-grid-flip">
            <div>
              <h2 className="advisor-name">Rick Watson</h2>
              <h6 className="advisor-occupation">
                Board Member, The Producer's Guild New Media Alliance
              </h6>
              <p className="advisor-description">
                The former CEO and Co-Founder of Ambient Digital Media, a
                leading content production company specializing in Branded
                Content, Animation, Interactive Web Development, and Special
                Features, Rick has participated in media advancements at Walt
                Disney Pictures, Paramount Pictures, Warner Brothers Pictures,
                Lions Gate Films, Netflix, Amazon, MGM, and 20th Century Fox.
              </p>
            </div>
            <img
              src="/images/rick-watson.png"
              loading="lazy"
              alt="rick-watson"
            />
          </div>
          <div className="w-layout-grid bio-grid">
            <img
              src="/images/jay_sherrard.png"
              loading="lazy"
              alt="jay_sherrard"
            />
            <div>
              <h2 className="advisor-name">Jay Sherrard</h2>
              <h6 className="advisor-occupation">
                Founder & CEO, Mass Media Films, LLC
              </h6>
              <p className="advisor-description">
                Jay, a veteran executive producer, and entrepreneur who has
                worked with filmmakers Spike Jonze, Hype Williams, Irv Blitz,
                and Matthew Rolston on music videos, commercials and more is the
                Founder & CEO of Mass Media Films, LLC (MMF), a Santa
                Monica-based production company with a roster of award-winning
                filmmakers set up to execute projects globally. Among his many
                positions prior to launching MMF, Jay managed Occidental
                Studios, one of the oldest continuously operating movie studios
                in Hollywood. As Jay puts it, “I am here. I’m visible, and a
                living example for aspiring filmmakers and entrepreneurs of all
                backgrounds that anything is possible.”
              </p>
            </div>
          </div>
          <div className="w-layout-grid bio-grid bio-grid-flip">
            <div>
              <h2 className="advisor-name">Alex Boyce</h2>
              <h6 className="advisor-occupation">
                Founder & CEO, Media Republica, Inc.
              </h6>
              <p className="advisor-description">
                The former GM, Digital for The Hollywood Reporter and Chief
                Strategy Officer at Woven Digital, where he ran all business
                development, content syndication, ad product and M&A activities,
                building out Woven’s entire owned and operated portfolio, Alex
                has been instrumental in raising over $37M in funding for these
                and other digital media businesses.
              </p>
            </div>
            <img src="/images/alex-boyce.png" loading="lazy" alt="alex-boyce" />
          </div>
          <Footer />
        </div>
      </div>
    </div>
  );
}
