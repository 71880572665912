import React from "react";
import { BACKEND_URL } from "../constants/Strings";
import Carousel from "react-bootstrap/Carousel";

// This component Renders a film entry that has been featured
// Data comes from the api in the subscriber homepage

function FeaturedFilmEntry({
  id,
  title,
  year,
  synopsis,
  runtime,
  language,
  country,
  poster,
  className,
}) {
  return (
    // <div className="slide-7 w-slide">
    //   <div className="w-layout-grid grid-4 grid-11 grid-15">
    <div className={className + " movie-card"}>
      <img
        src={`${BACKEND_URL}storage/images/${poster}`}
        width="348"
        sizes="(max-width: 479px) 132.98828125px, (max-width: 767px) 34vw, 240px"
        // srcSet="images/ivote-keyart-vertical-hq-p-500.png 500w, images/ivote-keyart-vertical-hq.png 738w"
        alt=""
        className="image-7"
      />
      <div
        id="w-node-_39ef41b0-4782-652a-91cf-f1b15410f35f-10984a00"
        className="mov-info-sec"
        style={{ display: "inline-block" }}
      >
        <div className="w-layout-grid grid-5">
          <div id="w-node-_39ef41b0-4782-652a-91cf-f1b15410f361-10984a00">
            <a href={`/screening-room?id=${id}`} className="w-inline-block">
              <div className="play-button-wrapper-div">
                <img
                  src="/images/white-play-triangle.png"
                  width="23"
                  alt=""
                  className="image-12"
                />
              </div>
            </a>
          </div>
          <div
            id="w-node-_39ef41b0-4782-652a-91cf-f1b15410f365-10984a00"
            className="div-block-8"
          >
            <div className="featured-film-card-title">{title}</div>
            <div className="featured-film-card-date">{year}</div>
          </div>
        </div>
        <div className="div-block-27">
          <div className="featured-film-card-description">{synopsis}</div>
        </div>
        <div className="div-block-28">
          <div className="featured-film-card-info">
            {runtime} min | {language} | {country}
          </div>
        </div>
      </div>
    </div>

    // </div>
  );
}

export default FeaturedFilmEntry;
