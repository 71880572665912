import React, { useEffect } from "react";
import SignedOutNavbar from "../components/navbar/signed-out-navbar";
import Footer from "../components/footer";

export default function HowItWorks() {
  return (
    <div className="body-2">
      <SignedOutNavbar></SignedOutNavbar>
      <section className="section">
        <h1 className="heading-2 select-your-plan-heading">HOW IT WORKS</h1>
        <div className="mobile-plan-title-grid">
          <ol>
            <li>
              <div>
                <div className="mobile-plan-titles mobile-how-it-works-titles">
                  <h2>ACQUISITION</h2>
                  <h3>PERIOD</h3>
                </div>
              </div>
            </li>
            <li>
              <div>
                <div className="mobile-plan-titles mobile-how-it-works-titles">
                  <h2>OTT</h2>
                  <h3>DISTRIBUTION</h3>
                </div>
              </div>
            </li>
            <li>
              <div>
                <div className="mobile-plan-titles mobile-how-it-works-titles">
                  <h2>THEATRICAL</h2>
                  <h3>DISTRIBUTION</h3>
                </div>
              </div>
            </li>
          </ol>
        </div>
        <div className="mobile-buttons">
          <div className="w-layout-grid grid-12">
            <a
              href="#distributor-div-block"
              className="link-block-5 w-inline-block"
            >
              <div className="div-block-29 acquisition-mobile-image"></div>
            </a>
            <a
              href="#filmmaker-div-block"
              className="link-block-4 w-inline-block"
            >
              <div className="div-block-30 oot-mobile-image"></div>
            </a>
            <a
              href="#audience-div-block"
              className="link-block-3 w-inline-block"
            >
              <div className="div-block-31 theatrical-mobile-image"></div>
            </a>
          </div>
          <div className="arrow-wrapper">
            <img
              src="/images/downfacing-arrow.png"
              width="24"
              alt="downfacing-arrow"
            />
          </div>
        </div>
        <div className="div-block-64">
          <div className="div-block-5 wrapper-how-it-works-mobile-cards">
            <div className="w-layout-grid grid-2 how-it-works-grid">
              <div
                id="distributor-div-block"
                className="plan-card-div plan-card-div-how-it-works w-node-24a2883a9075-70984a02"
              >
                <div className="plan-title-wrapper">
                  <div className="select-plan-title-text how-it-works-title-text">
                    <h2>1. ACQUISITION</h2>
                    <h3>PERIOD</h3>
                  </div>
                </div>
                <div className="select-plan-image select-plan-image-how-it-works">
                  <img
                    src="/images/acquisition-period.png"
                    width="320"
                    sizes="(max-width: 479px) 280px, (max-width: 767px) 320px, (max-width: 991px) 56vw, 950px"
                    srcSet="/images/acquisition-period-p-500.png 500w, /images/acquisition-period.png 698w"
                    alt="acquisition-period"
                    className="plan-image"
                  />
                  <div className="hidden-description hidden-description-how-it-works">
                    <div className="plan-card-description-row">
                      <div className="text-block-21 how-it-works-description">
                        <p>
                          Accepted films get 2 months of exclusive,
                          distributor-only screenings for acquisition
                          consideration.
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div
                id="filmmaker-div-block"
                className="plan-card-div plan-card-div-how-it-works w-node-9d18383b2384-70984a02"
              >
                <div className="plan-title-wrapper">
                  <div
                    id="hidden-description-filmmaker"
                    className="select-plan-title-text how-it-works-title-text"
                  >
                    <h2>2. OTT</h2>
                    <h3>DISTRIBUTION</h3>
                  </div>
                </div>
                <div className="select-plan-image select-plan-image-how-it-works">
                  <img
                    src="/images/oot-distribution.png"
                    width="320"
                    sizes="(max-width: 479px) 280px, (max-width: 767px) 320px, (max-width: 991px) 56vw, 950px"
                    srcSet="/images/oot-distribution-p-500.png 500w, /images/oot-distribution.png 698w"
                    alt="oot-distribution"
                    className="plan-image"
                  />
                  <div className="hidden-description hidden-description-how-it-works">
                    <div className="plan-card-description-row">
                      <div className="text-block-21 how-it-works-description oot-description">
                        <p>
                          Accepted films not acquired get unlimited audience
                          screenings on any device.{" "}
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div
                id="audience-div-block"
                className="plan-card-div plan-card-div-how-it-works w-node-43dfde75b9ee-70984a02"
              >
                <div className="plan-title-wrapper">
                  <div
                    id="hidden-description-audience"
                    className="select-plan-title-text how-it-works-title-text"
                  >
                    <h2>3. THEATRICAL</h2>
                    <h3>DISTRIBUTION</h3>
                  </div>
                </div>
                <div className="select-plan-image select-plan-image-how-it-works">
                  <img
                    src="/images/theatrical-distribution.png"
                    width="320"
                    sizes="(max-width: 479px) 280px, (max-width: 767px) 320px, (max-width: 991px) 56vw, 950px"
                    srcSet="/images/theatrical-distribution-p-500.png 500w, /images/theatrical-distribution.png 698w"
                    alt="theatrical-distribution"
                    className="plan-image"
                  />
                  <div className="hidden-description hidden-description-how-it-works">
                    <div className="plan-card-description-row">
                      <div className="text-block-21 how-it-works-description theatrical-distribution-description">
                        <p>
                          Festiv top films annually can get theatrical
                          distribution.
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <h2 className="heading-2 select-your-plan-heading final-headline-how-it-works">
              ENJOY THE ANNUAL SUMMERTIME FILM SHOWCASE IN SOUTHERN CALIFORNIA
            </h2>
          </div>
        </div>
      </section>
      <Footer />
    </div>
  );
}
