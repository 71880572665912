import React, { useState } from "react";
// import { default as ReactSelect } from "react-select";
import Select from "react-select";
import makeAnimated from "react-select/animated";
import "bootstrap/dist/css/bootstrap.min.css";
const animatedComponents = makeAnimated();
// import "./styles.css";
// import { components } from "react-select";

// const Option = (props) => {
//   return (
//     <div>
//       <components.Option {...props}>
//         <input
//           type="checkbox"
//           checked={props.isSelected}
//           onChange={() => null}
//         />{" "}
//         <label>{props.label}</label>
//       </components.Option>
//     </div>
//   );
// };
const options = [
  { value: "Global", label: "Global" },
  { value: "North America", label: "North America" },
  { value: "Brazil", label: "Brazil" },
  { value: "Argentina", label: "Argentina" },
  { value: "Mexico", label: "Mexico" },
  { value: "Central America", label: "Central America" },
  { value: "South America", label: "South America" },
  { value: "Western Europe", label: "Western Europe" },
  { value: "Eastern Europe", label: "Eastern Europe" },
  { value: "India", label: "India" },
  { value: "Middle East", label: "Middle East" },
  { value: "Japan", label: "Japan" },
  { value: "South Korea", label: "South Korea" },
  { value: "Southeast Asia", label: "Southeast Asia" },
  { value: "China", label: "China" },
  { value: "Australia/New Zealand", label: "Australia/New Zealand" },
  { value: "North Africa", label: "North Africa" },
  { value: "West Africa", label: "West Africa" },
  { value: "Southern Africa", label: "Southern Africa" },
  { value: "N/A", label: "N/A" },
];

export default function Dropdown({ getList }) {
  const [distributionList, setDistributionList] = useState({
    optionSelected: null,
  });
  const getDistributionList = (selected) => {
    setDistributionList({ optionSelected: selected });
    getList(selected);
    // console.log(distributionList);
  };

  return (
    <div className="form-input">
      <label className="form-input-label">
        Markets available for Theatrical, TV, or Streaming Distribution (Select
        all that apply)*
      </label>
      <div className="distribution-list input-styling">
        <Select
          options={options}
          components={animatedComponents}
          isMulti
          closeMenuOnSelect={false}
          onChange={getDistributionList}
          allowSelectAll={true}
          placeholder="Select Market or Markets"
        />
      </div>
    </div>
  );
}
