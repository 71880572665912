import React, { useState, useEffect } from "react";

export default function Footer() {
  const [currentYear, setCurrentYear] = useState(new Date().getFullYear());

  useEffect(() => {
    setCurrentYear(new Date().getFullYear());
  }, []);
  return (
    <div className="footer">
      <div className="w-layout-grid grid-6">
        <div id="w-node-_7ca8e957-41b8-21a6-be66-e7f1494548a6-494548a4">
          <div className="footer-col-text-title">
            <h4>Company</h4>
          </div>
          <a href="/about-us.html" className="link-block w-inline-block">
            <div className="text-block-6">
              <h5>About Us</h5>
            </div>
          </a>
          <a
            href="mailto:info@festivmedia.com?subject=Inquiry%20About%20Festiv"
            className="link-block w-inline-block"
          >
            <div className="text-block-6">
              <h5>Contact Us</h5>
            </div>
          </a>
        </div>
        <div>
          <div className="footer-col-text-title">
            <h4>Platform</h4>
          </div>
          <a
            href="/account-agreement.html"
            target="_blank"
            className="link-block w-inline-block"
          >
            <div className="text-block-6">
              <h5>Account Agreement</h5>
            </div>
          </a>
          <a
            href="/privacy-policy.html"
            target="_blank"
            className="link-block w-inline-block"
          >
            <div className="text-block-6">
              <h5>Privacy Policy</h5>
            </div>
          </a>
          <a
            href="/terms-of-use.html"
            target="_blank"
            className="link-block w-inline-block"
          >
            <div className="text-block-6">
              <h5>Terms of Use</h5>
            </div>
          </a>
          <a
            href="/festiv-video-service.html"
            target="_blank"
            className="link-block w-inline-block"
          >
            <div className="text-block-6">
              <h5>Video Services</h5>
            </div>
          </a>
        </div>
        <div>
          <div className="footer-col-text-title">
            <h4>Submissions</h4>
          </div>
          <a href="/how-it-works.html" className="link-block w-inline-block">
            <div className="text-block-6">
              <h5>How It Works</h5>
            </div>
          </a>
          <a
            id="submit-films-footer-link"
            href="/film-submission-form.html"
            className="link-block w-inline-block"
          >
            <div className="text-block-6">
              <h5>Submit Your Film Here</h5>
            </div>
          </a>
          <a
            href="/film-submission-guidelines.html"
            target="_blank"
            aria-current="page"
            className="link-block w-inline-block w--current"
          >
            <div className="text-block-6">
              <h5>Film Submission Guidelines</h5>
            </div>
          </a>
          {/* <a
            href="/filmmaker-cash-awards.html"
            target="_blank"
            className="link-block w-inline-block"
          >
            <div className="text-block-6">Filmmaker Cash Awards</div>
          </a> */}
        </div>
        <div>
          <div className="footer-col-text-title">
            <h4>Genres</h4>
          </div>
          <a href="/#" className="link-block w-inline-block">
            <div className="text-block-6">
              <h5>Action</h5>
            </div>
          </a>
          <a href="/#" className="link-block w-inline-block">
            <div className="text-block-6">
              <h5>Drama</h5>
            </div>
          </a>
          <a href="/#" className="link-block w-inline-block">
            <div className="text-block-6">
              <h5>Comedy</h5>
            </div>
          </a>
          <a href="/#" className="link-block w-inline-block">
            <div className="text-block-6">
              <h5>Family/Kids</h5>
            </div>
          </a>
          <a href="/#" className="link-block w-inline-block">
            <div className="text-block-6">
              <h5>Horror/Thriller</h5>
            </div>
          </a>
          <a href="/#" className="link-block w-inline-block">
            <div className="text-block-6">
              <h5>Science Fiction</h5>
            </div>
          </a>
          <a href="/#" className="link-block w-inline-block">
            <div className="text-block-6">
              <h5>Documentary</h5>
            </div>
          </a>
        </div>
        <div>
          <div className="footer-col-text-title">
            <h4>Promotions</h4>
          </div>
          <a href="/#" className="link-block w-inline-block display-none">
            <div className="text-block-6 display-none">
              <h5>Download Our App</h5>
            </div>
          </a>
          <a
            href="mailto:info@festivmedia.com?subject=Advertise%20With%20Us"
            className="link-block w-inline-block"
          >
            <div className="text-block-6">
              <h5>Advertise With Us</h5>
            </div>
          </a>
        </div>
      </div>
      <div className="w-layout-grid grid-7">
        <div id="w-node-_7ca8e957-41b8-21a6-be66-e7f1494548ec-494548a4">
          <a href="/index.html" className="w-inline-block">
            <img
              src="/images/festiv-logo-black.png"
              width="24"
              alt=""
              className="image-2"
            />
            <div className="text-block-8">
              <h5>FESTIV</h5>
            </div>
          </a>
        </div>
        <div id="w-node-_7ca8e957-41b8-21a6-be66-e7f1494548f0-494548a4">
          <div className="text-block-7">
            <p>© {currentYear} Next Wave Media Partners, LLC</p>
          </div>
        </div>
        <div
          id="w-node-_7ca8e957-41b8-21a6-be66-e7f1494548f3-494548a4"
          className="div-block-41"
        >
          <a
            href="https://www.instagram.com/festivmedia/?hl=en"
            className="social-link w-inline-block"
          >
            <img src="/images/instagram-icon.png" alt="" />
          </a>
          <a
            href="https://www.facebook.com/Festiv-101470541497280"
            className="social-link w-inline-block display-none"
          >
            <img src="/images/facebook-icon.png" alt="" className="image-30" />
          </a>
          <a
            href="https://twitter.com/festiv12"
            className="social-link w-inline-block display-none"
          >
            <img src="/images/twitter-icon.png" alt="" />
          </a>
          <a
            href="mailto:info@festivmedia.com?subject=Inquiry%20About%20Festiv"
            className="social-link w-inline-block"
          >
            <img src="/images/mail-icon.png" alt="" />
          </a>
        </div>
      </div>
    </div>
  );
}
