import React, { useEffect } from "react";
import { FRONTEND_URL } from "../../constants/Strings";
import "../../components/form.css";

const AdminFormModal = ({ show, handleClose, formSuccess, formError, progress}) => {
  function rerouteToPayment() {
    
    // window.localStorage.setItem('subscriptionStatus', 'active'); 
    window.location.assign(`${FRONTEND_URL}${'admin/all-films'}`);
  }

  useEffect(() => {
    // Side-effect logic gets executed when values in array
    // only get updated
    if ( formSuccess === "Form Submitted Successfully")
      setTimeout(rerouteToPayment, 3000);
      // return
      
    else if (progress === 100 && formSuccess === "Form Submitted Successfully") {
      setTimeout(rerouteToPayment, 3000);
      console.log(`Progress : ${progress}`);
      console.log(formSuccess);
    } else {
      console.log(progress, formSuccess);
    }
  }, [progress, formSuccess]);

  return (
    <div className={show ? "modal display-block" : "modal display-none"}>
      <section className="modal-main">
        { formSuccess  }
        {/* {formError && !formSuccess
          ? formError
          : formSuccess === "Submitting Form..." ||
          formSuccess === "Form Submitted Successfully"
          ? formSuccess
        : formError} */}
        <h6 >
          Film Upload Progress:{" "}
          {progress === 100 ? "Successfully Uploaded" : (!formError && progress ? `${progress}%`  : '' )}
        </h6>
        
        <br/>
        { formError  }
        <button
          className={formError ? "display-block" : "display-none"}
          onClick={handleClose}
        >
          Close
        </button>
      </section>
    </div>
  );
};

export default AdminFormModal;
