import React, { useEffect, useState } from "react";
import SignedOutNavbar from "../components/navbar/signed-out-navbar";
import Footer from "../components/footer";

export default function FilmSubmissionGuidelines() {
  const [currentYear, setCurrentYear] = useState(new Date().getFullYear());

  return (
    <div>
      <SignedOutNavbar />

      <div>
        <div className="extra-documents-container w-container">
          <div className="extra-documents-title-wrapper">
            <h1 className="extra-documents-page-title">
              GUIDELINES FOR SUBMISSION TO FESTIV {currentYear}
            </h1>
          </div>
          <div className="extra-documents-text">
            <p>
              Here is a description of the submission guidelines (“Guidelines”)
              for the submission of projects (hereafter referred to as “the
              Project”) to The Festiv Digital Film Festival (hereafter referred
              to as “Festiv”) for consideration for inclusion on the Festiv
              Online Screening Platform (hereafter referred to as “Platform”).
            </p>
            <p>
              By agreeing to the Terms and Conditions located at
              <a href="www.festivmedia.com">www.festivmedia.com</a>, the entity
              submitting the Project (hereafter called the “Filmmaker”)
              acknowledges that he, she, or it has obtained consent from any and
              all owners, creators, writers, Producers and/or other authorized
              representatives of the Project (hereafter called the “Project’s
              Owners”) whose consent is required to submit the Project to
              Festiv, and has read and understood the submission Guidelines set
              forth below:
            </p>
          </div>

          <div className="extra-documents-small-header">CONTENTS:</div>
          <ul className="extra-documents-text">
            <li>I. GENERAL GUIDELINES FOR SUBMITTING TO FESTIV</li>
            <li>II. SUBMISSION CATEGORIES &amp; ELIGIBILITY REQUIREMENTS</li>
            <li>III. PLATFORM PROGRAM &amp; ELIGIBILITY REQUIREMENTS</li>
          </ul>

          <div className="extra-documents-small-header">
            <h2>
              I. GENERAL GUIDELINES FOR SUBMISSION OF A FILM TO THE FESTIV
              PLATFORM:
            </h2>
          </div>

          <div className="extra-documents-text">
            <p>
              <strong>NOTE:</strong> The submission categories and program
              categories that are referenced and identified as capitalized terms
              in this Section I of these Submission Guidelines, and the
              eligibility requirements for each, shall be understood as having
              the definitions and descriptions assigned to them in Sections II
              and III of these Submission Guidelines below.
            </p>
          </div>

          <ol className="">
            <li class="document-section">
              <div class="document-content">
                <p>
                  If more than 50% of the Project’s financing originated from
                  sources within the United States, the Project will be
                  categorized as one of the following: Feature Film, Documentary
                  Feature Film, Short Film, or Episodic Content. Festiv reserves
                  the right to consider the Project for any Platform category,
                  regardless of the submission category selected.
                </p>
              </div>
            </li>
            <li class="document-section">
              <div class="document-content">
                <p>
                  If an amount greater than 50% of the Project’s financing
                  originated from sources outside of the United States, the
                  Project will be categorized as one of the following:
                  International Feature Film, International Documentary Feature
                  Film, International Short Film, or Episodic Content. Festiv
                  reserves the right to consider the Project for any Platform
                  category, regardless of the submission category selected.
                </p>
              </div>
            </li>

            <li class="document-section">
              <div class="document-content">
                <p>
                  If the Project’s final running time is 50 minutes or more, the
                  Project will be considered in one of the following categories:
                  Feature Film, Documentary Feature Film, International Feature
                  Film, International Documentary Feature Film, or Episodic
                  Content.
                </p>
              </div>
            </li>

            <li class="document-section">
              <div class="document-content">
                <p>
                  If the Project’s final running time is less than 50 minutes,
                  the Project will be considered in one of the following
                  categories: Short Film, or Episodic Content.
                </p>
              </div>
            </li>

            <li class="document-section">
              <div class="document-content">
                <p>
                  Feature Films and Documentary Feature Films that have been
                  exhibited publicly in their current form via limited or wide
                  theatrical release, broadcast television, cable, or streamed
                  on television or an OTT platform, or released via any home
                  video or other public distribution platform (Blu-ray, DVD,
                  video on demand, etc.) before submission and acceptance to
                  Festiv are not eligible for acquisition consideration for
                  theatrical distribution. All other submitted Feature Films and
                  Documentary Films are eligible for acquisition consideration
                  for theatrical distribution.
                </p>
              </div>
            </li>

            <li class="document-section">
              <div class="document-content">
                <p>
                  Feature Films and Documentary Feature Films that have been or
                  will be screened at other festivals in any country, including
                  the U.S. prior to submission and acceptance are still eligible
                  for acquisition consideration on the Platform.
                </p>
              </div>
            </li>

            <li class="document-section">
              <div class="document-content">
                <p>
                  Feature Films and Documentary Feature Films that have been
                  previously released via any home video or public distribution
                  platform (including, but not limited to, Blu-ray, DVD,
                  streaming, or video on demand) or broadcast/streamed on
                  television or the internet in any country are not eligible for
                  acquisition consideration for theatrical release unless the
                  version submitted is significantly different from the version
                  that was previously made available to the general public.
                  Festiv shall have the sole authority and discretion to
                  determine whether a Project that was previously released via
                  any method described above has been changed significantly from
                  the previously released or broadcast version. Festiv reserves
                  the sole and exclusive right to determine a previously
                  released or broadcast project’s eligibility, without refund of
                  any or all submission fees previously collected from the
                  Filmmaker, based on the content of the submitted version of
                  the Project as compared with the previously released or
                  broadcast version.
                </p>
              </div>
            </li>

            <li class="document-section">
              <div class="document-content">
                <p>
                  U.S. and International Feature Films and Documentary Feature
                  Films that have previously been exhibited in any form at one
                  or more private screenings for which viewing access was not
                  available to the general public retain eligibility for
                  submission and acquisition consideration via the Platform.
                </p>
              </div>
            </li>

            <li class="document-section">
              <div class="document-content">
                <p>
                  Feature Films and Documentary Feature Films with more than 50%
                  of funding originating outside of the U.S. that have screened
                  at up to three film festivals within their country of origin
                  are eligible for submission to the Platform in their
                  respective categories.
                </p>
              </div>
            </li>

            <li class="document-section">
              <div class="document-content">
                <p>
                  Feature Films and Documentary Feature Films with more than 50%
                  of funding originating outside of the U.S. that have been
                  released theatrically, broadcast, or streamed on television or
                  the Internet outside of their country of origin are not
                  eligible for acquisition consideration for theatrical
                  distribution via the Platform. This does not include
                  “work-in-progress” screenings in which an incomplete version
                  of the Project was previously screened.
                </p>
              </div>
            </li>

            <li class="document-section">
              <div class="document-content">
                <p>
                  Short Films do not require any sort of premiere status as they
                  retain their eligibility for submission to the Platform if
                  they were previously released theatrically or released on any
                  home video platform, broadcast or streamed on television or
                  the internet, or screened at any number of festivals or online
                  platforms anywhere in the world.
                </p>
              </div>
            </li>

            <li class="document-section">
              <div class="document-content">
                <p>
                  Submissions of Episodic Content are not eligible for
                  acquisition consideration on the Platform if all of the
                  submitted content has been made available in its entirety to
                  the general public via any platform (broadcast television,
                  Blu-ray, DVD, streaming, VOD, etc.) prior to submission to
                  Festiv and during the 90-day exclusive distributor screening
                  period.
                </p>
              </div>
            </li>

            <li class="document-section">
              <div class="document-content">
                <p>
                  Submissions of Episodic Content whereby more than 50% of
                  production financing originated outside of the United States
                  are eligible for acquisition consideration for U.S.
                  distribution if the entirety of the submitted content has been
                  released only within their country of origin or not released
                  at all via any platform (broadcast television, Blu-ray, DVD,
                  streaming, VOD, etc.) prior to submission to Festiv or during
                  the 90-day exclusive distributor screening period.
                </p>
              </div>
            </li>

            <li class="document-section">
              <div class="document-content">
                <p>
                  All Feature Films and Documentary Feature Films that have
                  previously been theatrically presented at a “work-in-progress”
                  screening at which tickets were available to the general
                  public are only eligible for submission to the Platform if the
                  version submitted is significantly different from the version
                  previously screened. Festiv reserves the right to determine a
                  previously screened project’s eligibility, without refund of
                  any or all submission fees previously collected from the
                  Filmmaker, based on the content of the submitted version of
                  the Project as compared with the previously screened version.
                </p>
              </div>
            </li>

            <li class="document-section">
              <div class="document-content">
                <p>
                  Projects completed more than three (3) years prior to
                  submission, are not eligible for acquisition consideration but
                  will be made available for global screening by all Festiv
                  members upon its acceptance and inclusion on the Platform.
                </p>
              </div>
            </li>

            <li class="document-section">
              <div class="document-content">
                <p>
                  Projects that have been submitted for Festiv consideration in
                  previous years are eligible for re-submission in the current
                  year if the content of the submitted Project has changed
                  significantly since the previous year in which the Project was
                  submitted. Festiv shall have the sole authority and discretion
                  to determine whether a Project that was previously submitted
                  has been changed significantly from the previously submitted
                  version. Festiv reserves the right to determine a previously
                  submitted Project’s eligibility, without refund of any or all
                  submission fees previously collected from the Filmmaker, based
                  on the content of the newly submitted version of the Project
                  as compared with the previously submitted version.
                </p>
              </div>
            </li>

            <li class="document-section">
              <div class="document-content">
                <p>
                  It is the sole responsibility of the Filmmaker to secure
                  authorization and permission from the copyright owner(s) of
                  any and all copyrighted content or materials included within
                  the submitted Project. Festiv expressly disavows any
                  responsibility for, and will not be held responsible for, any
                  unauthorized inclusion of any copyrighted content or materials
                  within or relating to the submitted Project, including any
                  content or materials that are or may become the basis for any
                  third party claims for copyright infringement. Festiv reserves
                  the right to disqualify, without refund of any or all
                  submission fees previously collected from the Filmmaker, any
                  Project with any unauthorized inclusion of copyrighted content
                  or materials. In the event that any claim, dispute, action or
                  proceeding shall be brought or asserted by any person or
                  entity that alleges that the Project makes unauthorized or
                  unlawful use of any copyrighted content or material, Filmmaker
                  shall fully indemnify and defend Festiv, and each of its
                  representatives and affiliates from any liability in
                  connection therewith and from any fees and expenses, including
                  but not limited to attorneys’ fees, that any of them may incur
                  in connection therewith.
                </p>
              </div>
            </li>
            <li class="extra-documents-text">
              <p>
                It is the sole responsibility of the Filmmaker to secure
                authorization and permission from the owner(s) of any and all
                trademarked content or materials included within the submitted
                Project. Festiv expressly disavows any responsibility for, and
                will not be held responsible for, any unauthorized inclusion of
                any trademarked content or materials within or relating to the
                submitted Project, including any content or materials that are
                or may become the basis for any third party claims for trademark
                infringement, trademark dilution or unfair competition. Festiv
                reserves the right to disqualify, without refund of any or all
                submission fees previously collected from the Filmmaker, any
                Project with any unauthorized inclusion of trademarked content
                or materials. In the event that any claim, dispute, action or
                proceeding shall be brought or asserted by any person or entity
                that alleges that the Project makes unauthorized or unlawful use
                of any trademarked content or material, Filmmaker shall fully
                indemnify and defend Festiv, and each of its representatives and
                affiliates from any liability in connection therewith and from
                any fees and expenses, including but not limited to attorneys’
                fees, that any of them may incur in connection therewith.
              </p>
            </li>

            <li class="extra-documents-text">
              <p>
                It is the sole responsibility of the Filmmaker to clear all
                content of the Project from any and all actual or potential
                legal claims and issues, including, without limitation, claims
                based upon theories of libel, defamation, invasion of privacy,
                violation of rights of publicity, theft of trade secrets, breach
                of confidence, breach of confidential relationship, and breach
                of express or implied contract (“Third Party Claim(s)”). Festiv
                and its representatives and affiliates expressly disavow any
                responsibility for, and will not be held responsible for, any
                unauthorized inclusion of any content or materials within or
                relating to the submitted Project that are or may be the basis
                for any Third Party Claims based upon any of the foregoing legal
                theories or others. Festiv, its representatives, and affiliates
                reserves the right to disqualify, without refund of any or all
                submission fees previously collected from the Filmmaker, any
                Project that is or may become the subject of any Third-Party
                Claims. In the event that any Third Party Claim(s) shall be
                asserted by any person or entity, Filmmaker shall fully
                indemnify and defend Festiv, and each of its representatives and
                affiliates from any liability in connection therewith and from
                any and all fees and expenses, including but not limited to
                attorneys’ fees, that each of any of them may incur in
                connection therewith.
              </p>
            </li>
            <li class="extra-documents-text">
              <p>
                Filmmakers submitting Projects that contain significant
                non-English spoken dialogue must provide on-screen English
                subtitles. If the submitted project containing significant
                non-English spoken dialogue does not contain on-screen English
                subtitles, Filmmaker can request that English subtitles be added
                by Festiv for an additional fee. If Filmmaker decides not to
                request English subtitles be added to the submitted film,
                Festiv, its representatives and affiliates, reserves the right
                to disqualify, without refund of any or all submission fees
                previously collected from the Filmmaker.
              </p>
            </li>

            <li class="extra-documents-text">
              <p>
                All Projects submitted to Festiv for consideration must be
                uploaded via Festiv’s online submission process. Festiv reserves
                the right to disqualify, without refund of any or all submission
                fees previously collected from Filmmaker, any Project that is
                not submitted via Festiv’s process.
              </p>
            </li>

            <li class="extra-documents-text">
              <p>
                It is the sole responsibility of the Filmmaker to ensure that
                any project submitted to Festiv for consideration plays in its
                entirety. Festiv reserves the right to disqualify, without
                refund of any or all submission fees previously collected from
                the Filmmaker, any Project that is not viewable due to any
                technical issue, whether the fault of the Filmmaker or any
                third-party system.
              </p>
            </li>

            <li class="extra-documents-text">
              <p>
                It is the sole responsibility of the Filmmaker to ensure that
                their project’s submission status is noted in the online
                submission form as “Acquisition Consideration” or “Screening
                Only” to be considered for inclusion on Festiv. Festiv reserves
                the right to disqualify, without refund of any or all submission
                fees previously collected from the Filmmaker, any and all
                submissions that do not contain an approved submission status
                designation.
              </p>
            </li>

            <li class="extra-documents-text">
              <p>
                In the event that the Filmmaker remits an updated version of the
                Project to Festiv after having submitted a previous version of
                the Project to Festiv within twelve months of the prior
                submission, Festiv will view part or all of the updated version
                of the Project to ensure the latest version is reviewed for
                inclusion.
              </p>
            </li>

            <li class="extra-documents-text">
              <p>
                In order to be considered by Festiv for inclusion on the
                platform, the Filmmaker must complete the submission form
                located at{" "}
                <a href="http://www.festivmedia.com">www.festivmedia.com</a>.
                Festiv reserves the right to disqualify, without refund of any
                or all submission fees previously collected from the Filmmaker,
                any Project that is not submitted or delivered via the Festiv
                platform.
              </p>
            </li>

            <li class="extra-documents-text">
              <p>
                Festiv reserves the right to disqualify, without refund of any
                or all submission fees previously collected from the Filmmaker,
                any Project with a submission form that is considered to be
                delinquent due to the non-payment of any or all submission fees.
                Festiv may attempt, but is under no obligation, to contact the
                Filmmaker using the contact information provided in the
                submission form in an effort to bring the payment status of the
                account up-to-date. Festiv disavows any responsibility for, and
                will not be held responsible for, any failure to contact the
                Filmmaker and inform the Filmmaker of any payment delinquency or
                other deficiency of a submission form.
              </p>
            </li>

            <li class="extra-documents-text">
              <p>
                During our continuous project review and selection process,
                Festiv will attempt to contact the Filmmaker using the contact
                information provided on the submission form to notify the
                Filmmaker of the Project’s acceptance status. Festiv disavows
                any responsibility for, and will not be held responsible for,
                any failure to contact the Filmmaker using the contact
                information provided on the submission form. It is the sole
                responsibility of the Filmmaker to ensure that the contact
                information provided on the submission form is correct
                throughout the selection period and up to three (3) months after
                the date of submission.
              </p>
            </li>

            <div className="extra-documents-text">
              <p>
                Festiv is under no obligation to provide to the Filmmaker or any
                other representative of the Project any comments or feedback
                regarding the submitted Project, any information relating to the
                manner in which the Project is, will be, or was reviewed; any
                views that Festiv or any reviewers of the Project may hold
                concerning the Project; any reasons why the Project, should it
                not be accepted for screening on the Platform, was not accepted;
                or any other information relating to  Festiv’s review,
                consideration and/or selection process regarding the Project
                specifically or submissions generally beyond the information set
                forth in these Guidelines.  Festiv, its representatives and
                affiliates, disavows any responsibility for, and will not be
                held responsible or liable for, the contents of any internal
                comments or feedback regarding any submitted Project that are or
                may be obtained by the Filmmaker or any other representative of
                the Project; any communications by any representative of Festiv
                or its representatives and affiliates, relating to the Project
                or  Festiv’s selection process; the manner in which the Project
                shall be, is or was considered and reviewed; the identity or
                identities of any specific reviewers of the Project; and/or any
                failure on the part of Festiv to provide the Filmmaker or any
                other representative(s) of the Project with information
                concerning Festiv’s consideration and/or review of the Project.
              </p>
            </div>
          </ol>
          <div className="extra-documents-small-header">
            <h2>II. SUBMISSION CATEGORIES &amp; ELIGIBILITY REQUIREMENTS:</h2>
          </div>
          <div className="extra-documents-text">
            <p>
              Projects submitted to Festiv for consideration for acceptance may
              apply in one of seven categories. Festiv reserves the right to
              determine eligibility of any film for any submission category. The
              following is a description of each submission category, along with
              eligibility requirements for each:
            </p>
          </div>
          <div className="extra-documents-super-small-header">
            <h3>Feature Film:</h3>
            <br />
          </div>
          <div className="extra-documents-text">
            <p>
              Any narrative or non-scripted work of fiction originating from
              within the United States with a running time of 50 minutes or
              more, including films that are shot in a &quot;mockumentary&quot;
              style. In order to qualify as a Feature Film, the submitted
              project must be either scripted or improvisational fiction, and at
              least half of the project&#x27;s financing must originate from
              within the United States. Films that are world premieres are
              eligible for acquisition consideration for limited and
              wide-release theatrical distribution and global screening to the
              Festiv Audience Members. Feature Films that have previously
              screened at five (5) or more other festivals anywhere in the world
              are eligible for acquisition consideration for release in any
              format and global screening to the Festiv Audience Members.
              Feature Films that have been or will be exhibited publicly at five
              (5) or more non-festival screenings, broadcast television or
              cable, or streamed on television , or released via any home video
              or other public distribution platform (Blu-ray, DVD, OTT Platform,
              VOD, etc.) prior to submission to Festiv are not eligible for
              acquisition consideration for limited or wide-release theatrical
              distribution but will be eligible for globally screening on Festiv
              by the complete community of Festiv Members.
            </p>
          </div>
          <div className="extra-documents-super-small-header">
            <h3>Documentary Feature Films:</h3>
          </div>
          <div className="extra-documents-text">
            <p>
              Any nonfiction film with a running time of 50 minutes or more, not
              including entirely scripted or improvised fictionalizations of
              actual events. If your documentary contains some dramatization of
              actual events, you may submit it for this classification, but we
              will ultimately decide which classification is best suited for
              your project, if accepted. In order to qualify as a U.S based
              Documentary Feature Film, at least half of the submitted
              project&#x27;s financing must originate from within the United
              States. Films that are world premieres are eligible for limited
              and wide-release theatrical acquisition consideration , and
              Documentary Feature Films that have previously screened at five
              (5) or more other festivals anywhere in the world are eligible for
              our acquisition consideration for release in any format and global
              screening to the complete community of Festiv Subscribers.
              Documentary Feature Films that have been or will be exhibited
              publicly at five (5) or more non-festival screenings, broadcast
              television or cable, or streamed on television, or released via
              any home video or other public distribution platform (Blu-ray,
              DVD, OTT Platform, VOD, etc.) prior to submission to Festiv are
              not eligible for acquisition consideration for limited or
              wide-release theatrical distribution but will be eligible for
              global screening on Festiv by the complete community of Festiv
              Members.
            </p>
          </div>
          <div className="extra-documents-super-small-header">
            <h3>International Feature Films:</h3>
          </div>
          <div className="extra-documents-text">
            <p>
              Any narrative or non-scripted work of fiction originating from
              outside of the United States with a running time of 50 minutes or
              more, including films that are shot in a &quot;mockumentary&quot;
              style. In order to qualify as an International Feature Film, the
              submitted project must be either scripted or improvisational
              fiction, and more than half of the project&#x27;s financing must
              originate from outside of the United States. International Feature
              Films that have previously screened at any film festival outside
              of the film’s country or countries of origin are eligible for
              submission to the Festiv Platform for consideration. International
              Feature Films that have been or will be exhibited publicly at five
              (5) or more non-festival screenings, broadcast television, cable,
              or streamed on television, or released via any home video or other
              public distribution platform (Blu-ray, DVD, OTT Platform, VOD,
              etc.) prior to submission to Festiv are not eligible for
              acquisition consideration for limited or wide-release theatrical
              distribution but are eligible for global screening on Festiv by
              the complete community of Festiv Members.
            </p>
          </div>
          <div className="extra-documents-super-small-header">
            <h3>International Documentary Feature Films:</h3>
          </div>
          <div className="extra-documents-text">
            <p>
              Any nonfiction film originating from outside the U.S. with a
              running time of 50 minutes or more, not including entirely
              scripted or improvised fictionalizations of actual events. If your
              documentary contains some dramatization of actual events, you may
              submit it for this classification, but we will ultimately decide
              which classification is best suited for your project, if accepted.
              In order to qualify as an International Documentary Feature Film,
              at least half of the submitted project&#x27;s financing must
              originate from outside the United States. Films that are world
              premieres are eligible for limited and wide-release theatrical
              acquisition consideration, and International Documentary Feature
              Films that have previously screened at five (5) or more other
              festivals anywhere in the world are eligible for acquisition
              consideration for release in any format and global screening to
              the entire community of Festiv Members. International Documentary
              Feature Films that have been, or will be, exhibited publicly at
              five (5) or more non-festival screenings, broadcast television or
              cable, or streamed on television, or released via any home video
              or other public distribution platform (Blu-ray, DVD, OTT Platform,
              VOD, etc.) prior to submission to Festiv are not eligible for
              acquisition consideration for limited or wide-release theatrical
              distribution but will be eligible for global screening on Festiv
              by the complete community of Festiv Members.
            </p>
          </div>
          <div className="extra-documents-super-small-header">
            <h3>Short Films:</h3>
            <br />
          </div>
          <div className="extra-documents-text">
            <p>
              Any narrative or non-scripted work of fiction or
              nonfiction/documentary film of any genre originating from within
              the United States with a running time of less than 50 minutes,
              including credits. In order to qualify as a U.S. Short Film, at
              least half of the submitted project&#x27;s financing must
              originate from within the United States. U.S. Short Films have no
              premiere requirements or prior screening restrictions that would
              impact Platform eligibility. U.S. Short Films may have been
              screened at any number of Platforms or other public theatrical
              exhibitions anywhere in the world, broadcast or streamed on
              television or the Internet, and/or released via any home video or
              other public distribution platform and still remain eligible for
              Platform consideration.
            </p>
          </div>
          <div className="extra-documents-super-small-header">
            <br />
          </div>
          <div className="extra-documents-text">
            <p>
              Any narrative or non-scripted work of fiction or
              nonfiction/documentary film of any genre originating from outside
              of the United States with a running time of less than 50 minutes,
              including credits. In order to qualify as an International Short
              Film, at least half of the submitted project&#x27;s financing must
              originate from outside of the United States. International Short
              Films have no premiere requirements or prior screening
              restrictions that would impact Platform eligibility. International
              Short Films may have been screened at any number of Platforms or
              other public theatrical exhibitions anywhere in the world,
              broadcast or streamed on television or the Internet, and/or
              released via any home video or other public distribution platform
              and still remain eligible for Platform consideration.
            </p>
          </div>
          <div className="extra-documents-super-small-header">
            <h3>Episodic Content:</h3>
          </div>
          <div className="extra-documents-text">
            <p>
              Any narrative or non-scripted work of fiction or
              nonfiction/documentary series of any genre, originating from
              anywhere in the world, presented in or intended to be presented in
              a multi-episode format. Filmmakers may submit single or multiple
              episodes of a single project, as long as all episodes play
              continuously in one single video file for digital upload and do
              not exceed the 10GB file size limit. We accept both short-form and
              long-form content. Projects originating from within the United
              States are not eligible for the Platform if all of the submitted
              content has been made available in its entirety to the general
              public via any platform (broadcast television, Blu-ray, DVD,
              streaming, VOD, etc.) prior to submission to Festiv. . Projects
              originating from outside of the United States remain eligible if
              all of the submitted content has been released only within their
              country of origin or not released at all. You may submit episodes
              that have previously been screened on other Platforms, at other
              festivals, or broadcast and/or streamed via another platform, but
              you must also submit subsequent episodes that have not been
              screened by or released to the general public. For example, if
              your pilot episode has screened on another Platform or is
              available to screen at another festival, you may only submit it if
              you currently have additional episodes that have not been screened
              anywhere or otherwise made available to the public.
            </p>
            <br />
          </div>
          <div className="extra-documents-small-header">
            <h3>III. FESTIV SELECTION REQUIREMENTS:</h3>
          </div>
          <div className="extra-documents-text">
            <p>
              Please be advised that the information listed below is subject to
              change at any time, as our Selection Criteria may vary from year
              to year. Content accepted by Festiv for screening on the Platform
              will be initially categorized based on your category selection at
              the time of submission. However, our Selection Committee may, at
              their discretion, recategorize the content submitted for screening
              on Festiv. The following is a description of each project
              category, and eligibility requirements for each can be found in
              the sections above:
            </p>
          </div>
          <div className="w-layout-grid number-grid">
            <div className="extra-documents-text-number-wrapper">
              <div className="extra-documents-text-number">
                <p>1)</p>
              </div>
            </div>
            <div className="extra-documents-text">
              <p>
                Feature Film: 50 minutes or longer run time with more than 50%
                of film funding from U.S. based parties.
              </p>
            </div>
            <div className="extra-documents-text-number-wrapper">
              <div className="extra-documents-text-number">
                <p>2)</p>
              </div>
            </div>
            <div className="extra-documents-text">
              <p>
                International Feature Film: 50 minutes or longer run time with
                more than 50% of film funding from non-U.S. based parties.
              </p>
            </div>
            <div className="extra-documents-text-number-wrapper">
              <div className="extra-documents-text-number">
                <p>3)</p>
              </div>
            </div>
            <div className="extra-documents-text">
              <p>
                Short Film: Less than 50 minutes run time with more than 50% of
                film funding from U.S. based parties.
              </p>
            </div>
            <div className="extra-documents-text-number-wrapper">
              <div className="extra-documents-text-number">
                <p>4)</p>
              </div>
            </div>
            <div className="extra-documents-text">
              <p>
                International Short Film: Less than 50 minutes run time with
                more than 50% of film funding from non-U.S. based parties.
              </p>
            </div>
            <div className="extra-documents-text-number-wrapper">
              <div className="extra-documents-text-number">
                <p>5)</p>
              </div>
            </div>
            <div className="extra-documents-text">
              <p>
                Animated Feature Film: 50 minutes or longer run time with more
                than 50% of film funding from U.S. based parties.
              </p>
            </div>
            <div className="extra-documents-text-number-wrapper">
              <div className="extra-documents-text-number">
                <p>6)</p>
              </div>
            </div>
            <div className="extra-documents-text">
              <p>
                International Animated Feature Film: 50 minutes or longer run
                time with more than 50% of film funding from non-U.S. based
                parties.
              </p>
              <br />
            </div>
            <div className="extra-documents-text-number-wrapper">
              <div className="extra-documents-text-number">
                <p>7)</p>
                <br />
              </div>
            </div>
            <div className="extra-documents-text">
              <p>
                Animated Short Film: Less than 50 minutes run time with more
                than 50% of film funding from U.S. based parties.
              </p>
              <br />
            </div>
            <div className="extra-documents-text-number-wrapper">
              <div className="extra-documents-text-number">
                <p>8)</p>
                <br />
              </div>
            </div>
            <div className="extra-documents-text">
              <p>
                International Animated Short Film: Less than 50 minutes run time
                with more than 50% of film funding from non-U.S. based parties.
              </p>
              <br />
            </div>
            <div className="extra-documents-text-number-wrapper">
              <div className="extra-documents-text-number">
                9)
                <br />
              </div>
            </div>
            <div className="extra-documents-text">
              <p>
                Episodic Content: Any narrative or non-scripted work of fiction
                or nonfiction/documentary series of any genre, originating from
                anywhere in the world, presented in or intended to be presented
                in a multi-episode format.
              </p>
              <br />
            </div>
            <div className="extra-documents-text-number-wrapper">
              <div className="extra-documents-text-number">
                10)
                <br />
              </div>
            </div>
            <div className="extra-documents-text">
              <p>
                Documentary Feature Film: 50 minutes or longer run time with
                more than 50% of film funding from U.S. based parties.
              </p>
              <br />
            </div>
            <div className="extra-documents-text-number-wrapper">
              <div className="extra-documents-text-number">
                11)
                <br />
              </div>
            </div>
            <div className="extra-documents-text">
              <p>
                International Documentary Feature Film: 50 minutes or longer run
                time with more than 50% of film funding from non-U.S. based
                parties.
              </p>
              <br />
            </div>
            <div className="extra-documents-text-number-wrapper">
              <div className="extra-documents-text-number">
                <p>12)</p>
                <br />
              </div>
            </div>
            <div className="extra-documents-text">
              <p>
                Documentary Short Film: Less than 50 minutes run time with more
                than 50% of film funding from U.S. based parties.
              </p>
              <br />
            </div>
            <div className="extra-documents-text-number-wrapper">
              <div className="extra-documents-text-number">
                <p>13)</p>
                <br />
              </div>
            </div>
            <div className="extra-documents-text">
              <p>
                International Documentary Short Film: Less than 50 minutes run
                time with more than 50% of film funding from non-U.S. based
                parties.
              </p>
            </div>
          </div>
          <div className="extra-documents-text">
            <p>
              Within each of the first eight (8) categories listed above,
              submitted films will be in one of the following genres:
            </p>
          </div>
          <div className="w-layout-grid number-grid">
            <div className="extra-documents-text-number-wrapper">
              <div className="extra-documents-text-number">
                <p>1)</p>
                <br />
              </div>
            </div>
            <div className="extra-documents-text">
              <h6>Action</h6>
              <br />
            </div>
            <div className="extra-documents-text-number-wrapper">
              <div className="extra-documents-text-number">
                <p>2)</p>
                <br />
              </div>
            </div>
            <div className="extra-documents-text">
              <h6>Drama</h6>
              <br />
            </div>
            <div className="extra-documents-text-number-wrapper">
              <div className="extra-documents-text-number">
                <p>3)</p>
                <br />
              </div>
            </div>
            <div className="extra-documents-text">
              <h6>Comedy</h6>
              <br />
            </div>
            <div className="extra-documents-text-number-wrapper">
              <div className="extra-documents-text-number">
                <p>4)</p>
                <br />
              </div>
            </div>
            <div className="extra-documents-text">
              <h6>Horror/Thriller</h6>
              <br />
            </div>
            <div className="extra-documents-text-number-wrapper">
              <div className="extra-documents-text-number">
                <p>5)</p>
                <br />
              </div>
            </div>
            <div className="extra-documents-text">
              <h6>Family/Kids</h6>
              <br />
            </div>
            <div className="extra-documents-text-number-wrapper">
              <div className="extra-documents-text-number">
                <p>6)</p>
                <br />
              </div>
            </div>
            <div className="extra-documents-text">
              <h6>Science Fiction</h6>
              <br />
            </div>
          </div>
          <div className="extra-documents-text">
            <p>
              Each film submitted will be required to be placed in one of the
              following categories upon submission:
            </p>
          </div>
          <div className="extra-documents-super-small-header">
            <h3>1. Acquisition Consideration:</h3>
            <br />
          </div>
          <div className="extra-documents-text">
            <p>
              Films with this designation will first be available for viewing
              within the exclusive 60-day Distributor Only screening area prior
              to being made available for screening by the entire Festiv global
              collection of Audience Members, Filmmakers, and Distributors.
            </p>
          </div>
          <div className="extra-documents-super-small-header">
            <h3>2. Global Screening:</h3>
            <br />
          </div>
          <div className="extra-documents-text">
            <p>
              Films with this designation will be immediately available for
              viewing by our entire Festiv global collection of Audience
              Members, Filmmakers, and Distributors.
            </p>
          </div>
          <div className="extra-documents-text">
            <p>Thank You,</p>
            <p>The Festiv Selection Committee</p>
            <br />
          </div>
        </div>
      </div>
      <Footer />
    </div>
  );
}
