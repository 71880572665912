import React, { useEffect } from "react";
import SignedOutNavbar from "../components/navbar/signed-out-navbar";
import Footer from "../components/footer";
export default function FestivVideoService() {
  return (
    <div>
      <SignedOutNavbar />

      <section>
        <article className="extra-documents-container w-container">
          <div className="extra-documents-title-wrapper">
            <div className="div-block-53">
              <img
                src="/images/festiv-logo-full-original.png"
                loading="lazy"
                width="114"
                sizes="100px"
                srcset="/images/festiv-logo-full-original-p-500.png 500w, /images/festiv-logo-full-original-p-800.png 800w, /images/festiv-logo-full-original-p-1080.png 1080w, /images/festiv-logo-full-original-p-1600.png 1600w, /images/festiv-logo-full-original-p-2000.png 2000w, /images/festiv-logo-full-original.png 2499w"
                alt="festiv-logo"
                className="extra-documents-festiv-icon"
              />
            </div>
            <h1 className="extra-documents-page-title">FESTIV VIDEO SERVICE</h1>
          </div>
          <div className="extra-documents-text">
            <br />
            <p>
              Video content for this service is provided by Festiv. You agree
              that Festiv, a subsidiary of Next Wave Media Partners, LLC, can
              share your video viewing activities with any service provider
              acting on our behalf and with advertising partners. Your consent
              to such sharing is valid until you withdraw your consent by
              emailing{" "}
              <a href="mailto:info@festivmedia.com">info@festivmedia.com</a>.
            </p>
            <br />
            <br />
            <p>
              If you do not consent or withdraw your consent in the future, your
              information will be used only within the Next Wave Media Partners,
              LLC family of brands shown{" "}
              <a
                href="http://www.nextwavemediapartners.com/brands"
                target="_blank"
              >
                here
              </a>
              , as set forth in our Privacy Policy, and shared with third
              parties only in the ordinary course of business.
            </p>
            <br />
            <br />
            <p>
              We may amend this Video Service policy at any time by posting the
              amended version on the applicable Festiv website or by providing
              such notice about or obtaining consent to changes as may be
              required by applicable law.
            </p>
            <br />
          </div>
        </article>
      </section>

      <Footer />
    </div>
  );
}
