import React from "react";
import "../../src/components/form.css";

export default function LoadingSpinner() {
  return (
    <div className="spinner-container">
      <div className="loading-spinner">
      </div>
      <h3>Loading...</h3>
    </div>
  );
}