import React, { useState } from "react";
import { CardElement, useStripe, useElements } from "@stripe/react-stripe-js";
import SignedInNavbar from "../components/navbar/signed-in-navbar";
import axios from "axios";
import setAccountContext from "../util/set-account-context";
import Footer from "../components/footer";

export default function UpdatePaymentModule() {
  const [processing, setProcessing] = useState(false);
  const stripe = useStripe();
  const elements = useElements();

  function handleUpdatePayment() {
    setProcessing(true);

    stripe
      .createPaymentMethod({
        type: "card",
        card: elements.getElement(CardElement),
      })
      .then((result) => {
        if (result.error) {
          setProcessing(false);
          alert("Payment Unsuccessful");
          return;
        } else {
          const config = {
            headers: {
              Authorization: window.localStorage.FBIdToken,
            },
          };
          axios
            .post(
              "https://us-central1-festiv-fd5c6.cloudfunctions.net/subscriptions/change-payment",
              result,
              config
            )
            // axios.post("http://localhost:5000/festiv-fd5c6/us-central1/subscriptions/change-payment", result, config)
            .then((result) => {
              if (result.data.success === true) {
                alert("Payment successful");
                setAccountContext("/subscriber-homepage");
                return;
              }
            })
            .catch((error) => {
              alert(error.message);
              setProcessing(false);
            });
        }
      });
  }

  const options = {
    style: {
      base: {
        color: "#32325d",
        fontFamily: '"Helvetica Neue", Helvetica, sans-serif',
        fontSmoothing: "antialiased",
        fontSize: "16px",
        "::placeholder": {
          color: "#aab7c4",
        },
      },
      invalid: {
        color: "#fa755a",
        iconColor: "#fa755a",
      },
    },
  };
  return (
    <div>
      <SignedInNavbar />
      <div className="div-block-62"></div>
      <div className="section-9" style={{ textAlign: "center" }}>
        <div
          style={{
            display: "inline-block",
            maxWidth: "auto",
            marginTop: "50px",
          }}
          className="account-info-container"
        >
          <div
            style={{ marginTop: "30px", marginBottom: "30px" }}
            className="sign-in-field-label"
          >
            <h2>Update Your Card Information</h2>
            <h2>In The Space Below</h2>
          </div>
          <div
            style={{ padding: "40px", minWidth: "350px" }}
            className="account-info-card"
          >
            <CardElement
              options={options}
              // onChange = {displayErrorMessages}
            />

            <button
              onClick={handleUpdatePayment}
              style={{ marginTop: "30px" }}
              className="submit-button-3 w-button"
            >
              {processing ? "LOADING..." : "SUBMIT"}
            </button>
          </div>
        </div>
      </div>

      <Footer />
    </div>
  );
}
