import axios from "axios";
import { useState } from "react";
import { useEffect } from "react";
import { useLocation } from "react-router";
import { BACKEND_URL } from "../../constants/Strings";
import ScreeningRoomDisplay from "../screening-room-display";
import AdminProtectedRoute from "./adminProtectedRotue";
import LoadingSpinner from "../loading-spinner";

// This component renders a room for screening a specified film. The film id is passed as a url param to this component and the film is retried
// using the passed id.
function AdminScreeningRoom() {


  // const navigate = useHistory()

  const [currentFilm, setCurrentFilm] = useState({});
  const [email, setEmail] = useState("");
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const { search } = useLocation(); //
  const [isLoading, setIsLoading] = useState(true);
  const [file, setFile] = useState("");


  // function goToHome(){

  //   navigate.push({
  //     pathname: '/index.html'
  //   });
  // }

  const errorMessage = <div className="container">
    <div className="row">
      <div className="col-md-12 errorTemplate">
        <div style={{
          'display': 'flex',
          'flexDirection': 'column',
          'alignItems': 'center',
        }}>
          <h1>
            Oops!</h1>
          <h2> Film Not Found</h2>
          <div className="error-details">
            Sorry, an error has occured, Requested Film is not available!
          </div>
          <div className="error-actions mt-2">
            <a href="/subscriber-homepage" className="btn btn-primary btn-lg"><span className="glyphicon glyphicon-home"></span>
              Take Me Home </a>
          </div>
        </div>
      </div>
    </div>
  </div>
    ;


  useEffect(() => {

    function getIdParam() {
      const match = search.match(/id=(.*)/);
      const id = match?.[1];
      return id;
    }

    // Get film data for film that was clicked by user
    const getFromLaravel = async () => {
      try {
        const response = await axios.get(
          `${BACKEND_URL}api/admin/get-film/${getIdParam()}`
        );
        if (response?.status === 200) {//show film
          setCurrentFilm(response.data.film);
          console.log('FIlm Data', response.data.film);
          setFile(response.data.film.film_file);
          setEmail(response.data.email);
          setFirstName(response.data.firstName);
          setLastName(response.data.lastName);
          console.log(file);

        }
        // else
        // setContent(errorMessage);
      }
      catch (e) {
        // console.log(e) ;
        console.log('Catch ');
      }

      setTimeout(() => {

        setIsLoading(false);
      }, 2000);

    };

    // const getMessageFromLaravel = async () => {
    //   const response = await axios.get(
    //     `${BACKEND_URL}api/get-message/${getIdParam()}`
    //   );
    //   setMessage(response.data.message);
    //   console.log(response.data.message);
    // };

    getFromLaravel();
    // getMessageFromLaravel();


    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);



  return (
    <AdminProtectedRoute >
      {
        isLoading ? (
          <LoadingSpinner />
        ) : file !== '' ?
          <ScreeningRoomDisplay
            id={currentFilm && currentFilm.id}
            title={currentFilm && currentFilm.title}
            year={currentFilm && currentFilm.completion_year.substring(0, 4)}
            runtime={currentFilm && currentFilm.runtime}
            country={currentFilm && currentFilm.country}
            language={currentFilm && currentFilm.language}
            synopsis={currentFilm && currentFilm.synopsis}
            file={file && file}
            fileLink={currentFilm && currentFilm?.external_link}
            poster={currentFilm && currentFilm.poster}
            banner={currentFilm && currentFilm.banner}
            message={currentFilm && currentFilm.message}
            views={(currentFilm && currentFilm.views) ? currentFilm.views : 0}
            email={email && email}
            firstName={firstName && firstName}
            lastName={lastName && lastName}
            imdb={currentFilm && currentFilm.imdb}
          />
          : errorMessage
      }

    </AdminProtectedRoute>
  );
}

export default AdminScreeningRoom;