import React from "react";

const FormInput = (props) => {
  return (
    <div>
      {props.name === "message" || props.name === "projectSynopsis" || props.name === "festivalScreenings" ? (
        <div className="form-input">
          <label htmlFor={props.id} className="form-input-label">{props.label}</label>

          <textarea maxLength={props.name === "message" ? "360" : props.name === "projectSynopsis" ? "180" : "524288"}
            type={props.type}
            name={props.name}
            id={props.id}
            placeholder={props.placeholder}
            required={props.required}
            onChange={props.getInput}
            className="input-styling"
          ></textarea>
        </div>
      ) : (
        <div className="form-input">
          <label htmlFor={props.id} className="form-input-label">{props.label}</label>
          <input
            type={props.type}
            name={props.name}
            id={props.id}
            //   value={props.value}
            placeholder={props.placeholder}
            required={props.required}
            disabled={props.disabled}
            onChange={props.getInput}
            className="input-styling"
          />
        </div>
      )}
      </div>
  );
};

export default FormInput;
