// Import React Components
import React, { useState, useContext } from "react";
// import authorization from "../firebaseApp";
import setAccountContext from "../util/set-account-context";
import FIREBASEURL from "../firebase-url";
import axios from "axios";
import SignedInNavbar from "../components/navbar/signed-in-navbar";
import Footer from "../components/footer";

export default function UpdateAccount() {
  setAccountContext();

  function handlePasswordReset() {
    axios
      .post(FIREBASEURL + "/resetPassword", {
        email: window.localStorage["email"],
      })
      .then((res) => {
        console.log(res);
        if (res.data.error) {
          alert(res.data.error);
        } else {
          alert(res.data.message);
        }
      })
      .catch((err) => {
        alert(err.message);
      });
  }

  const [userUpdateError, setUserUpdateError] = useState("");
  const [userUpdateSuccess, setUserUpdateSuccess] = useState("");
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [email, setEmail] = useState("");

  const [imdbLink, setImdbLink] = useState("");

  function updateFirstName(event) {
    setFirstName(event.target.value);
  }
  function updateLastName(event) {
    setLastName(event.target.value);
  }

  function updateImdbLink(event) {
    setImdbLink(event.target.value);
  }

  function updateEmail(event) {
    setEmail(event.target.value);
  }

  function handleInfoSubmit() {
    console.log("Starting");
    // CHANGE EMAIL WITH FIREBASE API
    let returnJSON = {
      email: window.localStorage["email"],
    };
    if (firstName) {
      returnJSON["firstName"] = firstName;
    }
    if (lastName) {
      returnJSON["lastName"] = lastName;
    }
    if (imdbLink) {
      returnJSON["imdbLink"] = imdbLink;
    }

    const config = {
      headers: {
        Authorization: window.localStorage.FBIdToken,
      },
    };
    axios
      .post(FIREBASEURL + "/modifyUserInfo", returnJSON, config)
      .then((res) => {
        if (res.data.error) {
          alert(res.data.error);
        } else {
          alert("Account Modifications Made!");
          setAccountContext("/account");
        }
      })

      .catch((err) => {
        alert(err.message);
      });
  }

  return (
    <div className="body-4">
      <div className="fixed-background-account-pages"></div>
      <SignedInNavbar />
      <div className="section-8">
        <div className="account-info-container w-container">
          <a
            href="/account.html"
            className="back-to-account-link w-inline-block"
          >
            <div className="html-embed-6 w-embed">
              <svg
                width="12"
                height="8"
                viewbox="0 0 12 8"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M12 3.33333H2.55333L4.94 0.94L4 0L0 4L4 8L4.94 7.06L2.55333 4.66667H12V3.33333Z"
                  fill="#7994C3"
                ></path>
              </svg>
            </div>
            <div className="back-to-account-text">
              <h1>Back to account</h1>
            </div>
          </a>
          <div className="account-info-card">
            <div className="account-info-card-title">
              <h2>Update Account Details</h2>
            </div>
            <div className="w-form">
              <form id="email-form" name="email-form" data-name="Email Form">
                <div className="w-layout-grid grid-19">
                  <div className="div-block-58 w-clearfix">
                    <label
                      for="first-name"
                      className="account-info-field-label"
                    >
                      First Name
                    </label>
                    <input
                      type="text"
                      className="account-info-text-field w-input"
                      maxlength="256"
                      name="first-name"
                      data-name="first-name"
                      placeholder={window.localStorage["firstName"]}
                      id="first-name"
                      onChange={updateFirstName}
                    />
                  </div>
                  <div className="div-block-58 w-clearfix">
                    <label
                      for="last-name-2"
                      className="account-info-field-label"
                    >
                      Last Name
                    </label>
                    <input
                      type="text"
                      className="account-info-text-field w-input"
                      maxlength="256"
                      name="last-name"
                      data-name="last-name"
                      placeholder={window.localStorage["lastName"]}
                      id="last-name"
                      onChange={updateLastName}
                    />
                  </div>
                  <div className="div-block-58 w-clearfix">
                    <label for="email" className="account-info-field-label">
                      Email
                    </label>
                    <input
                      type="text"
                      className="account-info-text-field w-input"
                      maxlength="256"
                      name="email"
                      data-name="email"
                      placeholder={window.localStorage["email"]}
                      id="email"
                      onChange={updateEmail}
                    />
                  </div>
                  <div className="div-block-58 w-clearfix">
                    <label for="lmdb-link" className="account-info-field-label">
                      IMDb link
                    </label>
                    <input
                      type="text"
                      className="account-info-text-field w-input"
                      maxlength="256"
                      name="lmdb-link"
                      data-name="lmdb-link"
                      placeholder={window.localStorage["imdbLink"]}
                      id="lmdb-link"
                      onChange={updateImdbLink}
                    />
                  </div>
                </div>
                <input
                  type="submit"
                  onClick={handleInfoSubmit}
                  value="SUBMIT"
                  data-wait="Please wait..."
                  className="submit-button-3 w-button"
                />
              </form>
              <div className="w-form-done">
                <div>
                  <p>Thank you! Your submission has been received!</p>
                </div>
              </div>
            </div>
          </div>
          <div className="account-info-card">
            <div className="account-info-card-title">Password Reset</div>
            <div className="w-form">
              <form>
                <input
                  onClick={handlePasswordReset}
                  type="submit"
                  value="RESET LINK"
                  data-wait="Please wait..."
                  className="submit-button-3 w-button"
                />
              </form>
              <div className="w-form-done">
                <div>
                  <p>Thank you! Your submission has been received!</p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </div>
  );
}
