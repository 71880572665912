import React, { useEffect } from "react";
import { FRONTEND_URL } from "../constants/Strings";
import "../../src/components/form.css";

const Modal = ({ show, handleClose, formSuccess, formError, progress, hasFileUpload, isPaid }) => {
  function rerouteToPayment() {

    // window.localStorage.setItem('subscriptionStatus', 'active'); 
    window.location.assign(`${FRONTEND_URL}${isPaid ? 'index.html' : 'select-film-submission'}`);
  }

  useEffect(() => {
    // Side-effect logic gets executed when values in array
    // only get updated
    if (!hasFileUpload && formSuccess === "Form Submitted Successfully")
      setTimeout(rerouteToPayment, 3000);
    // return

    else if (progress === 100 && formSuccess === "Form Submitted Successfully") {
      setTimeout(rerouteToPayment, 3000);
      console.log(`Progress : ${progress}`);
      console.log(formSuccess);
    } else {
      console.log(progress, formSuccess);
    }
  }, [progress, formSuccess]);

  return (
    <div className={show ? "modal display-block" : "modal display-none"}>
      <section className="modal-main">
        {formSuccess}
        {/* {formError && !formSuccess
          ? formError
          : formSuccess === "Submitting Form..." ||
          formSuccess === "Form Submitted Successfully"
          ? formSuccess
        : formError} */}
        <h6 className={hasFileUpload ? '' : "display-none"}>
          Film Upload Progress:{" "}
          {progress === 100 ? "Successfully Uploaded" : (!formError && progress ? `${progress}%` : '')}
        </h6>
        {progress === 100 || (!hasFileUpload && formSuccess === "Form Submitted Successfully") ?
          (isPaid ?
            <>
              <p>Thank you for your submission!</p>
              <br />
              <p>Our selection committee will review your submission and we will notify you of our decision.</p>

            </>
            :
            <p>Redirecting to Payment Page</p>

          )
          : ""
        }
        <br />
        {formError}
        <button
          className={formError ? "display-block" : "display-none"}
          onClick={handleClose}
        >
          Close
        </button>
      </section>
    </div>
  );
};

export default Modal;
