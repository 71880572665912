import React, { useEffect } from "react";
import SignedOutNavbar from "../components/navbar/signed-out-navbar";
import Footer from "../components/footer";

export default function FilmmakerCashAwards() {
  return (
    <div>
      <SignedOutNavbar />
      <div>
        <div className="extra-documents-container w-container">
          <div className="extra-documents-title-wrapper">
            <div className="div-block-53">
              <img
                src="/images/festiv-logo-full-original.png"
                loading="lazy"
                width="114"
                sizes="100px"
                srcset="/images/festiv-logo-full-original-p-500.png 500w, /images/festiv-logo-full-original-p-800.png 800w, /images/festiv-logo-full-original-p-1080.png 1080w, /images/festiv-logo-full-original-p-1600.png 1600w, /images/festiv-logo-full-original-p-2000.png 2000w, /images/festiv-logo-full-original.png 2499w"
                alt=""
                className="extra-documents-festiv-icon"
              />
            </div>
            <div className="extra-documents-page-title">
              <h2>FILMMAKER CASH AWARDS</h2>
            </div>
          </div>
          <div className="extra-documents-text">
            <p>
              Cash Awards for Filmmakers is determined as follows: In
              recognition of the top films as determined by our Selection
              Committee, as well as the total number of times a project was
              screened and viewer ratings, Festiv will provide a cash award to
              the top 20 films.   The total number of times a project is
              screened will include screenings by both Distributors and
              Filmmakers during the exclusive screening period and screenings by
              the general viewing audience when a film is no longer designated
              for exclusive Distributor and Filmmaker viewing.
            </p>
            <p>
              An example of how the cash awards for Filmmakers are allocated can
              be seen below:
            </p>
            <p>Total Cash Award: $150,000</p>
          </div>
          <div className="extra-documents-text extra-documents-bullet-points extra-documents-extra-bullet-points">
            <ol>
              <li>The Top Filmmaker’s Cash Award: $15,000</li>
              <li>Filmmaker’s 2-5 Cash Award: $12,000 each</li>
              <li>Filmmaker’s 6-10 Cash Award: $9,000 each</li>
              <li>Filmmaker’s 11-15 Cash Award: $5,250 each</li>
              <li>Filmmaker’s 16-20 Cash Award: $3,150 each</li>
            </ol>
          </div>
        </div>
      </div>
      <Footer />
    </div>
  );
}
