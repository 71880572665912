import axios from "axios";
import { useState } from "react";
import { useEffect } from "react";
import { useHistory } from "react-router";
import AppNavBar from "../../components/navbar/get-navbar";
import SignedInNavbar from "../../components/navbar/signed-in-navbar";
import { BACKEND_URL } from "../../constants/Strings";
import AdminProtectedRoute from "./adminProtectedRotue";


function AdminAllFilms() {

  const navigate = useHistory()
  // let notification = ''
  const [allFilms, setAllFilms] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [notification, setNotification] = useState(undefined);
  const [statusFilter, setStatusFilter] = useState('all');
  const [nameFilter, setNameFilter] = useState('');

  const [filteredFilms, setFilteredFilms] = useState(undefined);

  function getDateDisplayString(date) {
    if (date?.length === 0)
      return ''
    return new Date(date).toDateString()
  }

  // Get film data for film that was clicked by user
  const getFilmsFromLaravel = async () => {
    setIsLoading(true);

    try {

      const response = await axios.get(
        // `${BACKEND_URL}api/films/${getIdParam()}`
        `${BACKEND_URL}api/all-films`
      );
      setAllFilms(response.data.films);
      console.log('All Films Data', response.data.films);

    }
    catch (e) {
      console.log(e);
      displayMessage(false, 'Failed to load films!');
    }

    setTimeout(() => {
      setIsLoading(false);
    }, 2000);
  };


  useEffect(() => {
    document.querySelector('body').style.paddingTop = '0px';

    document.querySelector('body').style.margin = '0 !important';

    document.querySelector('#root').style.overflow = 'hidden';
    getFilmsFromLaravel();
  }, []);


  useEffect(() => {
    if (statusFilter === 'all' & nameFilter?.length === 0) //No filter
      setFilteredFilms(allFilms);

    else if (statusFilter === 'all' & nameFilter?.length > 0) //Only Name filter
      setFilteredFilms(allFilms?.filter(film =>
        film?.title?.toLowerCase()?.includes(nameFilter)
      ))

    else if (statusFilter !== 'all' & nameFilter?.length === 0)  //Only Status filter
      setFilteredFilms(allFilms?.filter(film =>
        film?.status?.toLowerCase()?.includes(statusFilter)
      ))

    else  //Only Both filters
      setFilteredFilms(allFilms?.filter(film =>
        film?.status?.toLowerCase()?.includes(statusFilter) && film?.title?.toLowerCase()?.includes(nameFilter)
      ))
  }, [allFilms, statusFilter, nameFilter])


  function goToForm(id, link) {
    console.log(id);
    // window.location.assign(`/upload/films/${id}`);

    navigate.push({
      pathname: '/admin/upload/films',
      // search : `?link=${link}`
      state: [{ filmId: id, externalLink: link }]
    });
  }

  async function approveVideo(id) {
    console.log(id);
    setIsLoading(true);

    try {

      const response = await axios.get(
        // `${BACKEND_URL}api/films/${getIdParam()}`
        `${BACKEND_URL}api/film/approve/${id}`
      );
      // response.data;
      if (response.status === 200) {
        console.log('Successfully')
        displayMessage(true, 'Film was successfully approved!');
        getFilmsFromLaravel();
      }

      else
        displayMessage(false, 'Failed to approve film!');
    }
    catch (e) {
      console.log(e);
      displayMessage(false, 'Failed to approve film!');
    }

    setTimeout(() => {
      setIsLoading(false);
    }, 2000);
  }


  async function rejectVideo(id) {
    console.log(id);
    setIsLoading(true);
    try {

      const response = await axios.get(
        // `${BACKEND_URL}api/films/${getIdParam()}`
        `${BACKEND_URL}api/film/reject/${id}`
      );

      // response.data;
      if (response.status === 200) {
        console.log('Successfully')
        displayMessage(true, 'Film was successfully rejected!');
        getFilmsFromLaravel();
      }

      else
        displayMessage(false, 'Failed to reject film!');
    }
    catch (e) {
      console.log(e);
      displayMessage(false, 'Failed to reject film!');
    }

    setTimeout(() => {
      setIsLoading(false);
    }, 2000);
  }

  const [ filmId , setFilmId ] = useState( undefined );

  function hoveringFilm ( id ){
    setFilmId( id );
  }
  let context = <p className="text-center"> No Films were found !</p>

  if (filteredFilms && filteredFilms?.length > 0)
    context = (
      // <table className="table table-striped table-light table-borderless " >
      <table className="table table-light table-borderless " >
        <thead className="thead-dark">
          <tr>
            <th>Filmmaker </th>
            <th scope="col">Title </th>
            <th>Status </th>
            <th>Film Type </th>
            <th>Genre </th>
            <th>Description </th>
            <th>Promo Code </th>
            <th>Created Date</th>
            <th> </th>
            {/* <th>External Link </th> */}
          </tr>
        </thead>

        <tbody>
          {
            filteredFilms?.map(film =>

              <tr key={film.uuid}
                className={
                  `${filmId === film?.id ? 'table-active' : ''} 
                  ${film?.status?.toLowerCase()?.includes('approve') ? 'table-success' :
                    //  ( film?.promo_code &&  == null ? 'table-warning' : 'table-success' ):
                    film?.status?.toLowerCase()?.includes('rejected') ? 'table-danger' : ''}`
                }
                onMouseOver={(e)=>hoveringFilm(film?.id) }
                onMouseOut={(e)=>hoveringFilm(undefined) }
                >
                <td > {film?.filmaker} </td>
                <td> {film?.film_file == null ? film?.title :
                  <a href={`/admin/screening-room?id=${film?.id}`} target={'_blank'} rel={'noreferrer'}>{film?.title}</a>
                } </td>
                <th scope="row">
                  {film?.status}
                </th>
                <td> {film?.film_type} </td>
                <td> {film?.genre} </td>
                <td> {film?.description}</td>
                <td> {film?.promo_code}</td>
                <td> {getDateDisplayString(film?.created_at)}</td>
                <td className="d-grid gap-2">

                  <>
                    {
                      !film?.status?.toLowerCase()?.includes('approve') ?
                        <button type="button" onClick={() => approveVideo(film?.id)} className=" btn btn-success btn-sm">
                          <i className="fa fa-upload" style={{ 'font-size': '24px' }}></i> Approve
                        </button >
                        : <> </>
                    }
                    {
                      !film?.status?.toLowerCase()?.includes('reject') ?
                        <button type="button" onClick={() => rejectVideo(film?.id)} className=" btn btn-danger btn-sm">
                          <i className="fa fa-upload" style={{ 'font-size': '24px' }}></i> Reject
                        </button >
                        : <> </>
                    }
                  </>



                  {
                    film?.film_file == null ?
                      <button type="button" onClick={() => goToForm(film?.id, film?.external_link)} className=" btn btn-primary btn-sm">
                        <i className="fa fa-upload" style={{ 'font-size': '24px' }}></i> upload video
                      </button >

                      : <></>

                  }
                </td>

                {/* <td className="text-wrap"> <a href={film?.external_link} target="_blank" rel="noreferrer"> {film?.external_link} </a> </td> */}
              </tr>
            )
          }
        </tbody>
      </table>

    ); //end of context


  function displayMessage(isSuccess, message) {

    const successIcon = <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" fill="currentColor" className="bi bi-exclamation-triangle-fill flex-shrink-0 me-2" viewBox="0 0 16 16" role="img" aria-label="Warning:">
      <path d="M16 8A8 8 0 1 1 0 8a8 8 0 0 1 16 0zm-3.97-3.03a.75.75 0 0 0-1.08.022L7.477 9.417 5.384 7.323a.75.75 0 0 0-1.06 1.06L6.97 11.03a.75.75 0 0 0 1.079-.02l3.992-4.99a.75.75 0 0 0-.01-1.05z" />
    </svg>

    const errorIcon = <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" fill="currentColor" className="bi bi-exclamation-triangle-fill flex-shrink-0 me-2" viewBox="0 0 16 16" role="img" aria-label="Warning:">
      <path d="M8.982 1.566a1.13 1.13 0 0 0-1.96 0L.165 13.233c-.457.778.091 1.767.98 1.767h13.713c.889 0 1.438-.99.98-1.767L8.982 1.566zM8 5c.535 0 .954.462.9.995l-.35 3.507a.552.552 0 0 1-1.1 0L7.1 5.995A.905.905 0 0 1 8 5zm.002 6a1 1 0 1 1 0 2 1 1 0 0 1 0-2z" /> </svg>

    setNotification(

      <div className={`alert ${isSuccess ? 'alert-success' : 'alert-danger'} alert-dismissible fade show`} role="alert">
        {isSuccess ? successIcon : errorIcon}
        <strong>{message}</strong>
        {/* <button type="button" className="btn-close" data-bs-dismiss="alert" aria-label="Close"></button> */}
      </div>
    );

    setTimeout(() => {
      setNotification(undefined);
    }, 5000);
  }


  // const Navbar = useMemo( () => <AppNavBar /> , []);
  return (
    <AdminProtectedRoute >
      {/* <div>
        {Navbar}
      </div> */}
      <div>
        <SignedInNavbar />
      </div>

      <div className=" justify-center align-center" style={{ overflow: 'hidden', maxHeight: '100vh', margin: '8vh' }} >

        <div className="heading d-flex flex-row justify-content-between" >
          <h1>All Films</h1>


          <div className="filters_wrapper d-flex flex-row gap-2">

            {/* Search Container */}
            <div className="mw-10 ml-2">
              <div class="input-group mr-2 ">
                <input type="text" class="form-control" placeholder="Search Movie Name" aria-label="Username" aria-describedby="basic-addon1" onChange={(e) => setNameFilter(e.target.value)} />
                {/* <div class="input-group-prepend">
                <span class="input-group-text" id="basic-addon1">@</span>
              </div> */}
              </div>
            </div>

            {/* Select container */}
            <div className="mw-10">

              <select className="form-select form-select-sm" defaultValue={'all'} onChange={(e) => setStatusFilter(e.target.value)}>
                <option value={'all'}>All</option>
                <option value={'pending'}>Pending Approval</option>
                <option value={'approved'}>Approved</option>
                <option value={'rejected'}>Rejected</option>
              </select>
            </div>
          </div>

        </div>

        {notification ? notification : <> </>}

        {/* <div className={`alert alert-danger alert-dismissible `} role="alert">
        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" fill="currentColor" class="bi bi-exclamation-triangle-fill flex-shrink-0 me-2" viewBox="0 0 16 16" role="img" aria-label="Warning:">
        <path d="M8.982 1.566a1.13 1.13 0 0 0-1.96 0L.165 13.233c-.457.778.091 1.767.98 1.767h13.713c.889 0 1.438-.99.98-1.767L8.982 1.566zM8 5c.535 0 .954.462.9.995l-.35 3.507a.552.552 0 0 1-1.1 0L7.1 5.995A.905.905 0 0 1 8 5zm.002 6a1 1 0 1 1 0 2 1 1 0 0 1 0-2z" /> </svg>
        <strong>message</strong>
        <button type="button" class="btn-close" data-bs-dismiss="alert" aria-label="Close"></button> 
      </div> */}

        <div className='col  w-auto  justify-content-center table-responsive'>



          {context}




        </div>

      </div >



    </AdminProtectedRoute>
  );
}

export default AdminAllFilms;