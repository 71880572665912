import React from "react";
import { BACKEND_URL } from "../constants/Strings";

// Renders a film component based on the props provided
function FilmEntry({
  id,
  title,
  year,
  synopsis,
  runtime,
  language,
  country,
  poster,
  reRouteFilm,
}) {
  return (
    <div className="film-entry">
      <div
        id={id}
        className="movie-div-wrapper"
        onClick={() => {
          reRouteFilm(id);
        }}
      >
        <div className="movie-description ivote-description">
          <div className="div-block-19">
            <div className="div-block-18">
              <div className="w-layout-grid grid-10">
                <div
                  id="w-node-abb58823-852f-36ea-aa39-ed89cac8a110-ebadf4bd"
                  className="div-block-20"
                >
                  <div className="movie-description-grid-title we-the-children-title">
                    {title}
                  </div>
                  <div className="movie-description-grid-year wtf-year">
                    {year}
                  </div>
                </div>
                <a href={`/screening-room?id=${id}`} className="w-inline-block">
                  <img
                    src="images/ivote-play-button.png"
                    width="56"
                    id="ivote-film-button"
                    alt=""
                    className="play-button wtc-play-button"
                  />
                </a>
              </div>
            </div>
          </div>
          <div className="div-block-21">
            <div className="movie-description-grid-description wth-desc">
              {synopsis}
            </div>
            <div className="movie-description-grid-stats we-the-children-time">
              {runtime} min | {language} | {country}
            </div>
          </div>
        </div>
        <img
          src={`${BACKEND_URL}storage/images/${poster}`}
          // srcSet="images/ivote-keyart-vertical-hq-p-500.png 500w, images/ivote-keyart-vertical-hq.png 738w"
          sizes="(max-width: 479px) 135px, 200px"
          alt=""
          className="movie-image"
        />
      </div>
      <div className="film-grid-card-title">
        <div className="text-block-3">{title}</div>
      </div>
    </div>
  );
}

export default FilmEntry;
