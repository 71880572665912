import React, { useState, useContext, useEffect } from "react";
// import {AccountContext} from '../account-context'
import { useHistory } from "react-router-dom";
import { Route, Redirect } from "react-router-dom";
import FIREBASEURL from "../firebase-url";
import axios from "axios";
import setAccountContext from "../util/set-account-context";
import SignedOutNavbar from "../components/navbar/signed-out-navbar";
import Footer from "../components/footer";
import { useRef } from "react";

export default function SignIn() {
  useEffect(() => {
    let signInNavbar = document.getElementById("sign-in-navbar-button");
    let navbarImage = document.getElementById("festiv-logo-navbar");
    window.addEventListener("scroll", function () {
      let navbar = document.getElementById("navbar-home-transparent");
      // Set starting position
      if (window.pageYOffset >= 60) {
        navbar.style.backgroundColor = "rgba(255,255,255,1)";
      } else {
        navbar.style.backgroundColor = "rgba(255,255,255,0)";
      }
    });
  });

  // const userContext = useContext(AccountContext)
  const history = useHistory();
  const [signInError, setSignInError] = useState("");
  const [signInEmail, setSignInEmail] = useState("");
  const [signInPassword, setSignInPassword] = useState("");
  const [errors, setErrors] = useState("");
  const [loading, setLoading] = useState(false);

  function isEmailValid() {
    //   For Valid Email:
    let re =
      /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    if (re.test(signInEmail)) {
      return true;
    } else {
      return false;
    }
  }
  function isPasswordValid() {
    if (signInPassword.length > 0) {
      return true;
    } else {
      return false;
    }
  }

  function handleFormChange(event) {
    if (event.target.name === "Password") {
      setSignInPassword(event.target.value);
    } else {
      setSignInEmail(event.target.value);
    }
  }

  function handleSignIn(event) {
    setLoading(true);
    if (!isEmailValid()) {
      alert("Please enter a valid email");
      setLoading(false);
      return;
    }
    if (!isPasswordValid()) {
      alert("Please enter a valid password");
      setLoading(false);
      return;
    }
    event.preventDefault();
    const requestOptions = {
      email: signInEmail,
      password: signInPassword,
    };
    
    const config = {
      headers: {
        'Access-Control-Allow-Headers': '*',
      },
    };
    
    axios
      .post(FIREBASEURL + "/signIn", requestOptions, config)
      .then((res) => {
        if (res.data.error) {
          alert(res.data.error);
          setLoading(false);
        } else {
          window.localStorage.setItem("FBIdToken", `Bearer ${res.data.token}`);
          window.localStorage.setItem("sessionStart", true);

          setAccountContext("/subscriber-homepage");
        }
      })
      .catch((err) => {
        alert(err.message);
        setLoading(false);
      });
  }

  const inputElement = useRef(null);
  document.addEventListener("keyup", function (event) {
    if (event.code === 'Enter') {
      // alert('Enter is pressed!');
      inputElement.current.click();
    }
  });


  return (
    <div>
      <div className="fixed-background-signin"></div>
      <SignedOutNavbar />
      <div className="section-11">
        <div className="sign-in-container w-container">
          <h1 className="sign-in-title">SIGN IN</h1>
          <a
            href="/sign-up.html"
            className="link-block-14 w-inline-block w-clearfix"
          >
            <div className="text-block-40">
              <p>Need an account?</p>
            </div>
          </a>
          <div className="sign-in-card">
            <div className="form-block-2 w-form">
              <div id="email-form" name="email-form" data-name="Email Form">
                <label for="Email-2" className="sign-in-field-label">
                  EMAIL
                </label>
                <input
                  type="email"
                  onChange={handleFormChange}
                  className="sign-in-text-field w-input"
                  maxlength="256"
                  name="Email"
                  data-name="Email"
                  placeholder="kubrick@2001.com"
                  id="Email-2"
                />
                <label for="Password" className="sign-in-field-label">
                  PASSWORD
                </label>
                <input
                  type="password"
                  onChange={handleFormChange}
                  className="sign-in-text-field w-input"
                  maxlength="256"
                  name="Password"
                  data-name="Password"
                  placeholder="••••••••"
                  id="Password"
                  required=""
                />
                <div className="sign-in-button-wrapper">
                  <input
                    ref={inputElement}
                    onClick={handleSignIn}
                    type="submit"
                    value={loading ? "LOADING..." : "SIGN IN"}
                    data-wait="Please wait..."
                    className="sign-in-button w-button"
                  />
                </div>
              </div>
              <div className="w-form-done">
                <div>
                  <p>Thank you! Your submission has been received!</p>
                </div>
              </div>
              <div className="w-form-fail">
                <div>
                  <p>Oops! Something went wrong while submitting the form.</p>
                </div>
              </div>
            </div>
            <div className="reset-password-wrapper">
              <a
                href="/forgot-password"
                className="reset-password-link-block w-inline-block"
              >
                <div className="text-block-41">
                  <p>Reset password</p>
                </div>
              </a>
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </div>
  );
}
