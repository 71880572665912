import React, { useState, useContext, useEffect } from "react";
import axios from "axios";
// import {AccountContext} from '../account-context'
import { useHistory } from "react-router-dom";
import SignedOutNavbar from "../components/navbar/signed-out-navbar";
import Footer from "../components/footer";

export default function ForgotPassword() {
  //   const history = useHistory()
  // const userContext = useContext(AccountContext)
  const [forgotPasswordError, setForgotPasswordError] = useState("");
  const [forgotPasswordEmail, setForgotPasswordEmail] = useState("");
  const [passwordResetSuccessMessage, setPasswordResetSuccessMessage] =
    useState("");

  function handleFormChange(event) {
    setForgotPasswordEmail(event.target.value);
  }

  function handleFormSubmit(event) {
    if (forgotPasswordEmail.length < 1) {
      alert("Please enter a valid email");
      return;
    }
    event.preventDefault();
    const requestOptions = {
      email: forgotPasswordEmail,
    };

    axios
      .post(
        "https://us-central1-festiv-fd5c6.cloudfunctions.net/api/resetPassword",
        requestOptions
      )
      .then((res) => {
        console.log("got response");
        if (res.data.error) {
          alert(res.data.error);
        } else {
          alert(
            "Success! A password reset link has been sent to your email address."
          );
          window.location.replace("/sign-in");
        }
      })
      .catch((err) => {
        alert(err.message);
      });
  }

  return (
    <div>
      <div className="fixed-background-signin"></div>
      <SignedOutNavbar />
      <div className="section-11">
        <div className="sign-in-container w-container">
          <h1 className="sign-in-title">RESET PASSWORD</h1>
          <a
            href="/sign-up.html"
            className="link-block-14 w-inline-block w-clearfix"
          >
            <div className="text-block-40">Need an account?</div>
          </a>
          <div className="sign-in-card">
            <div className="form-block-2 w-form">
              <form id="email-form" name="email-form" data-name="Email Form">
                <label for="Email-2" className="sign-in-field-label">
                  EMAIL
                </label>
                <input
                  onChange={handleFormChange}
                  type="email"
                  className="sign-in-text-field w-input"
                  maxlength="256"
                  name="Email"
                  data-name="Email"
                  placeholder="kubrick@2001.com"
                  id="Email-2"
                />
                <div className="sign-in-button-wrapper">
                  <input
                    type="submit"
                    onClick={handleFormSubmit}
                    value="RESET"
                    data-wait="Please wait..."
                    className="sign-in-button w-button"
                  />
                </div>
              </form>
              <div className="w-form-done">
                <div>Thank you! Your submission has been received!</div>
              </div>
              <div className="w-form-fail">
                <div>Oops! Something went wrong while submitting the form.</div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </div>
  );
}
