import Video from 'react-video-renderer';
import { useState, useRef, useEffect } from 'react';
import LoadingSpinner from '../pages/loading-spinner'

export default function VideoRender(props) {

  const { url, height, width } = props


  const [isPlaying, setIsPlaying] = useState(true);
  const ref = useRef();

  //to configure a url to use google drive/ dropbox links
  const isGoogleDrive = `${url}`.includes('drive.google.com')
  const isDropBox = `${url}`.includes('www.dropbox.com')

  let newUrl = '' 

  if (isGoogleDrive){ //replace '/view' at the end of link
    const subString = `${url}`.split('/view')[0]

    newUrl = subString + '/preview'
  }
  else if(isDropBox){ // replace '?dl=0' with '?raw=1'
    const subString = `${url}`.split('?dl=0')[0]

    newUrl = subString + '?raw=1'
  }

  const progressWrapStyles = {
    'display': 'flex',
    'flex-direction': 'row'
  }
  const videoWrapperStyles = {

    'width': width,
    'height': height,
    'maxHeight': '80vh'
  }


  return (


    <div >

      {/* { isGoogleDrive || isDropBox ?
        
        <iframe src={newUrl} allowFullScreen={true} allow="autoplay" style={{width: '98vw', height: 'auto', 'minHeight' : '40vh',  'maxHeight': '80vh'}}>

        </iframe>

        : */}
        <Video src={isGoogleDrive || isDropBox ? newUrl : url}
          videoRef={ref}
          controls={true}
          // styles ={ videoStyles}
          autoPlay={true}
          height={height}
          width={width}
        >
          {(video, state, actions) => (
            <div id='video-wrapper' style={videoWrapperStyles} >
              {
                // state.isLoading && !state.error ?
                //   <LoadingSpinner />

                //   :
                //   !state.isLoading && state.error ?
                //   <p> failed to Load video </p>

                //   :
                state.status === 'errored' || state.error ?
                  <div style={{ color: 'red', display: 'flex', 'flexDirection': 'column', height: '100%', width: '100vw', justifyContent: 'center', alignContent: 'center' }}>
                    <span style={{ 'font-size': '100px' }}>&#9888;</span>
                    <h2 > Failed to load video! </h2>
                  </div>

                  :

                  <>
                    {video ?? <LoadingSpinner />}
                    
                  </>

              }
              {/* <div className='progress-wrapper' style={progressWrapStyles}>
            <div>{state.currentTime} / {state.duration} / {state.buffered}</div>

            {!isPlaying ?
              <button onClick={() => { setIsPlaying(true); actions.play() }}> Play</button>
              :
              <button onClick={() => { setIsPlaying(false); actions.pause() }}>Pause</button>
            }
            <progress value={state.currentTime} max={state.duration} onChange={actions.navigate} />
            <div>{state.currentTime} / {state.duration} </div>
             {
              // Volume settings
              <progress value={state.volume} max={1} onChange={actions.setVolume} />
              
            }
            <button onClick={actions.requestFullScreen}>Fullscreen</button>
          </div> */}


              {/* 
          <progress value={state.currentTime} max={state.duration} onChange={actions.navigate} />
          <progress value={state.volume} max={1} onChange={actions.setVolume} />
          <button onClick={actions.play}>Play</button>
          <button onClick={actions.pause}>Pause</button>
          <button onClick={actions.requestFullScreen}>Fullscreen</button> 
          */}
            </div>
          )}
        </Video>



      {/* } */}
    </div >
  )
}