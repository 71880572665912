import React, { useEffect, useState } from "react";
import Carousel from "react-bootstrap/Carousel";
// import 'bootstrap/dist/css/bootstrap.min.css';
import SignedInNavbar from "../components/navbar/signed-in-navbar";
import Footer from "../components/footer";
import FilmEntry from "./film-entry";
import FeaturedFilmEntry from "./featured-film-entry";
import FeaturedFilmEntries from "./featured-film-entries";
import Axios from "axios";
import { BACKEND_URL, FRONTEND_URL } from "../constants/Strings";

// This component is rendered when a subscriber is logged in. It shows all recommended films along with the featured films
export default function SubscriberHomepageCopy() {
  const [films, setFilms] = useState([]); // All films
  const [featuredFilms, setFeaturedFilms] = useState([]); // All featured films
  const filmTitles = []; // All the film titles
  const filmIds = []; // All the film ids
  const [isLoading, setIsLoading] = useState(false);

  //Converts string to kebabcase
  const toKebabCase = (str) =>
    str &&
    str
      .match(
        /[A-Z]{2,}(?=[A-Z][a-z]+[0-9]*|\b)|[A-Z]?[a-z]+[0-9]*|[A-Z]|[0-9]+/
      )
      .map((x) => x.toLowerCase())
      .join("-");

  // Reroutes to the screening room of a specified film
  function rerouteFilm(urlSnippet) {
    window.location.assign(`${FRONTEND_URL}screening-room?id=` + urlSnippet);
  }

  useEffect(() => {
    // Get all the films from the database
    const getFromLaravel = async (api) => {
      setIsLoading(true);

      const response = await Axios.get(`${BACKEND_URL}${api}`);
      if (api === "api/films") {
        // console.log(response.data.films);
        setFilms(response.data.films);
        setIsLoading(false);
      }
      if (api === "api/get-featured") {
        // console.log(response.data.featuredFilms);
        setFeaturedFilms(response.data.featuredFilms);
        setIsLoading(false);
      }
    };
    getFromLaravel("api/films");
    getFromLaravel("api/get-featured");

    if (films && films.length > 0) {
      films.forEach((film) => {
        filmTitles.push(toKebabCase(film.title));
        filmIds.push(film.id);
      });
      console.log(filmTitles);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    // function setFilmLinks() {
    //   function rerouteFilm(urlSnippet) {
    //     window.location.replace('http://localhost:3000/screening-room?id='+urlSnippet);
    //   }
    //   function isMobile() {
    //     // credit to Timothy Huang for this regex test:
    //     // https://dev.to/timhuang/a-simple-way-to-detect-if-browser-is-on-a-mobile-device-with-javascript-44j3
    //     if (
    //       /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(
    //         navigator.userAgent
    //       )
    //     ) {
    //       return true;
    //     } else {
    //       return false;
    //     }
    //   }
    //   if (isMobile) {
    //     filmIds.forEach((id) => {
    //       document.getElementById(`${id}`).addEventListener("click", () => {
    //         rerouteFilm(id);
    //       });
    //     });
    //     // document.getElementById("ivote-film").addEventListener("click", () => {
    //     //   rerouteFilm("ivote");
    //     // });
    //     // document
    //     //   .getElementById("the-god-chair-film")
    //     //   .addEventListener("click", () => {
    //     //     rerouteFilm("the-god-chair");
    //     //   });
    //     // document
    //     //   .getElementById("out-with-a-bang-film")
    //     //   .addEventListener("click", () => {
    //     //     rerouteFilm("out-with-a-bang");
    //     //   });
    //     // document.getElementById("evie-film").addEventListener("click", () => {
    //     //   rerouteFilm("evie");
    //     // });
    //     // document
    //     //   .getElementById("lost-and-found-film")
    //     //   .addEventListener("click", () => {
    //     //     rerouteFilm("lost-and-found");
    //     //   });
    //     // document
    //     //   .getElementById("crappy-birthday-film")
    //     //   .addEventListener("click", () => {
    //     //     rerouteFilm("crappy-birthday");
    //     //   });
    //   }
    // }
    // window.onload = setFilmLinks;

    function blurCorrespondingImage() {
      //this.nextSibling.style.filter = "blur(5px);";
      //this.nextSibling.setAttribute("style","-webkit-filter: blur(5px);");
      this.style.transform = "scale(1.03)";
      this.getElementsByClassName("play-button")[0].style.marginTop = "0px";
    }
    function unblurCorrespondingImage() {
      //this.nextSibling.style.filter = "none";
      //this.nextSibling.style.removeProperty("-webkit-filter");
      this.style.transform = "scale(1)";
      this.getElementsByClassName("play-button")[0].style.marginTop = "10px";
    }
    // Create A Function To Inject Hover Interactions, But Wait For Page To Load First
    function setMoviesTransitions() {
      let movieDescriptions =
        document.getElementsByClassName("movie-description");
      for (var i = 0; i < movieDescriptions.length; i++) {
        //Blur The Actual Image, Which Is The Description's Sibling
        movieDescriptions[i].addEventListener(
          "mouseover",
          blurCorrespondingImage
        );
        movieDescriptions[i].addEventListener(
          "mouseout",
          unblurCorrespondingImage
        );
      }
    }
    setMoviesTransitions();

    // function stuff() {
    //   setTimeout(() => {
    //     let container = document.getElementById("welcome-container");
    //     container.style.paddingTop = "140px";
    //     container.style.opacity = "1";
    //     function showContent() {
    //       let welcomeDiv = document.getElementById("fixed-welcome-div");
    //       welcomeDiv.style.opacity = "0";
    //       setTimeout(() => {
    //         welcomeDiv.style.display = "none";
    //       }, 400);
    //     }
    //     let watchButton = document.getElementById("start-watching-button");
    //     watchButton.addEventListener("click", showContent);
    //   }, 500);
    // }
    // document.addEventListener("DOMContentLoaded", stuff);
  });

  console.log(films);
  console.log(featuredFilms);
  return (
    <div className="body-3">
      <SignedInNavbar />

      <div id="fixed-welcome-div" className="film-popup">
        <div id="welcome-container" className="container-3 w-container">
          <div className="festiv-logo-wrapper-welcome">
            <img
              src="/images/festiv-logo.svg"
              loading="lazy"
              alt=""
              className="image-41"
            />
          </div>
          <div className="welcome-wrapper">
            <div className="welcome-text">
              <h1>WELCOME</h1>
            </div>
          </div>
          <div className="start-watching-button-wrapper">
            <a
              id="start-watching-button"
              href="##"
              className="button-4 w-button"
            >
              <h2>START WATCHING</h2>
            </a>
          </div>
          <div className="html-embed-3 w-embed w-script"></div>
        </div>
      </div>
      <div className="featured-films">
        <div className="homepage-container">
          <div className="featured-films-title-wrapper">
            <h1 className="heading-4 featured-films-title-subhomepage">
              FEATURED
            </h1>
          </div>
          {/* <div
            data-delay="6000"
            data-animation="slide"
            data-autoplay="1"
            data-easing="ease-in-out"
            data-duration="700"
            data-infinite="1"
            className="slider-4 w-slider"
          >
            <div className="mask-4 w-slider-mask">
              
                      {featuredFilms &&
                        featuredFilms.length > 0 &&
                        featuredFilms.map((featuredFilm, key) => (
                          <FeaturedFilmEntry
                            key={key}
                            id={featuredFilm.id}
                            title={featuredFilm.title}
                            year={featuredFilm.year}
                            synopsis={featuredFilm.synopsis}
                            runtime={featuredFilm.runtime}
                            language={featuredFilm.language}
                            country={featuredFilm.country}
                            poster={featuredFilm.poster}
                            className="d-block w-100"
                          />
                        ))}
                  </div>
             
            <div className="left-arrow w-slider-arrow-left">
              <img
                src="/images/left-arrow-icon.png"
                alt=""
                className="image-18"
              />
            </div>
            <div className="right-arrow-2 w-slider-arrow-right">
              <img
                src="/images/right-arrow-icon.png"
                alt=""
                className="image-19"
              />
            </div>
            <div className="slide-nav-3 w-slider-nav w-round"></div>
          </div> */}
          <FeaturedFilmEntries
            filmsData={featuredFilms && featuredFilms.reverse()}
          />
        </div>
      </div>
      <div className="video-grid-js w-embed w-script"></div>
      <div className="div-block-11">
        <div className="div-block-25">
          <h1 className="heading-4 recommended-films-title-subhomepage">
            OFFICIAL SELECTIONS
          </h1>
        </div>
      </div>
      <div className="section-7">
        <div className="code-for-film-grid w-embed">
          <style>
            {`
      .movie-description:hover {
        opacity:1;
      }
      `}
          </style>
        </div>
        <div className="film-grid-section">
          <div className="film-grid-container">
            <div id="films-grid" className="w-layout-grid film-grid">
              <div className="film-entry official-selection-exlg-screens">
                <div className="movie-div-wrapper">
                  <img
                    src="/images/festiv-official-selection.png"
                    srcset="/images/festiv-official-selection-p-500.png 500w, /images/festiv-official-selection-p-800.png 800w, /images/festiv-official-selection-p-1080.png 1080w, /images/festiv-official-selection.png 1200w"
                    sizes="(max-width: 1919px) 100vw, 200px"
                    alt=""
                    className="movie-image"
                  />
                </div>
                <div className="film-grid-card-title"></div>
              </div>
              {films &&
                films.length > 0 &&
                films.reverse().map((film, key) => (
                  <FilmEntry
                    key={key}
                    // id={toKebabCase(`${film.title} film`)}
                    id={`${film.id}`}
                    title={film.title}
                    year={film.year}
                    synopsis={film.synopsis}
                    runtime={film.runtime}
                    language={film.language}
                    country={film.country}
                    poster={film.poster}
                    reRouteFilm={rerouteFilm}
                  />
                ))}
              <div className="film-entry official-selection-exlg-screens">
                <div className="movie-div-wrapper">
                  <img
                    src="/images/festiv-official-selection.png"
                    srcset="/images/festiv-official-selection-p-500.png 500w, /images/festiv-official-selection-p-800.png 800w, /images/festiv-official-selection-p-1080.png 1080w, /images/festiv-official-selection.png 1200w"
                    sizes="(max-width: 1919px) 100vw, 200px"
                    alt=""
                    className="movie-image"
                  />
                </div>
                <div className="film-grid-card-title"></div>
              </div>
            </div>
          </div>
          <div className="html-embed-5 w-embed w-script"></div>
        </div>
      </div>
      <div className="div-block-11">
        <div className="div-block-25">
          <h1 className="heading-4 featured-articles-title-subhomepage">
            FEATURED ARTICLES
          </h1>
        </div>
      </div>
      <div className="section-2">
        <div className="div-block-40">
          <div className="w-layout-grid grid-8">
            <div
              id="w-node-_9c9baa08-56e0-2fbf-4e89-7ccf9330d521-10984a00"
              className="div-block-39"
            >
              <a
                href="https://variety.com/2022/tv/news/mindy-kaling-norman-lear-achievement-award-producers-guild-event-1235434497/"
                target="_blank"
                rel="noreferrer"
                className="w-inline-block"
              >
                <img
                  src="/images/mindy.png"
                  width="114"
                  sizes="(max-width: 1919px) 113.984375px, 200px"
                  srcSet="/images/variety-logo-in-white-p-500.png 500w, /images/variety-logo-in-white.png 1129w"
                  alt=""
                  className="image-34"
                />
              </a>
            </div>
            <div
              data-delay="6000"
              data-animation="slide"
              data-autoplay="1"
              data-duration="500"
              data-infinite="1"
              id="w-node-_3fe0d1fa-de4f-9d45-4efe-32ad0d279ec3-10984a00"
              className="slider-2 w-slider"
            >
              <div className="mask-2 w-slider-mask">
                <div className="slide-4 w-slide">
                  <div className="div-block-14">
                    <div className="variety-article-element">
                      <a
                        href="https://variety.com/2022/tv/news/adam-sandler-gotham-awards-acceptance-speech-hustle-1235443853/"
                        target="_blank"
                        rel="noreferrer"
                        className="link-block-9 w-inline-block"
                      >
                        <div className="w-layout-grid grid-9">
                          <div className="div-block-22">
                            <img
                              src="/images/adam.png"
                              width="683"
                              alt=""
                              className="image-14"
                            />
                          </div>
                          <div
                            id="w-node-_603e189f-c4b8-9b34-9b39-dc67d7e02670-10984a00"
                            className="div-block-15"
                          >
                            <div className="featured-article-title">
                              <h2>
                                Adam Sandler Brings Down the House With
                                Hilarious Gotham Awards Acceptance Speech
                              </h2>
                            </div>
                            <div className="div-block-13"></div>
                            <div className="featured-article-description directors-variety-elem">
                              <p>
                                Adam Sandler lit up the 32nd annual Gotham
                                Awards on Monday evening with his acceptance
                                speech for the...
                              </p>
                            </div>
                          </div>
                        </div>
                      </a>
                    </div>
                  </div>
                </div>
                <div className="slide-4 w-slide">
                  <div className="div-block-14">
                    <div className="variety-article-element">
                      <a
                        href="https://variety.com/2022/tv/news/mindy-kaling-norman-lear-achievement-award-producers-guild-event-1235434497/"
                        target="_blank"
                        rel="noreferrer"
                        className="link-block-10 w-inline-block"
                      >
                        <div className="w-layout-grid grid-9">
                          <div className="div-block-22">
                            <img
                              src="/images/mindy.png"
                              width="168"
                              sizes="(max-width: 479px) 105px, (max-width: 767px) 27vw, 160px"
                              srcSet="/images/mindy.png 500w"
                              alt=""
                              className="image-14"
                            />
                          </div>
                          <div
                            id="w-node-c06e4f9b-2316-e59d-aaaf-1859c30aba2d-10984a00"
                            className="div-block-15"
                          >
                            <div className="featured-article-title">
                              <h2>
                                Mindy Kaling to Receive Norman Lear Achievement
                                Award
                              </h2>
                            </div>
                            <div className="div-block-13"></div>
                            <div className="featured-article-description mulan-description">
                              <p>
                                The honor is meant to recognize a producer or
                                producing team for their body of work in
                                television.
                              </p>
                            </div>
                          </div>
                        </div>
                      </a>
                    </div>
                  </div>
                </div>
              </div>
              <div className="left-arrow-2 w-slider-arrow-left">
                <div className="w-icon-slider-left"></div>
              </div>
              <div className="right-arrow-3 w-slider-arrow-right">
                <div className="w-icon-slider-right"></div>
              </div>
              <div className="slide-nav-2 w-slider-nav w-round"></div>
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </div>
  );
}
