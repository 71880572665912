import React, { useEffect } from "react";
import SignedOutNavbar from "../components/navbar/signed-out-navbar";
import Footer from "../components/footer";

export default function SelectFilmSubmission() {
  return (
    <div>
      <div className="div-block-62"></div>
      <SignedOutNavbar />
      <div className="section-12">
        <div className="container-5 choose-project-type-container w-container">
          <h1 className="submit-your-film-title">Choose Your Project Type</h1>
          <div className="text-block-42">
            <h2>What type of project are you submitting for?</h2>
          </div>
          <div className="w-layout-grid grid-21 submission-type-grid">
            <div className="submission-steps-card submission-type-card">
              <div className="w-layout-grid grid-25">
                <div className="steps-card-title">
                  <h2>OPTION 1</h2>
                </div>
                <div className="text-block-45">
                  <p>Feature</p>
                  <p>Film</p>
                </div>
                <div className="div-block-68">
                  <div className="text-block-46">
                    <div className="old-film-submission-price">
                      <p>$225</p>
                    </div>
                    <div className="new-film-submission-price">
                      <p>$125</p>
                    </div>
                  </div>
                  <div className="promo-percentage-off">
                    <div className="text-block-48">
                      <p>(45% Off)</p>
                    </div>
                  </div>
                </div>
                <div>
                  <a
                    href="https://payments.festivmedia.com/feature-film"
                    target="_blank"
                    className="button-6 submit-now-button w-button"
                  >
                    <p>PAY NOW</p>
                  </a>
                </div>
                <div className="powered-by-wrapper">
                  <div className="text-block-47">
                    <p>Powered by</p>
                  </div>
                  <img
                    src="/images/stripe-dark-logo.png"
                    loading="lazy"
                    width="35"
                    alt=""
                    className="image-42"
                  />
                </div>
              </div>
            </div>
            <div className="submission-steps-card submission-type-card">
              <div className="w-layout-grid grid-25">
                <div className="steps-card-title">
                  <h2>OPTION 2</h2>
                </div>
                <div className="text-block-45">
                  <p>Short Film or</p>
                  <p>TV Pilot</p>
                </div>
                <div className="div-block-68">
                  <div className="text-block-46">
                    <div className="old-film-submission-price">
                      <p>$129</p>
                    </div>
                    <div className="new-film-submission-price">
                      <p>$70</p>
                    </div>
                  </div>
                  <div className="promo-percentage-off">
                    <div className="text-block-48">
                      <p>(45% Off)</p>
                    </div>
                  </div>
                </div>
                <div>
                  <a
                    href="https://payments.festivmedia.com/short-film"
                    target="_blank"
                    className="button-6 submit-now-button w-button"
                  >
                    <p>PAY NOW</p>
                  </a>
                </div>
                <div className="powered-by-wrapper">
                  <div className="text-block-47">
                    <p>Powered by</p>
                  </div>
                  <img
                    src="/images/stripe-dark-logo.png"
                    loading="lazy"
                    width="35"
                    alt=""
                    className="image-42"
                  />
                </div>
              </div>
            </div>
            <div className="submission-steps-card submission-type-card">
              <div className="w-layout-grid grid-25">
                <div className="steps-card-title">
                  <h2>OPTION 3</h2>
                </div>
                <div className="text-block-45">
                  <p>Student</p>
                  <p>Film</p>
                </div>
                <div className="div-block-68">
                  <div className="text-block-46">
                    <div className="old-film-submission-price">
                      <p>$85</p>
                    </div>
                    <div className="new-film-submission-price">
                      <p>$47.50</p>
                    </div>
                  </div>
                  <div className="promo-percentage-off">
                    <div className="text-block-48">
                      <p>(45% Off)</p>
                    </div>
                  </div>
                </div>
                <div>
                  <a
                    href="https://payments.festivmedia.com/student-film"
                    target="_blank"
                    className="button-6 submit-now-button w-button"
                  >
                    <p>PAY NOW</p>
                  </a>
                </div>
                <div className="powered-by-wrapper">
                  <div className="text-block-47">
                    <p>Powered by</p>
                  </div>
                  <img
                    src="/images/stripe-dark-logo.png"
                    loading="lazy"
                    width="35"
                    alt=""
                    className="image-42"
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </div>
  );
}
