import React, { useState, useEffect, useRef, useMemo } from "react";
import FormInput from "./form-input";
// import FileUpload from "./file-upload";
import { postToLaravel } from "../util/apitest";
import { v4 as uuidv4 } from "uuid";
import UploadVideo from "./upload-video";
import "../../src/components/form.css";
import SignedOutNavbar from "../../src/components/navbar/signed-out-navbar";
import Footer from "../../src/components/footer";
import Dropdown from "./dropdown-list";
import Modal from "./modal";
import { Upload } from "@aws-sdk/lib-storage";
import { S3Client, S3, PutObjectCommand } from "@aws-sdk/client-s3";
import { uploadFile } from "../util/uploadFile";
import { regex } from "uuidv4";
import { getDisplayServerError } from "../util/api-error-messages";
import AppNavBar from "../components/navbar/get-navbar";

// import axios from "axios";

const S3_BUCKET = "festiv-films"; // The name of the festiv media bucket on AWS
const REGION = "us-east-2"; // The region the bucket was created with
// AWS.config.update({
//   accessKeyId: "AKIAQOPTG43WUDCSZU6Z",
//   secretAccessKey: "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",
// });
// const myBucket = new AWS.S3({
//   params: { Bucket: S3_BUCKET },
//   region: REGION,
// });
// Renders a form where a filmaker can submit a film for approval. The video for the film is also uploaded here

//const MAX_CHUNK_SIZE =  5000000000; //Min limit 5 MB - 5GB

export default function FilmSubmissionForm() {
  const [MAX_CHUNK_SIZE, setMaxChunkSize] = useState(100000000) //(5000000000);

  
  const [isCancelled, setIsCancelled] = useState(true);
  const [isPaid, setIsPaid] = useState(false);


  // States for the different form inputs
  const [uuid, setUuid] = useState("");
  const [email, setEmail] = useState("");
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [message, setMessage] = useState("");
  const [instagram, setInstagram] = useState("");
  const [facebook, setFacebook] = useState("");
  const [twitter, setTwitter] = useState("");
  const [promoCode, setPromoCode] = useState("");

  const [projectTitle, setProjectTitle] = useState("");
  const [projectSynopsis, setProjectSynopsis] = useState("");
  const [projectType, setProjectType] = useState("");
  const [projectDescription, setProjectDescription] = useState("");
  const [projectLength, setProjectLength] = useState("");
  const [studentFilm, setStudentFilm] = useState(0);
  const [filmSchool, setFilmSchool] = useState("");
  const [projectLanguage, setProjectLanguage] = useState("");
  const [englishSubtitled, setEnglishSubtitled] = useState("");
  const [englishDubbed, setEnglishDubbed] = useState("");
  const [originRegion, setOriginRegion] = useState("");
  const [originCountry, setOriginCountry] = useState("");
  const [completionYear, setCompletionYear] = useState("");
  const [filmGenre, setFilmGenre] = useState("");
  const [filmScreening, setFilmScreening] = useState();
  const [festivalScreenings, setFestivalScreenings] = useState("");
  const [audienceType, setAudienceType] = useState("");
  // const [submissionOption, setSubmissionOption] = useState("");
  const [rightsAgreement, setRightsAgreement] = useState("");
  const [fileLink, setFileLink] = useState("");
  const [poster, setPoster] = useState();
  const [banner, setBanner] = useState();
  const [file, setFile] = useState("");
  const [imdb, setIMDB] = useState("");
  // const [img, setImage] = useState();

  const [progress, setProgress] = useState(0); // progress of upload
  const [selectedFile, setSelectedFile] = useState(null); // file to be uploaded
  const [id, setId] = useState("");
  const [show, setShow] = useState(false);
  const [success, setSuccess] = useState("Submitting Form...");
  const [error, setError] = useState();
  const [list, setList] = useState({ markets: [] });

  const [uploadData, setuploadData] = useState();
  const [uploadResponses, setUploadResponses] = useState([]);
  const inputRef = useRef(null);

  useEffect(() => {
    var result = "";
    var characters =
      "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";
    var charactersLength = characters.length;
    for (var i = 0; i < 5; i++) {
      result += characters.charAt(Math.floor(Math.random() * charactersLength));
      setId(result);
    }
  }, []);

  const showModal = () => {
    setShow(true);
  };

  const hideModal = () => {
    setShow(false);
  };

  const handleFileInput = (e) => {
    const name = e.target.files[0].name;
    const newName = /\s/.test(name) ? name.replaceAll(" ", "_") : name;
    setSelectedFile(e.target.files[0]);
    console.log(id + "-" + newName);
    getFile(id + "-" + newName); // get the file name and store it


    const fileSize = e.target.files[0].size

    let avgSize
    let mb = 1024 * 1024

    if (fileSize > 1000 * mb * 100)
      avgSize = Math.floor(fileSize / 200) // fileSize / 200
    else if (fileSize / 100 > 100 * mb)
      avgSize = Math.floor(fileSize / 100) // 100 * mb
    else if (fileSize / 100 > 10 * mb)
      avgSize = Math.floor(fileSize / 100)// 10 * mb
    else if (fileSize / 50 > 10 * mb)
      avgSize = Math.floor(fileSize / 50) //10 * mb
    else
      avgSize = 5 * mb

    // splits to 100 > segments
    // if (fileSize > 1000 * mb * 100)
    //   avgSize = Math.floor(fileSize / 200)
    // else if (fileSize / 100 > 100 * mb)
    //   avgSize = 100 * mb
    // else if (fileSize / 100 > 10 * mb)
    //   avgSize = 10 * mb
    // else if (fileSize / 50 > 10 * mb)
    //   avgSize = 10 * mb
    // else
    //   avgSize = 5 * mb


    setMaxChunkSize(avgSize ?? 200000000)
    console.log('Max segment size', MAX_CHUNK_SIZE)
    console.log('File size', fileSize)
    // console.log('Number segments', fileSize / MAX_CHUNK_SIZE)
    // const fileSize = e.target.files[0].size
    // if (fileSize> MAX_CHUNK_SIZE * 10)
    // setMaxChunkSize(MAX_CHUNK_SIZE * 10)

    // if (fileSize > MAX_CHUNK_SIZE * 100)
    //   setMaxChunkSize(MAX_CHUNK_SIZE * 100)

    // if (fileSize > MAX_CHUNK_SIZE * 1000)
    //   setMaxChunkSize(MAX_CHUNK_SIZE * 1000)
  };

  const resetFileInput = () => {
    // 👇️ reset input value
    inputRef.current.value = null;
    setFile("");
  };



  const uploadFile = async (file) => {
    setError(undefined);

    console.log('Attempting File Upload...')
    const name = file.name;
    const newName = /\s/.test(name) ? name.replaceAll(" ", "_") : name;
    const key = id + "-" + newName;
    const target = { Bucket: S3_BUCKET, Key: key, Body: file };
    const credentials = {
      accessKeyId: "AKIAQOPTG43WUDCSZU6Z",
      secretAccessKey: "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",
    };


    // Segment file
    const fileSize = file.size
    const totalCount = fileSize % MAX_CHUNK_SIZE === 0 ? fileSize / MAX_CHUNK_SIZE : Math.floor(fileSize / MAX_CHUNK_SIZE) + 1; // Total count

    const uploadParts = [];
    const fileSegments = [];

    for (let i = 1; i <= totalCount; i++) {
      const endLength = i * MAX_CHUNK_SIZE > fileSize ? fileSize : i * MAX_CHUNK_SIZE

      fileSegments.push(file.slice((i - 1) * MAX_CHUNK_SIZE, endLength));
    }


    try {
      // Old working upload Method

      // const parallelUploads3 = new Upload({
      //   client: new S3Client({ region: REGION, credentials: credentials }),
      //   params: target,
      //   leavePartsOnError: false, // optional manually handle dropped parts
      //   // abortController : () =>{},

      // });

      // parallelUploads3.on("httpUploadProgress", (progress) => {
      //   console.log(progress);
      //   setProgress(Math.round((progress.loaded / progress.total) * 100));
      // });

      // parallelUploads3.done();



      // New Upload

      const abortController = new AbortController();

      const client = new S3({
        region: REGION,
        credentials: credentials,
        correctClockSkew: true, // whether to apply a clock skew correction and retry requests that fail because of an skewed client clock. Defaults to false.
        //systemClockOffset : getTomeZoneOffset(), //an offset value in milliseconds to apply to all signing times. Use this to compensate for clock skew when your system may be out of sync with the service time.
        httpOptions: {
          xhrAsync: false, //Set to false to send requests synchronously. Defaults to true (async on)
          timeout: 600000, //Sets the socket to timeout after timeout milliseconds of inactivity on the socket. Defaults to two minutes (120000).

        }
      });





      const bucketParams = { Bucket: S3_BUCKET, Key: key }; //, Body: file };


      // Initates the upload process returning {Bucket, key, uploadID}
      client.createMultipartUpload(bucketParams, async (err, data) => {
        // console.log('Data', data)
        if (err) {
          console.log('Error', err)
          setError('Failed to Establish connection to upload File \n Retrying....')

          // setError(prevState => `${prevState} \n ${err.name}: ${err.message}`);
        }
        else if (data) {
          setuploadData(data)
          console.log(' Success initiate Upload', data)


          // for each segment upload 


          const uploadTracker = {
            totalSegmentsSent: 0,
            freeSegments: 10
          }



          const uploadPartParams = {
            // Bucket: 'STRING_VALUE', /* required */
            // Key: 'STRING_VALUE', /* required */
            // PartNumber: 'NUMBER_VALUE', /* required */
            // UploadId: 'STRING_VALUE', /* required */
            ...bucketParams,
            Key: bucketParams.Key,
            Bucket: bucketParams.Bucket,
            Body: undefined,
            UploadId: data?.UploadId,
            // PartNumber: 1,
          }
          console.log(`File size ${fileSize}\n, total Segments: ${totalCount} \n ChunkSize : ${MAX_CHUNK_SIZE} \n\n\n`)

          //Custom Upload Fn
          const callUpload = (currPartNumber) => {
            console.log(`Calling Initial Part Upload for part #${currPartNumber}`)
            //Reducer free request
            uploadTracker['freeSegments'] -= 1
            uploadTracker['totalSegmentsSent'] += 1 //increase # of segments sent

            uploadPartParams['Body'] = fileSegments[currPartNumber - 1];
            uploadPartParams['PartNumber'] = currPartNumber;
            // console.log('Upload Part Params', uploadPartParams)

            client.uploadPart(uploadPartParams, (error, data, currPartNo = currPartNumber) => {
              if (error) {
                console.log('Upload Error', error)
                setError(`Upload Error : ${error.message === 'Failed to fetch' ? 'Failed to upload' : error.message}`)
                // setError('Upload Error: failed to upload file')

                // setError(prevState => `${prevState} \n ${error.name}: ${error.message}`);
                if (!isCancelled)
                  client.abortMultipartUpload(uploadPartParams, (err, data) => {
                    if (err) {
                      // console.log('Failed to Cancel Upload')
                      // setError(prevState => `Failed to  Upload Segment: \n ${err?.message}`);
                      setError(`Failed to Upload Video Parts`);
                    }

                    console.log('Cancelled Upload')
                    // setError("File Upload Cancelled: Failed to upload")
                    setIsCancelled(true)
                  })
                return
              }

              if (uploadParts.length <= totalCount - 1 || totalCount === 1)
                setProgress(totalCount === 1 ? 99 : Math.round(MAX_CHUNK_SIZE * uploadParts.length / fileSize * 100));

              console.log(`Successfully uploaded segment #${currPartNo}`, data)
              setUploadResponses(prevState => [...prevState, data]);

              uploadParts.push({ ETag: data?.ETag, PartNumber: currPartNo });
              uploadTracker['freeSegments'] += 1

              if (uploadTracker['totalSegmentsSent'] < totalCount)
                callUpload(uploadTracker['totalSegmentsSent'] + 1)
              // trigger complete ?
              else if (uploadParts.length === totalCount) {
                const sortedParts = uploadParts.sort((a, b) => a.PartNumber - b.PartNumber);

                const completeParams = {
                  Bucket: bucketParams.Bucket, /* required */
                  Key: bucketParams.Key, /* required */
                  UploadId: uploadPartParams?.UploadId, /* required */
                  MultipartUpload: {
                    Parts: sortedParts//[{ ETag: data?.ETag, PartNumber: 1 }] // array of Completed Part 
                  }
                }

                console.log('Complete Params', completeParams)
                setTimeout(() => client.completeMultipartUpload(completeParams, (err, data) => {
                  if (err) {
                    console.log(err, err.stack); // an error occurred
                    // setError("Failed to complete")

                    // setError(prevState => `Failed to Complete Upload \n ${err?.message}`);
                    setError(prevState => `Failed to Complete Video Upload `);
                  }
                  else console.log('Complete Response', data);           // successful response
                  setProgress(100)
                  /*
                  data = {
                   Bucket: "acexamplebucket", 
                   ETag: "\"4d9031c7644d8081c2829f4ea23c55f7-2\"", 
                   Key: "bigobject", 
                   Location: "https://examplebucket.s3.<Region>.amazonaws.com/bigobject"
                  }
                  */
                }), 3000);


              }

            })// end of uploadPart ( )
          }
          // uploadFirst 10
          const initialUploads = totalCount < 10 ? totalCount : 10
          for (let i = 1; i <= initialUploads; i++) {

            callUpload(i)

          } //eND OF UPLOAD FOR LOOP 




        } // end of success createMultipartUpload

      }); // end of createMultiUpload

    }
    catch (error) {
      console.log(error)
      setError('Upload Request Failed')
      // const { requestId, cfId, extendedRequestId } = error.$$metadata;
      // console.log('File Upload Error', { requestId, cfId, extendedRequestId });
      /**
       * The keys within exceptions are also parsed.
       * You can access them by specifying exception names:
       * if (error.name === 'SomeServiceException') {
       *     const value = error.specialKeyInException;
       * }
       */
    }

    // const params = {
    //   ACL: "public-read",
    //   Body: file,
    //   Bucket: S3_BUCKET,
    //   Key: id + "-" + newName,
    // };

    // /**
    //  * Adds an object to the bucket. You must have write permissions on a bucket to
    //  * add an object to it. Amazon S3 never adds partial objects; if you receive
    //  * a success response, Amazon S3 added the entire object to the bucket.
    //  */
    // myBucket
    //   .putObject(params)
    //   .on("httpUploadProgress", (evt) => {
    //     setProgress(Math.round((evt.loaded / evt.total) * 100)); // Sets the progress dynamically
    //   })
    //   .send((err) => {
    //     if (err) console.log(err);
    //   });
  }
    ;

  const rights =
    "I hereby claim I have the full rights to submit this project for Festiv consideration and inclusion on the platform and agree to indemnify, and hold Festiv, its affiliates, subsidiaries, joint ventures, third-party service providers, and its respective employees, contractors, agents, officers, and directors harmless from all liabilities, claims, and expenses that may arise out of, or be related to, the submitted content.";

  // const manualOption = " Direct upload (accepted file types: mp4, mov, avi)";
  // const linkOption =
  //   " Link to project which is accessible on an online video sharing or storage platform (e.g.,  YouTube, Vimeo, Google Drive, Dropbox, etc.)";
  // const distribution_list = '{"name":"John", "age":30, "city":"New York"}';
  const regions = [
    "Select Origin From the Dropdown",
    "North America",
    "South America",
    "Europe",
    "Asia",
    "Middle East",
    "India",
    "West Africa",
    "South Africa",
    "North Africa",
    "Australia / New Zealand",
    "South Pacific",
  ];

  const genres = [
    "Action",
    "Comedy",
    "Documentary",
    "Drama",
    "Family/Kids",
    "Horror/Thriller",
    "Science Fiction",
    "Instructional",
  ];

  const handleSubmit = async (e) => {
    e.preventDefault();
    setError(undefined);

    const RIGHTS = document.getElementById("rights-agreement");

    console.log('Promo:', promoCode);
    if (projectTitle?.length === 0 || !projectTitle) {
      let msg = "Please enter project Title";
      alert(msg);
      return false;
    }
    if (projectSynopsis?.length === 0 || !projectSynopsis) {
      let msg = "Please enter Film Synposis";
      alert(msg);
      return false;
    }

    if (projectType?.length === 0 || !projectType) {
      let msg = "Please Select a Project Type";
      alert(msg);
      return false;
    }

    if (projectDescription?.length === 0 || !projectDescription) {
      let msg = "Please select Film Description";
      alert(msg);
      return false;
    }

    if (projectLength.length === 0) {
      alert(
        "Please ensure that you added Film Runtime."
      );
      return false;
    }

    // if ( !studentFilm ) {
    //   alert(
    //     "Please select if you this is a student film."
    //   );
    //   return false;
    // }

    // if (!projectLanguage || projectLanguage?.length === 0) {
    //   alert(
    //     "Please enter the Project Language"
    //   );
    //   return false;
    // }


    if (!englishDubbed || englishDubbed?.length === 0) {
      alert(
        "Please state if English Subtitles are Present"
      );
      return false;
    }
    if (!englishSubtitled || englishSubtitled?.length === 0) {
      alert(
        "Please state if the film is English dubbed"
      );
      return false;
    }

    if (
      RIGHTS.checked === false ||
      originRegion === "" ||
      originRegion === "Select Origin From the Dropdown"
    ) {
      let msg =
        "Ensure Rights Agreement is Checked \nEnsure You Select Project Region of Origin";
      alert(msg);
      return false;
    }

    if (!originCountry || originCountry?.length === 0) {
      alert(
        "Please select the films origin"
      );
      return false;
    }

    if (!completionYear || completionYear?.length === 0) {
      alert(
        "Please select the films completion year!"
      );
      return false;
    }
    if (!filmGenre || filmGenre?.length === 0) {
      alert(
        "Please select the films genre!"
      );
      return false;
    }
    if (filmScreening !== 1 && filmScreening !== 0) {
      alert(
        "Please select 'Yes/No' if your project has been screened at other festivals"
      );
      return false;
    }
    if (filmScreening === 1 && festivalScreenings?.length === 0) {
      alert(
        "Please enter the Festival Screenings or select 'No' for screened at other festivals!"
      );
      return false;
    }
    if (!audienceType || audienceType?.length === 0) {
      alert(
        "Please select the Screening Audience!"
      );
      return false;
    }
    if (file === "" && fileLink.length === 0) {
      let msg = "Please select your video file or enter video url.";
      alert(msg);
      return false;
    }
    if (!poster || !banner) {
      alert(
        "Please ensure that you choose both a poster and a banner for your film."
      );
      return false;
    }


    if (firstName.length === 0 || lastName.length === 0 || email.length === 0) {
      alert(
        "Please enter author name & email!"
      );
      return false;
    }

    if (!message || message.length === 0) {
      alert(
        "Please include a message from the filmmaker!"
      );
      return false;
    }

    if (list?.markets.length === 0) {
      alert(
        "Please select Markets for streaming!"
      );
      return false;
    }

    // const formData = new FormData();
    // formData.append('image', img);
    // postToLaravel("api/upload", formData);
    showModal();
    setUuid(uuidv4());
    // store the states in the form data
    const formData = new FormData();
    // const filmakerFormData = new FormData();
    formData.append("uuid", uuid);
    formData.append("first_name", firstName);
    formData.append("last_name", lastName);
    formData.append("email", email);
    formData.append("message", message);
    formData.append("instagram", instagram);
    formData.append("facebook", facebook);
    formData.append("twitter", twitter);
    formData.append("promo_code", promoCode);
    // formData.append("id", )
    // formData.append("uuid", )
    formData.append("title", projectTitle);
    formData.append("synopsis", projectSynopsis);
    formData.append("film_type", projectType);
    formData.append("description", projectDescription);
    formData.append("runtime", projectLength);
    formData.append("student_film", studentFilm);
    formData.append("genre", filmGenre);
    formData.append("film_school", filmSchool);
    formData.append("language", projectLanguage);
    formData.append("english_subbed", englishSubtitled);
    formData.append("region", originRegion);
    formData.append("country", originCountry);
    formData.append("completion_year", completionYear);
    formData.append("imdb", imdb);
    formData.append("poster", poster);
    formData.append("banner", banner);
    formData.append("english_dubbed", englishDubbed);
    formData.append("distributor_connect", "jhbjhbj");
    formData.append("rights_agreement", rightsAgreement);
    formData.append("distribution_list", JSON.stringify(list));
    formData.append("festival_screening", filmScreening);
    formData.append("festival_list", festivalScreenings);
    formData.append("film_file", file);
    formData.append("external_link", fileLink);
    formData.append("audience_type", audienceType);
    formData.append("rights_agreement", rightsAgreement);

    try {
      // uploadFile(selectedFile);

      // make axios post request
      // console.log('FormData Request', formData)


      const res = postToLaravel("api/filmaker", formData)
      // .catch( error=>{
      //   setError(`File maker Error ${error?.message}`);
      // } );
      console.log('Res', (await res));
      if ((await res).status === 200)
        setError("Invalid Promo Code! Please re-enter promo code.");

      if ((await res).status === 201) {
        console.log('isPaid : ', (await res).data?.isValidCode)
        setIsPaid((await res).data?.isValidCode); // determine if the user has to Go to Payment Page or not

        const filmResponse = postToLaravel("api/film", formData);

        console.log(`Film Logging `, filmResponse)

        if ((await filmResponse)?.status === 200) {
          const imageResponse = postToLaravel("api/upload", formData)


          // console.log(`Image Logging `, imageResponse)

          // console.log(imageResponse);
          if ((await imageResponse)?.status === 200) {
            setSuccess("Form Submitted Successfully");
            if (file && file !== '')
              uploadFile(selectedFile);

          } else {

            setError("Failed to Submit : Images could not be uploaded!");
            return
          }
        } else { // esle for Film Response
          let msg = "Failed to Submit : "
          if ((await filmResponse)?.response?.data?.message === "Call to a member function films() on null")
            msg += 'Film could not be created because Film Maker was not found!'
          else
            msg += 'Film could not be created!'
          setError(msg || `\nTry again later`);
          return
        }
      }

      //End of try request
    } catch (error) {
      // setError("Failed to Submit, Try again later");
      if (error?.response)
        setError(await Promise.resolve(getDisplayServerError(error?.response?.data ?? '')))
      else if (error?.message?.includes('undefined'))
        setError('Please resubmit using a unique email')
      else
        // setError(`Failed to Submit: ${error?.response}`);
        setError(`Failed to Submit: Please Refresh or Try Again`);
      console.log(error, error?.message, error?.response);
    }
  };

  function getEmail(event) {
    setEmail(event.target.value);
  }

  function getFirstName(event) {
    setFirstName(event.target.value);
  }

  function getLastName(event) {
    setLastName(event.target.value);
  }

  function getMessage(event) {
    const message = event.target.value;
    if (message.length >= 360) {
      setMessage(message.substring(0, 360));
      alert("Character limit (360) reached for message from the film maker");
    } else {
      setMessage(message);
    }
  }

  function getTwitter(event) {
    setTwitter(event.target.value);
  }

  function getInstagram(event) {
    setInstagram(event.target.value);
  }

  function getFacebook(event) {
    setFacebook(event.target.value);
  }

  function getPromoCode(event) {
    setPromoCode(event.target.value);
  }

  const getProjectTitle = (event) => {
    setProjectTitle(event.target.value);
  };

  const getProjectType = (event) => {
    setProjectType(event.target.value);
  };

  const getProjectSynopsis = (event) => {
    const synopsis = event.target.value;
    if (synopsis.length >= 180) {
      setProjectSynopsis(synopsis.substring(0, 180));
      alert("Character limit (180) reached for synopsis");
    } else {
      setProjectSynopsis(synopsis);
    }
  };

  const getProjectDescription = (event) => {
    setProjectDescription(event.target.value);
  };

  const getProjectLength = (event) => {
    // console.log( 'Runtime', event.target.value.length)
    setProjectLength(event.target.value);
  };

  const getStudentFilm = () => {
    if (studentFilm === 1) {
      setStudentFilm(0);
    } else {
      setStudentFilm(1);
    }
  };

  const getFilmSchool = (event) => {
    setFilmSchool(event.target.value);
  };

  const getProjectLanguage = (event) => {
    setProjectLanguage(event.target.value);
  };

  const getEnglishSubtitled = (event) => {
    setEnglishSubtitled(event.target.value);
  };

  const getEnglishDubbed = (event) => {
    setEnglishDubbed(event.target.value);
  };

  const getOriginRegion = (event) => {
    setOriginRegion(event.target.value);
  };

  const getOriginCountry = (event) => {
    setOriginCountry(event.target.value);
  };

  const getCompletionYear = (event) => {
    setCompletionYear(event.target.value);
  };

  const getFilmGenre = (event) => {
    setFilmGenre(event.target.value);
  };

  const getFilmScreening = (value) => {
    // console.log(value);
    setFilmScreening(value);

  };

  const createFestivalScreeningObj = (text) => {
    if (text?.length === 0 || !text)
      return {}

    const arrInfo = text.split(',')
    if (arrInfo?.length === 0 || !arrInfo)
      return {}
    const obj = {
      'Festival Name': arrInfo[0],
      'Festival Year': arrInfo[1],
      'Festival Award': arrInfo[2]
    }
    return obj;
  }

  const getFestivalScreenings = (event) => {
    // console.log(typeof event.target.value);
    const screenings = event.target.value;
    const arrOfObjects = [];
    // if (screenings.includes(";")) {
    //   const splitScreenings = screenings.split(";");
    //   let splitByCOmma = [];
    // splitScreenings.forEach((s) => {
    //   splitByCOmma.push(s.split(","));
    // });
    // splitByCOmma.forEach((el) => {
    //   let target = {};
    //   el.forEach((property) => {
    //     let key = property.split(":")[0];
    //     let value = property.split(":")[1];
    //     target[key] = value;
    //   });
    //   arrOfObjects.push(target);
    //   console.log(arrOfObjects);
    //   setFestivalScreenings(JSON.stringify({ FESTIVALS: arrOfObjects }));
    // });
    // } else {
    //   const myArray = event.target.value.split(",");
    //   let target = {};
    //   myArray.forEach((property) => {
    //     let key = property.split(":")[0];
    //     let value = property.split(":")[1];
    //     target[key] = value;
    //   });
    //   console.log(target);
    //   setFestivalScreenings(JSON.stringify(target));
    // }
    if (screenings?.length === 0 || !screenings)
      return
    const splitScreenings = screenings.split(";");
    splitScreenings.forEach(item => {
      const festivalInfoObj = createFestivalScreeningObj(item)
      if (Object.keys(festivalInfoObj)?.length > 0)
        arrOfObjects.push(festivalInfoObj)
    })

    console.log(arrOfObjects);
    setFestivalScreenings(JSON.stringify({ FESTIVALS: arrOfObjects }));

  };

  const getAudienceType = (event) => {
    setAudienceType(event.target.value);
  };

  // const getSubmissionOption = (event) => {
  //   setSubmissionOption(event.target.value);
  // };

  const getRightsAgreement = (event) => {
    setRightsAgreement(event.target.value);
  };

  const getFileLink = (event) => {
    console.log('File Link', event.target.value)
    setFileLink(event.target.value);
  };

  const getBanner = (event) => {
    let fileList = event.target.files;
    setBanner(fileList[0]);
  };

  const getPoster = (event) => {
    setPoster(event.target.files[0]);
  };

  const getFile = (fileName) => {
    setFile(fileName);
    console.log(fileName);
  };

  const getIMDB = (event) => {
    setIMDB(event.target.value);
  };

  const getList = (selected) => {
    let d_list = [];
    selected.forEach((selection) => {
      d_list.push(selection.value);
    });
    setList({ markets: d_list });
    console.log(list);
  };

  // function handleChange(e) {
  //   setImage(e.target.files[0]);
  // }

  // return  post(url, formData)
  //         .then(response => console.log(response))

  document.addEventListener("keyup", function(event) {
    if (event.code  === 'Enter') {
        // alert('Enter is pressed!');
        handleSubmit(event);
    }
});

  const Navbar = useMemo( () => <AppNavBar /> , []);
  return (
    <>
      <div>
        {Navbar}
      </div>

      <header className="header">
        <h1 className="header-heading">Festiv - Film Submission Form</h1>
        <h4>
          Welcome! We are excited to showcase your amazing content on Festiv! On
          Festiv you can:
        </h4>
        <div>
          <ul>
            <li>
              Connect with Distributors, Filmakers, and Content Creators from
              around the world!
            </li>
            <li>Enjoy Unlimited Screenings for a Global Audience!</li>
            <li>Get Marketing and Promotional Services for your Content!</li>
            <li>Find out about upcoming virtual and live industry events</li>
            <li>New Films are Announced and Awarded each Month!</li>
            <li>Submission fees are discounted for a limited time! <br />{"("}Feature Films <s>$225</s>  $125, Short Film or TV Pilot <s>$129</s>  $75, Student Film <s>$85</s>  $47.50{")"} <br /> All types Free with Valid Promo Code!</li>
          </ul>
        </div>
        <p>
          By submitting this form, you affirm that you have read and agreed to
          Festiv's Film Submission Guidelines:{" "}
          <a href="/film-submission-guidelines.html">
            Festiv's Film Submission Guidelines
          </a>
        </p>
      </header>
      <form encType="multipart/form-data" className="form-style">
        <div>
          <h2 className="film-information-header">Film Information</h2>
        </div>
        <strong>
          <em style={{ color: "red" }}>The (*) marks required fields.</em>
        </strong>

        <FormInput
          type="text"
          name="projectTitle"
          id="projectTitle"
          // value={projectTitle}
          placeholder="Enter Film Title"
          label="Film Title*"
          required={true}
          getInput={getProjectTitle}
        />
        <FormInput
          type="text"
          name="projectSynopsis"
          id="projectSynopsis"
          label="Film Synopsis* (maximum 180 characters)"
          placeholder="Enter Film Synopsis"
          required={true}
          getInput={getProjectSynopsis}
        />
        <div className="form-input">
          <label className="form-input-label">Film type*</label>
          <div className="project-type input-styling">
            <input
              type="radio"
              value="Live Action (Scripted)"
              checked={projectType === "Live Action (Scripted)"}
              onChange={getProjectType}
            />
            {" Live Action - Scripted"}
            <br />
            <input
              type="radio"
              value="Reality (Non-Scripted)"
              checked={projectType === "Reality (Non-Scripted)"}
              onChange={getProjectType}
            />
            {" Reality Non-Scripted"}
            <br />
            <input
              type="radio"
              value="Animation"
              checked={projectType === "Animation"}
              onChange={getProjectType}
            />
            {" Animation"}
          </div>
        </div>
        <div className="form-input">
          <label className="form-input-label">Film Description*</label>
          <div className="project-description input-styling">
            {/* <input
              type="radio"
              value="Pocket Film Festival Entry"
              checked={projectDescription === "Pocket Film Festival Entry"}
              onChange={getProjectDescription}
            />
            {" Pocket Film Festival Entry"}
            <br /> */}
            <input
              type="radio"
              value="Feature Film"
              checked={projectDescription === "Feature Film"}
              onChange={getProjectDescription}
            />
            {" Feature Film"}
            <br />
            <input
              type="radio"
              value="Short Film (Under 50min)"
              checked={projectDescription === "Short Film (Under 50min)"}
              onChange={getProjectDescription}
            />
            {" Short Film (Under 50min)"}
            <br />
            <input
              type="radio"
              value="TV Pilot - Short Form (22-25min)"
              checked={
                projectDescription === "TV Pilot - Short Form (22-25min)"
              }
              onChange={getProjectDescription}
            />
            {" TV Pilot - Short Form (22-25min)"}
            <br />
            <input
              type="radio"
              value="TV Pilot - Long Form (44-50min)"
              checked={projectDescription === "TV Pilot - Long Form (44-50min)"}
              onChange={getProjectDescription}
            />
            {" TV Pilot - Long Form (44-50min)"}
          </div>
        </div>
        <FormInput
          type="text"
          name="projectLength"
          id="projectLength"
          placeholder="Enter Film Length in Minutes"
          label="Film Runtime*"
          required={true}
          getInput={getProjectLength}
        />
        <div className="form-input">
          <label className="form-input-label">
            Is your film a student film?*
          </label>
          <div className="student-film input-styling">
            <input
              type="radio"
              value={1}
              checked={studentFilm === 1}
              onChange={getStudentFilm}
            />
            {" Yes"}
            <br />
            <input
              type="radio"
              value={0}
              checked={studentFilm === 0}
              onChange={getStudentFilm}
            />
            {" No"}
          </div>
        </div>
        <br />
        <FormInput
          type="text"
          name="filmSchool"
          id="filmSchool"
          // value={filmSchool}
          placeholder="Enter Film School (optional)"
          label="Film School (optional)"
          getInput={getFilmSchool}
        />
        <FormInput
          type="text"
          name="projectLanguage"
          id="projectLanguage"
          placeholder="Enter Project Language"
          label="Project Language"
          getInput={getProjectLanguage}
        />
        <div className="form-input">
          <label className="form-input-label">
            English Subtitles Present?*
          </label>
          <div className="english-subtitled input-styling">
            <input
              type="radio"
              value="Yes"
              checked={englishSubtitled === "Yes"}
              onChange={getEnglishSubtitled}
            />
            {" Yes"}
            <br />
            <input
              type="radio"
              value="No"
              checked={englishSubtitled === "No"}
              onChange={getEnglishSubtitled}
            />
            {" No"}
          </div>
        </div>
        <div className="form-input">
          <label className="form-input-label">
            Is the film english dubbed?*
          </label>
          <div className="english-dubbed input-styling">
            <input
              type="radio"
              value="Yes"
              checked={englishDubbed === "Yes"}
              onChange={getEnglishDubbed}
            />
            {" Yes"}
            <br />
            <input
              type="radio"
              value="No"
              checked={englishDubbed === "No"}
              onChange={getEnglishDubbed}
            />
            {" No"}
          </div>
        </div>
        <div className="note-wrapper form-input">
          <label className="note-label form-input-label">
            <em>Please Note:</em>
          </label>
          <p className="note input-styling">
            If not English Language, or English Subtitled, or English Dubbed we
            will contact you shortly after we&apos;ve received your submission
            with information on services for your project to ensure your project
            qualifies for consideration.
          </p>
        </div>

        <div className="select-container form-input">
          <label className="form-input-label">Project Region of Origin*</label>
          <select
            value={originRegion}
            onChange={getOriginRegion}
            className="input-styling"
          >
            {regions.map((region, index) => (
              <option key={index} value={region}>
                {region}
              </option>
            ))}
          </select>
        </div>
        <FormInput
          type="text"
          name="originCountry"
          id="originCountry"
          placeholder="Enter Project Country of Origin"
          label="Film Country*"
          required={true}
          getInput={getOriginCountry}
        />
        <FormInput
          type="text"
          name="completionYear"
          id="completionYear"
          placeholder="Enter Project Year of Completion"
          label="Completion Year*"
          required={true}
          getInput={getCompletionYear}
        />
        <div className="select-container form-input">
          <label className="form-input-label">Film Genre*</label>
          <div className="input-styling">
            {genres.map((genre, index) => (
              <div>
                <input
                  key={index}
                  id={genre}
                  type="radio"
                  value={genre}
                  checked={filmGenre === genre}
                  onChange={getFilmGenre}
                />
                <span>{" " + genre}</span>
              </div>
            ))}
          </div>
        </div>
        <div className="form-input">
          <label className="form-input-label">
            Has your project screened at other festivals?
          </label>
          <div className="film-screening input-styling">
            <input
              type="radio"
              value={1}
              checked={filmScreening === 1}
              // onChange={getFilmScreening}
              onChange={e => { getFilmScreening(e.target.checked ? 1 : 0) }}
            />
            {" Yes"}
            <br />
            <input
              type="radio"
              value={0}
              checked={filmScreening === 0}
              // onChange={getFilmScreening}
              onChange={e => getFilmScreening(e.target.checked ? 0 : 1)}
            />
            {" No"}
          </div>
        </div>
        <div className="note-wrapper form-input">
          <label className="note-label form-input-label">
            <em>Please Note:</em>
          </label>
          <p className="note input-styling">
            The format to enter festival screenings is "Festival Name, Festival Year, Festival Award":<br />
            <b>eg. "Tribeca Film Festival, 2021, Best Short Film"</b> <br />
            If you wish to enter more than one screening, separate each with a semicolon (;) <br />
            <b>eg. "Tribeca Film Festival, 2021, Best Short Film; <br />
              Toronto International Film Festival, 2022, Best Director"</b>
          </p>
        </div>
        <FormInput
          type="text"
          name="festivalScreenings"
          id="festivalScreenings"
          label="Festival Screenings"
          placeholder="e.g Festival Name, Festival Year, Festival Award "
          required={filmScreening === 1 ? true : false}
          getInput={getFestivalScreenings}
        />
        {/* <div className="form-input">
          <label className="form-input-label">Submission Option</label>
          <div className="submission-type input-styling">
            <input
              type="radio"
              value={manualOption}
              checked={submissionOption === manualOption}
              onChange={getSubmissionOption}
            />{" "}
            <div className="options">Direct Upload</div>
            <br />
            <input
              type="radio"
              value={linkOption}
              checked={submissionOption === linkOption}
              onChange={getSubmissionOption}
            />{" "}
            <div className="options">Link to Video</div>
          </div>
        </div> */}
        <div className="form-input">
          <label className="form-input-label">Screening Audience*</label>
          <div className="audience-type input-styling">
            <input
              type="radio"
              value="Exclusive (Distributors and Filmmakers Only)"
              checked={
                audienceType === "Exclusive (Distributors and Filmmakers Only)"
              }
              onChange={getAudienceType}
            />
            {" Exclusive (Distributors and Filmmakers Only)"}
            <br />
            <input
              type="radio"
              value="Non-Exclusive (Distributors, Filmmakers, and General Audience)"
              checked={
                audienceType ===
                "Non-Exclusive (Distributors, Filmmakers, and General Audience)"
              }
              onChange={getAudienceType}
            />
            {" Non-Exclusive (Distributors, Filmmakers, and General Audience)"}
          </div>
        </div>

        <br />
        <br />
        <h2 className="film-information-header">Either enter External url or Upload File </h2>

        <FormInput
          type="text"
          name="fileLink"
          id="fileLink"
          placeholder="Enter File external url"
          label="External Video Link(recommended for video files larger than 10GB)"
          required={false}
          getInput={getFileLink}
        />
        <UploadVideo
          resetFileInput={resetFileInput}
          inputRef={inputRef}
          handleFileInput={handleFileInput}
          progress={progress}
        />
        <div className="form-input">
          <label htmlFor="poster" className="form-input-label">
            Film Poster*
          </label>
          <div className="input-styling">
            <p>Your upload must be a jpeg, png, or svg file.</p>
            <input
              type="file"
              id="poster"
              name="poster"
              accept="image/*"
              required
              onChange={getPoster}
            />
          </div>
        </div>
        <div className="form-input">
          <label htmlFor="banner" className="form-input-label">
            Film Banner*
          </label>
          <div className="input-styling">
            <p>Your upload must be a jpeg, png, or svg file.</p>
            <input
              type="file"
              id="banner"
              name="banner"
              accept="image/*"
              required
              onChange={getBanner}
            />
          </div>
        </div>
        {/* <div className="note-wrapper form-input">
          <label className="note-label form-input-label">
            Film Link (If Not Direct Upload)*
          </label>
          <div className="input-styling">
            <p className="note">
            Please provide a link to your project which is accessible on an
            online video sharing or storage platform (e.g., YouTube, Vimeo,
              Google Drive, Dropbox, etc.)
              </p>
              <p className="note">
              Ensure that the file is accessible to the following email address:
              submissions@festivmedia.com
              </p>
          </div>
        </div> */}

        {/* <FormInput
          type="text"
          name="fileLink"
          id="fileLink"
          // value={email}
          disabled = {file === "" ? false : true}
          placeholder="Enter URL"
          required={submissionOption === linkOption ? true : false}
          getInput={getFileLink}
        />
        */}
        <div className="form-input">
          <label className="form-input-label">
            Please confirm by clicking the checkbox
          </label>
          <div className="input-styling rights-agreement">
            <input
              type="checkbox"
              id="rights-agreement"
              name="rightsAgreement"
              value={rights}
              checked={rightsAgreement === rights}
              onChange={getRightsAgreement}
              required
            />
            {" " + rights}
          </div>
        </div>

        <br />
        <br />
        <h2 className="film-information-header">Filmmaker Information</h2>
        <FormInput
          type="text"
          name="firstName"
          id="firstName"
          placeholder="Enter First Name"
          label="First Name*"
          required={true}
          getInput={getFirstName}
        />
        <FormInput
          type="text"
          name="lastName"
          id="lastName"
          placeholder="Enter Last Name"
          label="Last Name*"
          required={true}
          getInput={getLastName}
        />
        <FormInput
          type="email"
          name="email"
          id="email"
          placeholder="Enter Email"
          label="E-mail Address*"
          required={true}
          getInput={getEmail}
        />
        <FormInput
          type="text"
          name="message"
          id="message"
          placeholder="Message from the filmmaker"
          required={true}
          getInput={getMessage}
          label="Message from the filmmaker*"
        />
        <FormInput
          type="text"
          name="imdb"
          id="imdb"
          placeholder="Project URL or IMDb URL"
          label="Project URL (IMDb if applicable)"
          required={true}
          getInput={getIMDB}
        />
        <Dropdown getList={getList} />
        <FormInput
          type="text"
          name="facebook"
          id="facebook"
          placeholder="Enter Facebook Handle"
          label="Facebook Handle"
          required={true}
          getInput={getFacebook}
        />
        <FormInput
          type="text"
          name="twitter"
          id="twitter"
          placeholder="Enter Twitter Handle"
          label="Twitter Handle"
          required={true}
          getInput={getTwitter}
        />
        <FormInput
          type="text"
          name="instagram"
          id="instagram"
          placeholder="Enter Instagram Handle"
          label="Instagram Handle"
          required={true}
          getInput={getInstagram}
        />
        <FormInput
          type="text"
          name="promoCode"
          id="promoCode"
          placeholder="Enter Promo Code"
          label="Promo Code"
          getInput={getPromoCode}
        />
        <Modal
          show={show}
          handleClose={hideModal}
          formSuccess={success}
          formError={error}
          progress={progress}
          isPaid={isPaid}
          hasFileUpload={file && file !== ''}
        />
        <div className="button-wrapper">
          <button onClick={handleSubmit} className="form-button">
            Submit Film
          </button>
        </div>
      </form>
      <Footer />
    </>
  );
}
