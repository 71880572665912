import React from "react";
import Carousel from "react-bootstrap/Carousel";
import "bootstrap/dist/css/bootstrap.min.css";
import FeaturedFilmEntry from "./featured-film-entry";
import "../../src/components/form.css";

function FeaturedFilmEntries({ filmsData }) {
  return (
    <div className="container-fluid featured-container">
      <div className="row row-custom-style">
        <div className="">
          <Carousel variant="dark" fade>
            {filmsData.slice(0, 5).map((film, index) => (
              <Carousel.Item key={index}>
                <FeaturedFilmEntry
                  id={film.id}
                  title={film.title}
                  year={film.completion_year.substring(0, 4)}
                  synopsis={film.synopsis}
                  runtime={film.runtime}
                  language={film.language}
                  country={film.country}
                  poster={film.poster}
                  className="d-block w-100"
                />
              </Carousel.Item>
            ))}
          </Carousel>
        </div>
      </div>
    </div>
  );
}

export default FeaturedFilmEntries;
