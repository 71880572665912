import { useState } from "react";


 function AccountModal( { message} ){

  const [showModal , setShowModal] = useState ( true );

  return (
    <div className={ showModal ? "modal display-block" : "modal display-none"}>
      <section className="modal-main" >
        
        <h6 className={'text-center'}>
          {message}
          
        </h6>
        
        
        <button
          className={ "display-block" }
          onClick={()=>setShowModal( false) }
        >
          Close
        </button>
      </section>
    </div>
  );
} 


export default AccountModal;