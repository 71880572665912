import React, { useEffect } from "react";
import SignedOutNavbar from "../components/navbar/signed-out-navbar";
import Footer from "../components/footer";

export default function AccountAgreement() {
  return (
    <div>
      <SignedOutNavbar />
      <section className="section-6">
        <article className="extra-documents-container w-container">
          <div className="extra-documents-title-wrapper">
            <h1 className="extra-documents-page-title">
              FESTIV WEBSITE SUBSCRIBER AGREEMENT
            </h1>
            <p className="extra-documents-page-subtitle">
              LAST UPDATED OCTOBER 11, 2022
            </p>
          </div>
          <p className="extra-documents-text">
            Next Wave Media Partners, LLC., located at 703 Pier Ave., Ste. B421,
            Hermosa Beach, CA 90254, (“we”, “us”, “our”) creator and owner of
            web domains and media platforms festiv.media and festivmedia.com
            (collectively "Festiv") welcome you to the Festiv Service. The
            "Festiv Service" includes the Festiv Media website, application and
            associated content and services.
          </p>

          <br />
          <p className="extra-documents-text">
            PLEASE READ THIS SUBSCRIBER AGREEMENT ("SUBSCRIBER AGREEMENT", OR
            "AGREEMENT") CAREFULLY BEFORE USING THE FESTIV SERVICE.
          </p>
          <br />
          <p className="extra-documents-text">
            THIS AGREEMENT WILL GOVERN YOUR USE OF THE FESTIV SERVICES.
          </p>
          <br />
          <div className="extra-documents-text">
            <p>
              You agree to the Subscriber Agreement by clicking "Agree &
              Continue" or other industry standard mechanism during the Festiv
              registration process and ratify your agreement when you use any
              aspect of the Festiv Service. If you do not agree to the
              Subscriber Agreement, you may not use the Festiv Service. We may
              amend this Agreement. Any such amendment will be effective thirty
              (30) days following either our dispatch of a notice to you or our
              posting of the amendment on the Festiv Service. If you do not
              agree to any change to the Subscriber Agreement, you must
              discontinue using the Festiv Service. Our customer service
              representatives are not authorized to modify any provision of the
              Subscriber Agreement, either verbally or in writing.
            </p>
          </div>
          <br />
          <div className="extra-documents-text">
            <p>
              ANY DISPUTE BETWEEN YOU AND US, EXCEPT FOR SMALL CLAIMS, IS
              SUBJECT TO A CLASS ACTION WAIVER AND MUST BE RESOLVED BY
              INDIVIDUAL BINDING ARBITRATION. PLEASE READ THE ARBITRATION
              PROVISION IN THIS AGREEMENT AS IT AFFECTS YOUR RIGHTS UNDER THIS
              CONTRACT.
            </p>
          </div>
          <br />

          <div className="extra-documents-text">
            <h2>List of Sections</h2>
            <ol>
              <li>User Eligibility and Registration</li>
              <li>Subscription Terms</li>
              <li>Copyright License Grant and Restrictions</li>
              <li>Usage Terms</li>
              <li>Use and Sharing of Certain Information</li>
              <li>Suspension and Termination</li>
              <li>Binding Arbitration and Class Action Waiver</li>
              <li>Additional Provisions</li>
            </ol>
          </div>

          <br />
          <ol>
            {/** 1 */}
            <li>
              <h2 className="extra-documents-small-header">
                USER ELIGIBILITY AND REGISTRATION
              </h2>
              <ol type="a" className="extra-documents-text">
                <li>
                  Eligibility and Age Limitations. Only residents of the
                  countries where we offer the Festiv Service (collectively, the
                  "Territory") are eligible to register for a Festiv Account.
                  This Subscriber Agreement governs for residents of the United
                  States and all U.S. territories. You must be 18 years of age,
                  or the age of majority in your state or territory of
                  residence, to purchase the Festiv Service. The Festiv
                  “Audience” Account is provided to individuals for their
                  personal, noncommercial use only. The Festiv “Filmmaker” and
                  “Distributor” Accounts are intended for use by Individuals,
                  Companies, Associations, and other groups for their
                  noncommercial use. Individuals under the age of 13 are not
                  eligible for a Festiv Account and are not permitted to provide
                  personal information to Festiv.
                </li>
                <br />
                <li>
                  Registration. Only individuals that have registered for a
                  Festiv account, provide certain information (e.g., a valid
                  email address), and agree to the Subscriber Agreement are
                  eligible to use the Festiv Service. You are solely responsible
                  for maintaining the confidentiality and security of your
                  username and password and for all activities that occur on or
                  through your Festiv accounts. However, if you allow others to
                  access your Festiv Account, this Agreement, as well any
                  specific consents you may have provided, also applies to their
                  access, use, and disclosure of information. You agree to
                  immediately notify Festiv of any unauthorized access to your
                  Festiv Account. Festiv will not be responsible for any losses
                  arising from the unauthorized use of your Festiv Accounts.
                </li>
                <br />
                <li>
                  Notices. Any notices Festiv delivers to you may be made as
                  follows: (i) by email to the last email address provided by
                  you or (ii) by posting a notice on the Festiv Service. You
                  agree to provide and maintain accurate, current, and complete
                  information, including your contact information for notices
                  and other communications from us. You agree not to impersonate
                  or misrepresent your affiliation with any person or entity,
                  including using another person's username, password or other
                  account information, or another person's name or likeness, or
                  provide false details for a parent or guardian. You agree that
                  we may take steps to verify the accuracy of information you
                  provide.
                </li>
              </ol>
            </li>
            {/** 2 */}
            <li>
              <h2 className="extra-documents-small-header">
                SUBSCRIPTION TERMS
              </h2>
              <ol type="a" className="extra-documents-text">
                <li>
                  Subscription and Auto-Renewal. Your subscription to the Festiv
                  Service includes enrollment into an ongoing/recurring payment
                  plan. Your subscription(s) will automatically renew at the end
                  of the disclosed billing period, unless cancelled in
                  accordance with the instructions for cancellation below.
                  Payment will be charged to your chosen payment method at
                  confirmation of purchase and at the start of every new billing
                  period, unless cancelled. Your “billing period” is the
                  interval of time between each recurring billing date and
                  corresponds to the term of your subscription. To see your next
                  recurring billing date, log in to your account and view your
                  account details. You acknowledge that the timing of when you
                  are billed may vary, including if your subscription began on a
                  day not contained in a given month (e.g. if you have a monthly
                  subscription and became a paying subscriber on January 31,
                  your payment method would be billed next on February 28). We
                  reserve the right to change our pricing. In the event of a
                  price change, we will attempt to notify you thirty (30) days
                  in advance of the change by sending an email to the email
                  address you have registered for your account. If you do not
                  wish to accept a price change, you may cancel your
                  subscription in accordance with the instructions included in
                  that email and below. If you do not cancel your subscription
                  after the price change takes effect and prior to the start of
                  your new subscription period, your subscription will be
                  renewed at the price in effect at the time of the renewal,
                  without any additional action by you, and you authorize us to
                  charge your payment method for these amounts. We will not be
                  able to notify you of any changes in applicable taxes. You are
                  responsible for all third-party Internet access charges and
                  taxes in connection with your use of the Festiv Service.
                  Please check with your Internet provider for information on
                  possible Internet data usage charges.
                </li>
                <br />
                <li>
                  Free Trials. Your Festiv Service subscription(s) may begin
                  with a free trial. Availability of a free trial is not
                  guaranteed and, if one is available, is only available on the
                  specified terms of the free trial to those who have not
                  previously used one for the Festiv Service. Your first payment
                  will be charged to your chosen payment method immediately
                  following the free trial, unless cancelled in accordance with
                  the instructions for cancellation below. You can cancel your
                  subscription at any time before the end of your free trial. We
                  provide notice of the terms of the free trial at the time you
                  register, and you will not receive a separate notice that your
                  free trial is about to end or has ended, or that your paid
                  subscription has begun.
                </li>
                <br />
                <li>
                  Cancellation and Refund Policy. You can cancel your
                  subscription by clicking the "Cancel Subscription" button in
                  the user menu at any time before the end of your current
                  billing period or free trial. Cancellation will take effect
                  immediately and you will no longer be able to view the content
                  on Festiv. We do not refund or provide credit for partially
                  used billing periods. If you subscribed via a third party
                  (e.g. an app store such as iTunes, Google Play, Amazon Store,
                  or Roku), you can cancel your subscription by contacting the
                  third party.
                </li>
                <br />
                <li>
                  Payment Details. Your detailed payment information, such as
                  credit card number and expiry date will be kept on file with
                  our 3rd party payment provider, Stripe. We will not share your
                  payment information. You are responsible for keeping your
                  payment details up-to-date by changing the details in your
                  account settings. Where your details change or are due to
                  expire, we may obtain or receive from your payment provider
                  updated payment details including your card number, expiry
                  date and CVV (or equivalent). This enables us to continue to
                  provide you access to the Festiv Service. You authorize us to
                  continue to charge your card using the updated information. If
                  a payment is not successfully authorized due to expiration,
                  insufficient funds, or otherwise, we may suspend or terminate
                  your subscription. You also agree that we may charge your
                  payment method on file if you decide to restart your Festiv
                  Service subscription.
                </li>
                <br />
                <li>
                  Subscriptions Obtained Through Third Parties. If you obtain a
                  Festiv Service subscription via a third party (e.g., an app
                  store), that subscription is also subject to the third party's
                  terms, and the provisions in this Subscriber Agreement
                  concerning subscription purchase, billing,
                  cancellation/refunds, and payment do not apply to that
                  subscription to the extent this Subscriber Agreement conflicts
                  with the applicable third party's terms. For subscriptions
                  obtained via a third party, your billing relationship will be
                  directly with the applicable third party. Any fees charged for
                  your Festiv Service will be billed by the applicable third
                  party using the payment information you have provided to such
                  third party. To cancel a Festiv Service subscription obtained
                  via a third party, please follow the cancellation instructions
                  set out by the applicable third party. You can email us at
                  info@festivmedia.com with questions on how to cancel a Festiv
                  Service subscription obtained via a third party.
                </li>
                <br />
                <li>
                  Bundled Subscription Options. We may offer a Festiv Service
                  subscription bundled with other subscription services,
                  including subscriptions to third-party products and services,
                  e.g., a wireless plan. Notice of the terms of the bundled
                  subscription options will be provided to you at the time you
                  register. Third-party subscriptions, products, and services
                  are governed by terms of use issued by those third parties.
                  <br />
                  <br />
                  If you are a Festiv Bundle subscriber, by agreeing to this
                  Subscriber Agreement, you also agree to the Bundle Offer
                  Terms, which are incorporated by reference. You agree that to
                  the extent such Bundle Offer Terms conflict with the terms
                  contained herein, those terms shall govern.
                </li>
                <br />
                <li>
                  By subscribing to The Festiv Bundle when offered, you agree
                  that certain account information (e.g., your e-mail address)
                  will be provided to each Bundled Service for purposes of
                  assessing your eligibility for The Festiv Bundle, activating,
                  administering, improving your experience with The Festiv
                  Bundle and each Bundled Service, and communicating with you
                  about The Festiv Bundle and each Bundled Service. Any such
                  account information received by the Bundled Services will be
                  subject to each Bundled Service provider's respective Privacy
                  Policy.
                </li>
                <br />
                <li>
                  Premier Access. Festiv may offer Premier Access to certain
                  content from time to time. Each Premier Access title requires
                  a separate payment of an additional fee (i.e., in addition to
                  the subscription fee for the Festiv Service) specific to the
                  applicable Premier Access title. You will be charged for each
                  Premier Access title at the time of purchase to your chosen
                  payment method. “Premier Access” means the basis upon which
                  Festiv makes available access to certain content, in Festiv’s
                  sole and absolute discretion, to subscribers of the Festiv
                  Service prior to such content’s availability to all
                  subscribers of the Festiv Service at no additional cost.
                </li>
                <br />
                <li>
                  Pay Per View Events. Festiv may also offer one-time pay per
                  view events. You will be charged for pay per view events at
                  the time of purchase to your chosen payment method.
                </li>
              </ol>
            </li>
            {/** 3 */}
            <li>
              <h2 className="extra-documents-small-header">
                COPYRIGHT LICENSE GRANT AND RESTRICTIONS
              </h2>
              <ol type="a" className="extra-documents-text">
                <li>
                  License. Within the Territory and subject to the terms and
                  conditions in this Subscriber Agreement, Festiv grants you a
                  limited, personal use, non-transferable, non-assignable,
                  revocable, non-exclusive and non-sublicensable right to do the
                  following:
                  <br />
                  <br />
                  <ol type="i">
                    <li>
                      Install and make non-commercial, personal use of the
                      Festiv Service; and
                    </li>
                    <br />
                    <li>
                      stream or temporarily download copyrighted materials,
                      including but not limited to movies, television shows,
                      other entertainment or informational programming,
                      trailers, bonus materials, images, and artwork (the
                      "Festiv Content") that are available to you from the
                      Festiv Service. <br />
                      <br />
                      This is a license agreement and not an agreement for sale
                      or assignment of any rights in the Festiv Content or the
                      Festiv Service. The purchase of a license to stream or
                      temporarily download any Festiv Content does not create an
                      ownership interest in the content available for streaming
                      or download on Festiv. Such Content available for
                      streaming or download on Festiv, including the copyrights,
                      trademarks, service marks, trade names, trade dress and
                      other intellectual property rights in the Festiv Serivce,
                      are owned by the current Content rights holder, its
                      affiliates and/or other licensors, and is protected by the
                      copyright laws of the United States, as well as other
                      intellectual property laws and treaties.
                    </li>
                  </ol>
                </li>
                <br />
                <li>
                  Restrictions on Your Use of the Festiv Service Content. You
                  agree that as a condition of your license, you may not and
                  agree not to:
                  <br />
                  <br />
                  <ol type="i">
                    <li>
                      circumvent or disable any content protection system or
                      digital rights management technology used in connection
                      with the Festiv Service to control access to the Content
                      available on Festiv;
                    </li>
                    <br />
                    <li>
                      copy the Festiv Content (except as expressly permitted by
                      this Subscriber Agreement);
                    </li>
                    <br />
                    <li>
                      rebroadcast, transmit or perform the Festiv Content
                      available via the Festiv Service;
                    </li>
                    <br />
                    <li>
                      create derivative works of the content available on
                      Festiv; or
                    </li>
                    <br />
                    <li>
                      allow third parties to violate the above restrictions.
                    </li>
                  </ol>
                </li>
                <br />
                <li>
                  Restrictions on Your Use of the Festiv Service. You agree that
                  as a condition of your license, you may not and agree not to:
                  <br />
                  <br />
                  <ol type="i">
                    <li>
                      move, decompile, reverse-engineer, disassemble, or
                      otherwise reduce to human-readable form the Festiv Service
                      and/or the video player(s), underlying technology, any
                      digital rights management mechanism, device, or other
                      content protection or access control measure incorporated
                      into the video player(s);
                    </li>
                    <br />
                    <li>
                      modify the Festiv Service, including, but not limited to,
                      by removing identification, copyright or other proprietary
                      notices from the Festiv Content or the Festiv Service;
                    </li>
                    <br />
                    <li>
                      access or use the Festiv Service in a manner that suggests
                      an association with our products, services or brands;
                    </li>
                    <br />
                    <li>
                      use the Festiv Service for any commercial or
                      business-related use unapproved by Festiv or in any
                      commercial establishment or area open to the public (e.g.,
                      lobby, bar, restaurant, diner, stadium, casino, club,
                      cafe, theater, etc.) or build a business utilizing the
                      Festiv Content or Festiv Service, whether or not for
                      profit;
                    </li>
                    <br />
                    <li>
                      create derivative works of any components of the Festiv
                      Service owned by Next Wave Media Partners, LLC, any
                      updates, or any part thereof, except as and only to the
                      extent that any foregoing restriction is prohibited by
                      applicable law;
                    </li>
                    <br />
                    <li>
                      bypass, modify, defeat, tamper with or circumvent any of
                      the functions or protections of the Festiv Service;
                    </li>
                    <br />
                    <li>
                      access, monitor or copy, or permit another person or
                      entity to access, monitor or copy, any element of the
                      Festiv Service using a robot, spider, scraper or other
                      automated means or manual process without our express
                      written permission;
                    </li>
                    <br />
                    <li>
                      damage, disable, overburden or impair the Festiv Service;
                    </li>
                    <br />
                    <li>
                      use the Festiv Service in any unlawful manner, for any
                      unlawful purpose, or in any manner inconsistent with this
                      Subscriber Agreement;
                    </li>
                    <br />
                    <li>share your login credentials with third parties; or</li>
                    <br />
                    <li>
                      otherwise allow third parties to violate the above
                      restrictions.
                    </li>
                  </ol>
                </li>
                <br />
                <li>
                  Violations. Any attempt to perform any of the restrictions
                  listed in Sections 3(b) and 3(c) above is a violation of the
                  rights of Festiv, Next Wave Media Partners, LLC, and the
                  copyright holder.
                </li>
              </ol>
            </li>
            {/** 4 */}
            <li>
              <h2 className="extra-documents-small-header">USAGE TERMS</h2>
              <ol type="a" className="extra-documents-text">
                <li>
                  Festiv Compatible Devices. Use of the Festiv Service requires
                  compatible devices, and certain software may require periodic
                  updates, and your use of the Festiv Service may be affected by
                  the performance of these elements. You can access Festiv
                  Content with almost any Internet-connected computer or through
                  the Festiv Service application available for certain mobile or
                  other devices (Internet connection required) (each, a
                  "Compatible Device"). For specifics concerning supported
                  devices, operating systems, web browsers and optimal streaming
                  support please visit our Terms of Use page. You can add a
                  Compatible Device to your Festiv accounts by downloading the
                  Festiv Service application(s) to the Compatible Device and by
                  signing into your Festiv account(s) through the application.
                </li>
                <br />
                <li>
                  Internet Connection. You must have a high-speed Internet
                  connection in order to access and use certain aspects of the
                  Festiv Service.
                </li>
                <br />
                <li>
                  Streaming Festiv Content. Festiv Content can be streamed
                  through the Festiv Service over an active Internet connection.
                  Concurrent streams are allowed for each Festiv account at any
                  time. The number of concurrent streams available for use may
                  change from time to time at our discretion.
                </li>
                <br />
                <li>
                  Downloading Content Available on Festiv. Festiv Content may be
                  available for temporary download for offline viewing on
                  certain Compatible Devices. When downloads are available,
                  Downloads are limited to a maximum of ten (10) Compatible
                  Devices for each of the Festiv Service. You will not be
                  permitted to download to an additional device once the maximum
                  of ten (10) Compatible Devices has been reached for that
                  service. You must connect your Compatible Device to the
                  Internet and access the Festiv Service at least once every
                  thirty (30) days for your downloaded Festiv Content to remain
                  available for offline viewing. The length of time that certain
                  temporary downloads remain available to you for offline
                  viewing may change from time to time at our discretion.
                </li>
                <br />
                <li>
                  Profiles. Festiv will, at some point in the future, offer the
                  option to personalize use of the Festiv Service through the
                  creation of one or more profiles under one account. You may be
                  able to designate a profile as a Kid profile, which will
                  restrict viewing of certain Festiv Content from within that
                  profile. When established, you will be able to visit our Terms
                  of Use page if you would like more information about creating
                  profiles.
                </li>
                <br />
                <li>
                  Festiv Content Availability. Certain Festiv Content available
                  through the Festiv Service subscription may not be available
                  in all countries or territories within the Territory.
                  Geographic restrictions will be enforced according to the
                  location from which you are accessing the Festiv Service, and
                  we may use different technologies and methods to verify your
                  geographic location
                </li>
                <br />
                <li>
                  Future Unavailability. It is possible that the Festiv Service,
                  and/or some or all Festiv Content may not be available for
                  streaming or downloading at any given time including (i)
                  during any maintenance or update periods; (ii) any power or
                  server outages; (iii) as a result of war, riots, strikes,
                  social unrest; or (iv) as a result of other matters beyond the
                  control of us or third parties. Festiv will take reasonable
                  efforts to provide you with as much prior notice as possible;
                  however, Festiv shall have no liability to you in such event.
                  There may be times when we have to remove certain features or
                  functionality and/or devices or platforms from being able to
                  access the Festiv Service. We will do our best to let you know
                  of any of these changes, usage rules and restrictions, but you
                  acknowledge that we may do so in our sole discretion at any
                  time without notice. You also agree that we will not be liable
                  to you for any modification, suspension, or discontinuance of
                  the Festiv Service, although if you are a subscriber and we
                  suspend or discontinue your subscription to the Festiv
                  Service, we may, in our sole discretion, provide you with a
                  credit, refund, discount or other form of consideration.
                  However, if we terminate your account or suspend or
                  discontinue your access to the Festiv Service due to your
                  violation of the Subscriber Agreement, then you will not be
                  eligible for any such credit, refund, discount, or other
                  consideration.
                </li>
                <br />
                <li>
                  Promotional and Experimental Features. In our continued
                  assessment of the Festiv Service, we may from time to time,
                  with respect to any or all of our users, experiment or
                  otherwise offer certain features or other elements of the
                  Festiv Service, including promotional features, user
                  interfaces, plans and pricing. Your use of any updates,
                  modifications to, or replacement versions of the Festiv
                  Service shall be governed by this Subscriber Agreement and any
                  additional terms you agree to when you install such update,
                  modification, or replacement version.
                </li>
              </ol>
            </li>
            {/** 5 */}
            <li>
              <h2 className="extra-documents-small-header">
                USE AND SHARING OF CERTAIN INFORMATION
              </h2>
              <div className="extra-documents-text">
                <p>
                  For more information about our collection, use, and sharing of
                  your information please refer to our PRIVACY POLICY, which is
                  incorporated herein by this reference.
                </p>
                <p>
                  Festiv reserves the right to, and you agree that Festiv may,
                  release your details to system administrators at other sites
                  and to law enforcement agencies in order to assist them in
                  resolving security incidents and violations of law.
                </p>
              </div>
            </li>
            {/** 6 */}
            <li>
              <h2 className="extra-documents-small-header">
                SUSPENSION AND TERMINATION
              </h2>
              <div className="extra-documents-text">
                <p>
                  You agree that Festiv may, in its sole discretion and without
                  notice or liability to you, restrict, suspend, or terminate
                  your access to part or all of the Festiv Service, and to any
                  Festiv Content if we believe you are using or have used the
                  Festiv Service in violation of the Subscriber Agreement or
                  applicable law or regulations or in any manner other than for
                  their intended purpose and in accordance with all other
                  guidelines and requirements applicable thereto. Without
                  limiting the foregoing, Festiv may restrict or suspend your
                  access to your Festiv account(s) for cause and upon reasonable
                  notice to you, which may be communicated electronically, which
                  cause includes but is not limited to (a) requests from law
                  enforcement or other government authorities, (b) unexpected
                  technical issues or problems, or (c) if Festiv reasonably
                  believes that your Festiv Account has been created
                  fraudulently, your Festiv Account has been accessed
                  fraudulently, or anyone uses your Festiv Account to commit
                  fraud or for any purpose other than its intended purpose and
                  in accordance with all of the requirements applicable thereto.
                  Festiv also reserves the right, after notice to you, to
                  terminate any Festiv Account that remains inactive for two
                  years (failure to log in to your Festiv Account will
                  constitute inactivity for purposes of this Agreement).
                </p>
                <p>
                  You agree that Festiv will not be liable to you or to any
                  third party for any such restriction, suspension, or
                  termination of your access to your Festiv Account or content.
                </p>
                <p>
                  UPON TERMINATION OF YOUR FESTIV ACCOUNT, WHETHER TERMINATED BY
                  FESTIV, OR AT YOUR REQUEST (OTHER THAN A CANCELLATION OF YOUR
                  SUBSCRIPTION, IN WHICH CASE YOUR SUBSCRIPTION WILL CONTINUE TO
                  THE END OF THE BILLING PERIOD), YOU WILL IMMEDIATELY LOSE THE
                  RIGHT TO ACCESS STREAMED CONTENT THROUGH THE FESTIV SERVICE
                  AND WITHIN A LIMITED PERIOD WILL LOSE THE RIGHT TO VIEW FESTIV
                  CONTENT DOWNLOADED WITHIN THE FESTIV SERVICE.
                </p>
              </div>
            </li>
            {/** 7 */}
            <li>
              <h2 className="extra-documents-small-header">
                BINDING ARBITRATION AND CLASS ACTION WAIVER
              </h2>
              <div className="extra-documents-text">
                <p>
                  PROCEEDINGS TO RESOLVE OR LITIGATE A DISPUTE IN ANY FORUM WILL
                  BE CONDUCTED ON AN INDIVIDUAL BASIS. Neither you, nor Festiv,
                  nor Next Wave Media Partners, LLC will seek to have a dispute
                  heard as a class action or private attorney general action or
                  in any other proceeding in which either party acts or proposes
                  to act in a representative capacity. No arbitration or
                  proceeding can be combined with another without the prior
                  written consent of all parties to the arbitrations or
                  proceedings.
                </p>
                <p>
                  You and Festiv agree to arbitrate, as provided below, all
                  disputes between you (including any related disputes involving
                  Next Wave Media Partners, LLC or its affiliates), that are not
                  resolved informally, except disputes relating to the ownership
                  or enforcement of intellectual property rights. "Dispute"
                  includes any dispute, action, or other controversy, whether
                  based on past, present, or future events, between you and us
                  concerning the Festiv Service, or this Agreement, whether in
                  contract, tort, warranty, statute, regulation, or other legal
                  or equitable basis. You and Next Wave Media Partners, LLC
                  empower the arbitrator with the exclusive authority to resolve
                  any dispute relating to the interpretation, applicability or
                  enforceability of these terms or the formation of this
                  contract, including the arbitrability of any dispute and any
                  claim that all or any part of this Subscriber Agreement are
                  void or voidable.
                </p>
              </div>
              <br />
              <ol type="a" className="extra-documents-text">
                <li>
                  <p>
                    In the event of a dispute, you or Festiv must send to the
                    other party a notice of dispute, which is a written
                    statement that sets forth the name, address, and contact
                    information of the party giving the notice, the facts giving
                    rise to the dispute, and the relief requested. You must send
                    any notice of dispute to Festiv, 703 Pier Ave., Ste. B421,
                    Hermosa Beach, California 90254, USA, Attention: Legal. We
                    will send any notice of dispute to you at the contact
                    information we have for you. You and Festiv will attempt to
                    resolve a dispute through informal negotiation within one
                    hundred and twenty (120) days from the date the notice of
                    dispute is sent. After that one hundred and twenty (120) day
                    period and not before, you or we may instead litigate a
                    dispute in small claims court if the dispute meets the
                    requirements to be heard in small claims court, prior to
                    commencing an arbitration proceeding.
                  </p>
                </li>
                <br />
                <li>
                  <p>
                    {" "}
                    If you and Festiv do not resolve a dispute by informal
                    negotiation or in small claims court, the dispute shall be
                    resolved by binding arbitration before a neutral arbitrator
                    whose decision will be final except for a limited right of
                    appeal under the U.S. Federal Arbitration Act. YOU ARE
                    GIVING UP THE RIGHT TO LITIGATE A DISPUTE IN COURT BEFORE A
                    JUDGE OR JURY. Arbitration will be administered by JAMS
                    Mediation, Arbitration and ADR Services ("JAMS") in
                    accordance with the JAMS Streamlined Arbitration Rules and
                    Procedures (the "JAMS Rules"). The JAMS Rules and
                    instructions about how to initiate an arbitration are
                    available at www.jamsadr.com or 1-800-352-5267. Arbitration
                    may be conducted in person, through the submission of
                    documents, by phone, or online. Proceedings that cannot be
                    conducted through the submission of documents, by phone, or
                    online, will take place in Los Angeles, California. However,
                    if circumstances prevent you from traveling to Los Angeles,
                    You and Festiv agree to submit to the exclusive jurisdiction
                    of the federal or state courts located in Los Angeles,
                    California in order to compel arbitration, to stay
                    proceedings pending arbitration, or to confirm, modify,
                    vacate, or enter judgment on the award entered by the
                    arbitrator. The arbitrator may award damages to you
                    individually as a court could, including declaratory or
                    injunctive relief, but only to the extent required to
                    satisfy your individual claim.
                  </p>
                  <br />
                  <br />{" "}
                  <p>
                    In accordance with the JAMS Rules, the party initiating the
                    arbitration (either you or Festiv) is responsible for paying
                    the filing fee. If the arbitrator issues you an award of
                    damages and: (a) that award is greater than the amount of
                    our last written settlement offer; we will pay the amount of
                    the award. If the arbitrator issues us an award of damages
                    and: (a) that award is greater than the amount of your last
                    written settlement offer; you will pay us the total amount
                    of the award.
                  </p>
                  <br />
                  <br />{" "}
                  <p>
                    Except as provided above with respect to jurisdiction in Los
                    Angeles, California, nothing in this arbitration provision
                    shall be construed as consent by Festiv or its affiliates to
                    the jurisdiction of any other court with regard to disputes,
                    claims or controversies unrelated to the Festiv Service, or
                    this Agreement.
                  </p>
                </li>
              </ol>
            </li>
            {/** 8 */}
            <li>
              <h2 className="extra-documents-small-header">
                ADDITIONAL PROVISIONS
              </h2>
              <ol type="a" className="extra-documents-text">
                <li>
                  You understand and agree that the Festiv Content you receive
                  through the Festiv Service is intended for informational and
                  entertainment purposes only; it does not constitute legal,
                  financial, professional, medical or healthcare advice or
                  diagnosis and cannot be used for such purposes.
                </li>
                <br />
                <li>
                  Content Subjectivity. Content on Festiv tends to elicit
                  varying reactions among different people. You may come across
                  Content on Festiv that you find offensive, indecent, explicit,
                  or objectionable. Also, content ratings, types, genres,
                  categories, and/or descriptions are provided as suggestions to
                  help with navigation and for informational purposes. We do not
                  guarantee that you will agree with them. You acknowledge these
                  risks and your responsibility for making your own choices
                  regarding what Content on Festiv is appropriate for your
                  family.
                </li>
                <br />
                <li>
                  Photosensitivities. Content on Festiv may contain some
                  flashing lights sequences or patterns which may affect users
                  who are susceptible to photosensitive epilepsy or other
                  photosensitivities. Additionally, 4K UHD HDR content versions
                  enable greater brightness and color saturation which may also
                  affect users.
                </li>
                <br />
                <li>
                  Content Quality. We use various technologies to provide you
                  with an optimal viewing experience. For example, HD quality is
                  available for certain Content on Festiv and 4K Ultra HD
                  quality is available for certain Content on Festiv. That said,
                  the playback quality of Content on Festiv, including
                  resolution, may be affected by the format of the Content on
                  Festiv, your location, the speed, bandwidth and specific terms
                  of your Internet service, and the devices and/or equipment
                  used, among other factors. The time it takes you to begin
                  viewing Content on Festiv will vary based on several factors,
                  including your location, Internet bandwidth, the number of
                  devices simultaneously connecting to the same network, the
                  Content on Festiv you have selected, and the configuration of
                  the device you are using. As a result, we are unable to make
                  any warranties about the Content on Festiv in these respects.
                </li>
                <br />
                <li>
                  Third-Party Services and Content. The Festiv Service may
                  integrate, be integrated into, or be provided in connection
                  with third-party services and content. We do not control those
                  third-party services and content. You should read the terms of
                  use, agreements and privacy policies that apply to such
                  third-party services and content. If you access the Festiv
                  Service using a third-party service or device (for example, an
                  Apple iOS, Android, or Microsoft Windows-powered device) then
                  Apple Inc., Google, Inc. or Microsoft Corporation,
                  respectively, or another such company that offers a
                  third-party service or device, shall be a third-party
                  beneficiary to this contract. However, these third-party
                  beneficiaries are not a party to this contract. You agree that
                  your access to the Festiv Service using these devices also
                  shall be subject to the usage terms set forth in the
                  applicable third-party beneficiary's terms of service.
                </li>
                <br />
                <li>
                  Mobile Networks. When you access the Festiv Service through a
                  mobile network, your network or roaming provider's messaging,
                  data and other rates and fees will apply. Downloading,
                  installing, or using the Festiv Service may be prohibited or
                  restricted by your network provider and the Festiv Service may
                  not work with your network provider or device.
                </li>
                <br />
                <li>
                  Submissions and Unsolicited Ideas Policies. Our policy does
                  not allow us to accept or consider unsolicited creative ideas,
                  suggestions, or materials. In connection with anything you
                  submit to us, whether or not solicited by us, you agree that
                  creative ideas, suggestions or other materials you submit are
                  not being made in confidence or trust and that no confidential
                  or fiduciary relationship is intended or created between you
                  and us in any way, and that you have no expectation of review,
                  compensation or consideration of any type unless the content
                  is submitted via our standard fee based submission process for
                  acceptance and screening as a part of the Festiv Service.
                  Festiv does not claim ownership over any ideas, suggestions,
                  or other materials submitted; however, as to such materials,
                  you grant us a non-exclusive, sublicensable, irrevocable and
                  royalty-free worldwide license under all copyrights,
                  trademarks, patents, trade secrets, privacy and publicity
                  rights and other intellectual property rights to use,
                  reproduce, transmit, display, create derivative works, or
                  otherwise exploit them for any purpose without limit as to
                  time, manner and frequency of use, without further notice to
                  you, with or without attribution, and without the requirement
                  of permission from or payment to you or any other person or
                  entity.
                </li>
                <br />
                <li>
                  Contact Information. <br />
                  <br />
                  You may contact Festiv as follows:
                  <br />
                  <br />
                  by email at: info@festivmedia.com
                </li>
                <br />
                <li>
                  DISCLAIMERS OF WARRANTY; LIABILITY LIMITATION. YOU ACKNOWLEDGE
                  AND AGREE TO THE ESSENTIAL CONDITION THAT THE FESTIV CONTENT
                  AS WELL AS THE FESTIV SERVICE ARE PROVIDED "AS IS" AND WITHOUT
                  WARRANTIES OF ANY KIND. TO THE FULLEST EXTENT PERMITTED BY
                  LAW, FESTIV, AND THEIR AFFILIATES, LICENSORS, AGENTS, AND
                  SERVICE PROVIDERS (COLLECTIVELY, THE "FESTIV PARTIES") EACH
                  EXPRESSLY DISCLAIMS ANY WARRANTIES AND CONDITIONS, EXPRESS,
                  IMPLIED, OR STATUTORY, INCLUDING BUT NOT LIMITED TO,
                  WARRANTIES OR CONDITIONS OF MERCHANTABILITY, FITNESS FOR A
                  PARTICULAR PURPOSE, ACCURACY, COMPLETENESS, AVAILABILITY,
                  SECURITY, COMPATIBILITY, SATISFACTORY QUALITY AND
                  NONINFRINGEMENT, AND THEIR EQUIVALENTS UNDER THE LAWS OF ANY
                  JURISDICTION. YOU ALSO ACKNOWLEDGE AND AGREE THAT THE FESTIV
                  PARTIES DO NOT WARRANT THAT THE CONTENT AND THE FESTIV SERVICE
                  WILL BE WITHOUT ERRORS, VIRUSES, OR OTHER HARMFUL COMPONENTS.
                  THE FESTIV PARTIES DO NOT WARRANT OR OTHERWISE STATE THAT THE
                  FESTIV CONTENT AND THE FESTIV SERVICE WILL MEET YOUR
                  REQUIREMENTS. YOU AND NOT THE FESTIV PARTIES ASSUME THE ENTIRE
                  COST OF ALL NECESSARY SERVICING, REPAIR OR CORRECTION
                  OCCASIONED BY USE OF THE FESTIV CONTENT AND THE FESTIV
                  SERVICE. SOME JURISDICTIONS DO NOT ALLOW THE EXCLUSION OF
                  IMPLIED WARRANTIES, SO THE ABOVE EXCLUSION MAY NOT APPLY TO
                  YOU. TO THE EXTENT NOT PROHIBITED BY APPLICABLE LAW, IN NO
                  EVENT SHALL THE FESTIV PARTIES BE LIABLE FOR ANY PERSONAL
                  INJURY, OR ANY INDIRECT, SPECIAL, INCIDENTAL OR CONSEQUENTIAL
                  DAMAGES OF ANY KIND, INCLUDING LOST PROFITS AND PROPERTY
                  DAMAGE, EVEN IF WE WERE ADVISED OF THE POSSIBILITY OF SUCH
                  DAMAGES, THAT RESULT FROM THE USE OF, OR INABILITY TO USE THE
                  FESTIV CONTENT OR THE FESTIV SERVICES, HOWEVER CAUSED. NOR
                  SHALL WE BE HELD LIABLE FOR DELAY OR FAILURE IN PERFORMANCE
                  RESULTING FROM CAUSES BEYOND OUR REASONABLE CONTROL. IN NO
                  EVENT SHALL OUR TOTAL LIABILITY TO YOU FOR ALL DAMAGES, LOSSES
                  AND CAUSES OF ACTION EXCEED SEVEN HUNDRED AND FIFTY U.S.
                  DOLLARS (US $750). THE LIABILITY LIMITATIONS IN THIS SECTION
                  APPLY UNDER ANY LEGAL THEORY (TORT, CONTRACT, BREACH OF
                  WARRANTY, STRICT LIABILITY OR OTHERWISE) EVEN IF THE FESTIV
                  PARTIES HAVE BEEN ADVISED OF THE POSSIBILITY OF SUCH DAMAGES.
                  BECAUSE SOME STATES AND JURISDICTIONS DO NOT ALLOW THE
                  EXCLUSION OR LIMITATION OF LIABILITY, THE ABOVE LIMITATION MAY
                  NOT APPLY TO YOU.
                </li>
                <br />
                <li>
                  Choice of Forum. You agree that any action at law or in equity
                  arising out of or relating to this Agreement that is not
                  subject to arbitration shall be filed, and that venue properly
                  lies, only in the state or federal courts located in the
                  county of Los Angeles, California, United States of America,
                  and you consent and submit to the personal jurisdiction of
                  such courts for the purposes of litigating such action.
                </li>
                <br />
                <li>
                  Choice of Law. This Agreement is governed by and construed in
                  accordance with the laws of the State of California and the
                  laws of the United States, without giving effect to any
                  conflict of law principles.
                </li>
                <br />
                <li>
                  Severability. If any provision of this Agreement shall be
                  unlawful, void or for any reason unenforceable, then that
                  provision shall be deemed severable from the Agreement and
                  shall not affect the validity and enforceability of any
                  remaining provisions.
                </li>
                <br />
                <li>
                  Survival. The provisions of this Agreement which by their
                  nature should survive the termination of this Agreement shall
                  survive such termination.
                </li>
                <br />
                <li>
                  Entire Agreement. This Subscriber Agreement and the provisions
                  referenced herein, constitute the entire agreement between you
                  and Festiv pertaining to the subject matter hereof and
                  supersedes all prior or other arrangements, understandings,
                  negotiations, and discussions, whether oral or written. No
                  waiver of any of the provisions of the Subscriber Agreement
                  shall be deemed or shall constitute a waiver of any other
                  provisions hereof (whether or not similar), nor shall such
                  waiver constitute a continuing waiver unless otherwise
                  expressly provided.
                </li>
              </ol>
            </li>
          </ol>
        </article>
      </section>

      <Footer />
    </div>
  );
}
