import axios from "axios";
import { BACKEND_URL } from "../constants/Strings";

export const getFromLaravel = async (api) => {
  const { response } = await axios.get(
    `${BACKEND_URL}${api}`
  );

  return response;
};


export const getByIdFromLaravel = async (id) => {
  const response = await axios.get(
    `${BACKEND_URL}${id}`
  );
  // console.log(response);
  return response;

}

export const postToLaravel = async (api, payload) => {
  try {
    let res = await axios.post(
      `${BACKEND_URL}${api}`,
      payload
    );
    let data = res.data;
    // console.log( `Post to ${api} \n Response:`, res)
    // console.log(data);
    return res;
  }
  catch(e){
    return Promise.reject(e)
  }
};



// email: rc@cenedex.com
// pw: rcroney1