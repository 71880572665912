import React, { useEffect } from "react";
import SignedOutNavbar from "../components/navbar/signed-out-navbar";
import Footer from "../components/footer";

export default function TermsOfUse() {
  return (
    <div>
      <SignedOutNavbar />

      <div className="section-6">
        <div className="extra-documents-container w-container">
          <div className="extra-documents-title-wrapper">
            <div className="extra-documents-page-title">
              <h1>FESTIV WEBSITE TERMS OF USE</h1>
            </div>
            <div className="extra-documents-page-subtitle">
              <h2>LAST UPDATED JANUARY 23, 2021</h2>
            </div>
          </div>
          <div className="extra-documents-text">
            <p>
              These Terms of Use govern your use of this website (“Festiv
              Website”), which is operated by or for Next Wave Media Partners,
              LLC (“Festiv” or “us”) and which links to these Terms of Use.
              These Terms of Use are applicable to such use whether you possess
              or create a Festiv Account through or on the Festiv Website.
              Websites that do not contain a link to these Terms of Use are not
              subject to the terms described below.By accessing or using the
              Festiv Website, you acknowledge that you understand and agree to
              be bound by these Terms of Use. If you do not understand or agree
              to be bound by these Terms of Use, do not access or use the Festiv
              Website. Please note that when you create an end user online
              profile and billing account (“Festiv Account”) on the Festiv
              Website, you are additionally subject to the Festiv Account Terms
              and Conditions, which also govern your use of all Festiv affiliate
              or associated websites (“Account Terms”).If you download any
              Festiv mobile application and any updates thereto (“Mobile App”),
              you are additionally subject to the terms of use governing such
              Mobile App. If you access your Festiv Account from various
              devices, you may be asked to agree to a separate end user license
              agreement (“EULA”) for those devices. To the extent you have a
              Festiv Account and a conflict arises between these Terms of Use
              and your Account Terms, the Account Terms shall govern. 
            </p>
          </div>
          <div className="extra-documents-small-header">
            <h2>CHANGES TO THESE TERMS</h2>
          </div>
          <div className="extra-documents-text">
            <p>
              We reserve the right to modify these Terms of Use, in whole or in
              part, in our own discretion at any time. Such modifications shall
              be effective immediately upon the linking of modified Terms of Use
              to the Festiv Website.
            </p>
          </div>
          <div className="extra-documents-small-header">
            <h2>PRIVACY POLICY AND USE OF DATA</h2>
          </div>
          <div className="extra-documents-text">
            <p>
              Festiv’s privacy policy, (“Privacy Policy”), explains Festiv’s
              policies regarding the collection, use, transmission and
              disclosure of information provided by or collected from you via
              your Festiv Account, the devices used to access it, and/or the
              Festiv Website. By agreeing to be bound by these Terms of Use, you
              are agreeing that you have read and understood the collection,
              use, transmission and disclosure of your information as described
              in the Privacy Policy. You should review the Privacy Policy before
              using the Festiv Website. The policies and other content of the
              Festiv web pages may be changed by Festiv in its sole discretion,
              without notice to you.
            </p>
          </div>
          <div className="extra-documents-small-header">
            <h2>INTELLECTUAL PROPERTY</h2>
          </div>
          <div className="extra-documents-text">
            <p>
              All text, graphics, user interfaces, photographs, trademarks,
              logos, sounds, music, artwork, computer code, and other materials
              created by and/or licensed by Festiv which is contained on, or
              within, the Festiv website (collectively, “Content”) are owned, or
              controlled, by Festiv, and is protected by trade dress, copyright,
              patent, and trademark laws, and various other intellectual
              property rights and laws. No license to or right in any such items
              as described above is granted to or conferred upon you, and all
              rights are reserved by Festiv or its licensors. Except as
              expressly provided in these Terms of Use, you agree not to modify,
              sell, license, rent, or edit, in any manner, any part of the
              Festiv Website, without Festiv’s express prior written consent.
            </p>
            <p>
              Notwithstanding the above, you may view and print selected
              available portions of the Festiv website solely for your own
              personal, informational use, provided that you do not republish
              the material and that you keep intact all copyright, trademarks,
              service marks, attributions, patent, and other proprietary
              notices.
            </p>
            <h2>FESTIV TRADEMARK GUIDELINES</h2>
            <p>
              All Festiv trademarks are the exclusive property of Next Wave
              Media Partners, LLC. Unauthorized use of any Festiv trademark,
              service mark, or logo may be a violation of federal and state and
              applicable foreign trademark laws.
            </p>
            <h2>PROCEDURE FOR CLAIMS OF COPYRIGHT INFRINGEMENT</h2>
            <p>
              If you believe in good faith that your copyright (or other
              intellectual property rights) has been infringed upon on the
              Festiv Website or that there is any content which is illegal or
              infringes your or a Third-Party&#x27;s rights, please contact us
              at <a href="mailto:info@festivmedia.com">info@festivmedia.com</a>.
            </p>{" "}
             
          </div>
          <div className="extra-documents-small-header">
            <h2>PURCHASES</h2>
          </div>
          <div className="extra-documents-text">
            <p>
              You may be able to purchase products or services through the
              Festiv Website in certain countries now or in the future. In such
              a case, you may be asked to supply certain information in
              connection with such purchase, including, but not limited to, your
              credit card number or other payment account number, your billing
              address, and your shipping information (“Personal Financial
              Information”).{" "}
            </p>
            <strong>
              <p>
                TO INITIATE A PURCHASE, YOU MUST BE (A) AT LEAST EIGHTEEN (18)
                YEARS OF AGE, OR THE AGE OF MAJORITY IN YOUR COUNTRY; AND (B)
                HAVE THE LEGAL RIGHT TO USE THE PAYMENT MEANS SELECTED BY YOU.{" "}
              </p>
            </strong>
            <p>
              By supplying your Personal Financial Information, you understand
              that we may use and disclose such information, including, but not
              limited to, providing such information to third parties for the
              purposes of order fulfillment and payment processing. For more
              information about how Festiv uses your information, please review
              our Privacy Policy. You also understand and acknowledge that
              Festiv may use a third-party payment processor to process credit
              card payments on our behalf, and when you supply Personal
              Financial Information in connection with a purchase, you agree
              that Festiv is not responsible for the security of such
              information when it is in the control of the third-party payment
              processor.
            </p>
            <p>
              By placing an order for any product or service made available
              through the Festiv Website, you represent that the products and/or
              services ordered will be used only in a lawful manner. Festiv
              reserves the right, with or without prior notice, to do any one or
              more of the following: (a) limit the available quantity of or
              discontinue any product or service; (b) impose conditions on the
              honoring of any coupon, coupon code, promotional code, or other
              similar promotion; (c) bar any user from making or completing any
              or all transaction(s); and (d) refuse to provide any user with any
              product or service. You agree to pay all charges that may be
              incurred by you or on your behalf through the Festiv Website, at
              the price(s) in effect when such charges are incurred including,
              without limitation, all shipping and handling charges. In
              addition, you remain responsible for any taxes that may be
              applicable to your transactions.
            </p>
          </div>
          <div className="extra-documents-small-header">
            <h2>YOUR PROVISION OF CONTRACT INFORMATION TO US</h2>
          </div>
          <div className="extra-documents-text">
            <p>
              You agree that all Personal Financial Information or other
              information that you provide to us will be (a) accurate, current,
              and complete; (b) promptly updated when there is a change; and (c)
              your own information and not that of another individual. If you
              provide any information that is untrue, inaccurate or incomplete,
              or we have reasons to suspect that such information is untrue,
              inaccurate or incomplete, we retain the right to refuse any or all
              current or future use of the Festiv Website or any portion
              thereof.
            </p>
            <br />
          </div>
          <div className="extra-documents-small-header">
            <h2>USER-PROVIDED CONTENT</h2>
          </div>
          <div className="extra-documents-text">
            <p>
              You understand that all information, data, or other materials that
              you or another user provide in connection with the Festiv Website
              or otherwise communicate to Festiv (&quot;User-Provided
              Content&quot;), including, but not limited to,<strong> </strong>
              product reviews and content posted to any user forums Festiv makes
              available, are the sole responsibility of you or the person from
              whom such User-Provided Content originated. This means that you,
              and not Festiv, are responsible for all User-Provided Content that
              you upload, post, email, transmit, or otherwise make available in
              connection with the Festiv Website. Festiv, unless otherwise
              noted, did not produce the User-Provided Content submitted and
              featured and, as such, does not guarantee the accuracy, integrity,
              or quality of any information contained within the User-Provided
              Content. You understand that by using the Festiv Website, you may
              be exposed to User-Provided Content that could be viewed as
              misleading, offensive, indecent, or objectionable. The views
              expressed by other users on Festiv do not represent our views or
              values, and Festiv will not be responsible or liable for
              User-Provided Content or the accuracy of any User-Provided
              Content.
            </p>
            <p>
              By submitting any User-Provided Content to Festiv, you represent
              and warrant that:
            </p>
            <ul>
              <li>
                you own or control all rights to the User-Provided Content you
                submit to Festiv for consideration; and
              </li>
              <li>
                all content that you submit does not violate these Terms of Use,
                and will not cause injury to any person or entity; and
              </li>
              <li>
                all content that you post is accurate, does not violate these
                Terms of Use, and will not cause injury to any person or entity;
                and
              </li>
              <li>you are at least 18 years old.</li>
            </ul>
            <p>
              We also will not disclose your identity to any Third-Party who is
              claiming that any User-Provided Content submitted by you for
              consideration on Festiv constitutes a violation of their
              intellectual property rights, or of their right to privacy.
            </p>
          </div>
          <div className="extra-documents-small-header">
            <strong>
              <h2>
                YOUR CLAIM OF OWNERSHIP OF SUBMITTED USER-PROVIDED CONTENT
              </h2>
            </strong>
          </div>
          <div className="extra-documents-text">
            <p>
              By submitting your User-Provided Content, you grant Festiv a
              royalty-free right to use and/or display such User-Provided
              Content in our publications, marketing material and
              advertisements. In our marketing material, publications, and
              advertisements we may, in our sole discretion, cite your name, or
              other user identifier, as provided when you submitted your
              User-Provided Content. As noted above, you represent and warrant
              that you own or otherwise control all rights in and to any such
              User-Provided Content, and that our use of your User-Provided
              Content in Festiv marketing materials, publications, and/or
              advertisements will not infringe, violate or interfere with the
              rights of anyone.
            </p>
          </div>
          <div className="extra-documents-small-header">
            <strong className="bold-text">
              <h2>FESTIV DISCRETION TO USE USER-PROVIDED CONTENT</h2>
            </strong>
          </div>
          <div className="extra-documents-text">
            <p>
              All User-Provided Content that you submit may be accepted and made
              available on the platform at Festiv&#x27;s sole discretion. Festiv
              reserves the right to remove, or refuse to accept any
              User-Provided Content on the Festiv Website in its sole
              discretion. None of the User-Provided Content that you submit, and
              is accepted for display on the website, shall be subject to any
              obligation of confidence on the part of Festiv, its agents,
              subsidiaries, affiliates, partners, or third-party service
              providers and their respective directors, officers, and employees.
            </p>
          </div>
          <div className="extra-documents-small-header">
            <h2>CONTENT AND CONDUCT GUIDELINES</h2>
          </div>
          <div className="extra-documents-text">
            <p>
              You agree to comply with all laws, rules, and regulations
              applicable to your use of the Festiv Website. In addition, you
              agree not to:
            </p>
            <ul>
              <li>
                email, transmit, or otherwise make available on the website any
                information that is known by you to be false or inaccurate;
              </li>
              <li>
                email, transmit, or otherwise make available any User-Provided
                Content that violates any law, statute, ordinance, or regulation
                (including, but not limited to, those governing consumer
                protection, unfair competition, anti-discrimination, or false
                advertising);
              </li>
              <li>
                email, transmit, or otherwise make available any User-Provided
                Content that is, or may reasonably be considered to be, harmful,
                threatening, abusive, harassing, tortious, defamatory, vulgar,
                obscene, libelous, invasive of another's privacy, hateful, or
                racially, ethnically or otherwise objectionable, or that harms
                minors in any way;
              </li>
              <li>
                forge headers or otherwise manipulate identifiers in order to
                disguise the origin of any User-Provided Content submitted to
                the Festiv Website;
              </li>
              <li>
                email, transmit, or otherwise make available any User-Provided
                Content that you do not have a right to make available under any
                law or under contractual or fiduciary relationships;
              </li>
              <li>
                email, transmit, or otherwise make available any User-Provided
                Content that infringes any patent, trademark, trade secret,
                copyright, right of publicity, or other intellectual property or
                proprietary right of any party;
              </li>
              <li>
                email, transmit, or otherwise make available any material that
                contains software viruses or any other computer code, files, or
                programs designed to interrupt, destroy, or limit the
                functionality of any computer software or hardware or
                telecommunications equipment;
              </li>
              <li>
                take any action that interferes with the proper working of the
                Festv Website, compromises the security of the Festv Website, or
                otherwise damages the Festiv Website or any materials and
                information available through the Festiv Website;
              </li>
              <li>
                attempt to gain unauthorized access to any portion or feature of
                the Festiv Website, to any other systems or networks connected
                to the Festiv Website, to any of our servers, or to any of the
                services offered on or through the Festiv Website, including but
                not limited to by hacking, password “mining”, or any other
                unauthorized means;
              </li>
              <li>
                probe, scan, or test the vulnerability of the Festiv Website or
                any network connected to the Festiv Website or bypass the
                authentication measures on the Festiv Website or any network
                connected to the Festiv Website;
              </li>
              <li>
                use any automated means to collect information or content from
                or otherwise access the Festiv Website, including but not
                limited to through the use of technical tools known as robots,
                spiders, or scrapers, without prior permission from Festiv;
              </li>
              <li>
                harvest or otherwise collect and store information about other
                users of the Festiv Website, including e-mail addresses;
              </li>
              <li>
                interfere with or disrupt the operation of the Festiv Website or
                server networks connected to the Festiv Website, or disobey any
                requirements, procedures, policies, or regulations of networks
                connected to the Festiv Website.
              </li>
            </ul>
            <p>
              You may be responsible for any loss or damage Festiv suffers as a
              result of your breach of any of the above promises.
            </p>
          </div>
          <div className="extra-documents-small-header">
            <h2>LINKS ON THE FESTIV WEBSITE TO THIRD PARTY WEBSITES</h2>
          </div>
          <div className="extra-documents-text">
            <p>
              The Festiv Website may contain links to or at times connect or
              redirect you to websites, apps, services and resources owned or
              controlled by third parties (collectively “Third-Party
              Services”). 
            </p>
            <p>
              We do not control or monitor such Third-Party Services and you
              acknowledge and agree that Festiv is not responsible or liable for
              the content, functionality or practices of such Third-Party
              Services. The inclusion of links to Third-Party Services on the
              Festiv Website does not imply approval or endorsement of
              Third-Party Services by Festiv, nor does it suggest any
              association with operators of such Third-Party Services. If you
              decide to leave the Festiv Website and access these Third-Party
              Services, you do so at your own risk. We suggest that you read the
              terms of use and privacy policies (if any) of those Third-Party
              Services. You agree not to hold Festiv or its affiliated companies
              responsible for any harm that may arise based on your access to or
              use of any Third-Party Services. Festiv is not responsible for or
              any form of transmission received from any linked Third-Party
              Services. Any reliance on the contents of Third-Party Services is
              undertaken at your own risk and you assume all responsibilities
              and consequences resulting from such reliance.
            </p>
          </div>
          <div className="extra-documents-small-header">
            <h2>LINKS TO FESTIV WEBSITE</h2>
          </div>
          <div className="extra-documents-text">
            <p>
              Unless otherwise set forth in a written agreement between you and
              Festiv, you must not include a link (including “deep-linking,”
              “framing” or “inlining”) to the Festiv Website on any website or
              online service that you own, control, or operate, except with
              Festiv’s prior written consent. Festiv reserves the right to
              withdraw its consent at any time and may request that any person
              or entity remove a link to any portion of the Festiv Website at
              any time and in its sole discretion.
            </p>
          </div>
          <div className="extra-documents-small-header">
            <strong>
              <h2>INFORMATION, NEWS, PRESS RELEASES, AND ILLUSTRATIONS</h2>
            </strong>
          </div>
          <div className="extra-documents-text">
            <p>
              The Festiv Website may contain information about our company, such
              as news and press releases. You acknowledge that we have no duty
              or obligation to update any such information.
            </p>
          </div>
          <div className="extra-documents-small-header">
            <h2>REGISTRATION AND PASSWORDS</h2>
          </div>
          <div className="extra-documents-text">
            <p>
              The Festiv Website may permit or require you to register or obtain
              a password prior to permitting you to access certain portions of
              the Festiv Website. You acknowledge and agree that you are
              responsible for maintaining the confidentiality of your login ID
              and password, and for all uses of your login ID and password. You
              agree to notify Festiv immediately of any unauthorized use of your
              login ID, password, or any other breach of security involving
              access to the Festiv Website through your login ID.
            </p>
          </div>
          <div className="extra-documents-small-header">
            <h2>OPTIONAL OPTIONS, PROMOTIONS, AND FEATURES</h2>
          </div>
          <div className="extra-documents-text">
            <p>
              We may offer or facilitate Third-Party offers for you to
              participate in such as rebates, coupons or other discount programs
              as well as promotions such as contests or sweepstakes on the
              Festiv Website or via Third-Party platforms. These offers and
              promotions may require purchases or participation in surveys, and
              may be subject to separate and additional terms and conditions
              governing your participation and eligibility for any such
              programs. Festiv is not responsible or liable for any claims,
              damages, losses or injuries arising from your interactions with
              such third parties.
            </p>
          </div>
          <div className="extra-documents-small-header">
            <h2>OPERATION OF THE FESTIV WEBSITE</h2>
          </div>
          <div className="extra-documents-text">
            <p>
              We reserve the right to do any of the following, at any time, at
              our sole discretion, with notice: (a) modify, suspend, or
              terminate operation of your access to the Festiv Website, or any
              portion of the Festiv Website; for your violation of these Terms
              of Use; (b) modify or change the Festiv Website, or any portion of
              the Festiv Website; and (c) interrupt the regular operation of the
              Festiv Website, or any portion of the Festiv Website, as necessary
              to perform routine or non-routine maintenance, to correct errors,
              or to make other changes to the Festiv Website.
            </p>
            <p>
              If you are a consumer user, please note that we only provide the
              Festiv Website for private use. You agree not to use our site for
              any commercial or business purposes except for those which are the
              stated purpose of the Festiv Website.
            </p>
          </div>
          <div className="extra-documents-small-header">
            <h2>DISCLAIMER</h2>
          </div>
          <div className="extra-documents-text">
            <p>
              THE FESTIV WEBSITE AND ALL MATERIALS, INFORMATION AND SOFTWARE
              INCLUDED IN OR AVAILABLE THROUGH THE FESTIV WEBSITE IS PROVIDED
              &quot;AS IS&quot; AND &quot;AS AVAILABLE&quot;. THE FESTIV WEBSITE
              AND ALL MATERIALS, INFORMATION AND SOFTWARE INCLUDED IN OR
              AVAILABLE THROUGH THE FESTIV WEBSITE IS PROVIDED WITHOUT WARRANTY
              OF ANY KIND, EITHER EXPRESS OR IMPLIED, INCLUDING, BUT NOT LIMITED
              TO, IMPLIED WARRANTIES OF MERCHANTABILITY, FITNESS FOR A
              PARTICULAR PURPOSE, OR NON-INFRINGEMENT. WE AND OUR AFFILIATES DO
              NOT WARRANT THAT THE MATERIALS, INFORMATION AND SOFTWARE INCLUDED
              IN OR AVAILABLE THROUGH THE FESTIV WEBSITE IS ACCURATE, RELIABLE,
              OR CORRECT; THAT THE FESTIV WEBSITE WILL BE AVAILABLE AT ANY
              PARTICULAR TIME OR LOCATION; THAT ANY DEFECTS OR ERRORS WILL BE
              CORRECTED; OR THAT THE FESTIV WEBSITE IS FREE OF VIRUSES OR OTHER
              HARMFUL COMPONENTS. YOUR USE OF THE FESTIV WEBSITE IS AT YOUR SOLE
              RISK. BECAUSE SOME JURISDICTIONS DO NOT PERMIT THE EXCLUSION OF
              CERTAIN WARRANTIES, THESE EXCLUSIONS MAY NOT APPLY TO YOU.
            </p>
          </div>
          <div className="extra-documents-small-header">
            <h2>LIMITATION OF LIABILITY</h2>
          </div>
          <div className="extra-documents-text">
            <p>
              UNDER NO CIRCUMSTANCES SHALL WE OR OUR AFFILIATES BE LIABLE FOR
              ANY DIRECT, INDIRECT, PUNITIVE, INCIDENTAL, SPECIAL, OR
              CONSEQUENTIAL DAMAGES ARISING OUT OF OR IN ANY WAY CONNECTED WITH
              THE USE OF, OR INABILITY TO USE, THE FESTIV WEBSITE. THIS
              LIMITATION APPLIES WHETHER THE ALLEGED LIABILITY IS BASED ON
              CONTRACT, TORT, NEGLIGENCE, STRICT LIABILITY, OR OTHER LEGAL
              THEORY, EVEN IF WE HAVE BEEN ADVISED OF THE POSSIBILITY OF SUCH
              DAMAGES. IN ADDITION, IN NO EVENT SHALL WE OR OUR AFFILIATES BE
              LIABLE FOR ANY DAMAGES ARISING OUT OF YOUR USE OF THE FESTIV
              WEBSITE. IN THE EVENT SOME JURISDICTIONS DO NOT ALLOW THE
              EXCLUSION OR LIMITATION OF DAMAGES TO THE EXTENT INDICATED ABOVE,
              OUR LIABILITY IN SUCH JURISDICTIONS SHALL BE LIMITED TO THE EXTENT
              PERMITTED BY LAW.
            </p>
          </div>
          <div className="extra-documents-small-header">INDEMNIFICATION</div>
          <div className="extra-documents-text">
            <p>
              You agree to defend, indemnify, and hold us, our affiliates,
              subsidiaries, joint ventures, third-party service providers, and
              our respective employees, contractors, agents, officers, and
              directors harmless from all liabilities, claims, and expenses
              (including reasonable attorneys&#x27; fees) that arise out of, or
              are related to, any User-Provided Content you submit, post,
              transmit, or make available through the Festiv Website, your
              violation of these Terms of Use, your use or misuse of the Festiv
              Website, or your violation of any third-party rights.
            </p>
          </div>
          <div className="extra-documents-small-header">
            <h2>CHOICE OF LAW AND JURISDICTION</h2>
          </div>
          <div className="extra-documents-text">
            <p>
              You agree that these Terms of Use will be governed by and
              construed in accordance with the laws of the State of California
              without regard to the conflicts of laws principles.
            </p>
          </div>
          <div className="extra-documents-small-header">
            <h2>DISPUTE BY BINDING ARBITRATION WITH CLASS ACTION WAIVER</h2>
          </div>
          <div className="extra-documents-text">
            <p>
              Any dispute or claim arising from or related to these Terms of Use
              or Festiv’s websites is subject to binding arbitration. By using
              the Festiv Website, you agree to be bound by those terms.
            </p>
          </div>
          <div className="extra-documents-small-header">EQUITABLE RELIEF</div>
          <div className="extra-documents-text">
            <p>
              You acknowledge that any breach or threatened breach of these
              Terms of Use will result in irreparable harm for which damages
              would not be an adequate remedy, and, therefore, in addition to
              our rights and remedies otherwise available at law, we shall be
              entitled to seek immediate equitable relief, including injunctive
              relief, as appropriate. If we seek any equitable remedies, we
              shall not be precluded or prevented from seeking remedies at law,
              nor shall we be deemed to have made an election of remedies.
            </p>
          </div>
          <div className="extra-documents-small-header">
            <h2>SEVERABILITY</h2>
          </div>
          <div className="extra-documents-text">
            <p>
              If any provision of these Terms of Use is held unenforceable or
              invalid under any applicable law or is so held by an applicable
              court decision, such unenforceability or invalidity will not
              render these Terms of Use unenforceable or invalid as a whole, and
              such provision will be changed and interpreted so as to best
              accomplish the objectives of such unenforceable or invalid
              provision within the limits of applicable law or the applicable
              court decisions.
            </p>
          </div>
          <div className="extra-documents-small-header">
            <h2>WAIVER</h2>
          </div>
          <div className="extra-documents-text">
            <p>
              Any waiver by us of a breach of any provision of these Terms of
              Use shall not operate as or be construed to be a waiver of any
              other breach of such provision or of any breach of any other
              provision of these Terms of Use. Any such waiver must be in
              writing. Failure by us to insist upon strict adherence to any term
              of these Terms of Use on one or more occasions shall not be
              considered a waiver or deprive us of the right to insist upon
              strict adherence to that term or any other term of these Terms of
              Use in the future.
            </p>
          </div>
          <div className="extra-documents-small-header">
            <h2>CONTACT US</h2>
          </div>
          <div className="extra-documents-text">
            <p>
              If you have any questions regarding these Terms of Use, please
              contact us at{" "}
              <a href="mailto:info@Festiv.com">info@festivmedia.com</a>.
            </p>
            <p>Last Updated: January 23, 2021</p>
          </div>
        </div>
      </div>
      <Footer />
    </div>
  );
}
