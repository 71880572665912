import React from "react";
import { BACKEND_URL } from "../constants/Strings";

// this component is used in the landing page to render all available featured films to everyone. However they cannot be viewed unless you are
// a subscriber
export default function LandingPageFilmEntry({
  id,
  title,
  year,
  synopsis,
  runtime,
  language,
  country,
  poster,
}) {
  return (
    <div className="film-entry">
      <div id="ivote-film" className="movie-div-wrapper">
        <div className="movie-description ivote-description">
          <div className="div-block-19">
            <div className="div-block-18">
              <div className="w-layout-grid grid-10">
                <div
                  id="w-node-a70104d7-a716-68ce-90a1-ef6ffb6b3c42-aa9849fb"
                  className="div-block-20"
                >
                  <h5 className="movie-description-grid-title we-the-children-title">
                    {title}
                  </h5>
                  <p className="movie-description-grid-year wtf-year">{year}</p>
                </div>
              </div>
            </div>
          </div>
          <div className="div-block-21">
            <h6 className="movie-description-grid-description wth-desc">
              {synopsis}
            </h6>
            <p className="movie-description-grid-stats we-the-children-time">
              {runtime} min | {language} | {country}
            </p>
          </div>
        </div>
        <img
          src={`${BACKEND_URL}storage/images/${poster}`}
          //   srcSet="images/ivote-keyart-vertical-hq-p-500.png 500w, images/ivote-keyart-vertical-hq.png 738w"
          sizes="(max-width: 479px) 135px, 200px"
          alt={title}
          className="movie-image"
        />
      </div>
      <div className="film-grid-card-title">
        <h3 className="text-block-3">{title}</h3>
      </div>
    </div>
  );
}
