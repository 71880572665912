
import jwtDecode from "jwt-decode";
import React, { useEffect, useState } from "react";
import { useHistory } from "react-router";
import { checkEmailIsAdmin } from "../../util/adminRequest";


const AdminProtectedRoute = (props) => {
  const navigate = useHistory();
  const [isLoggedIn, setIsLoggedIn] = useState(false);

  function redirectToHome() {
    navigate.push({
      pathname: '/sign-in'
    });

  }
  const checkUserToken = async () => {
    const userToken = localStorage.getItem('FBIdToken');
    if (!userToken || userToken === 'undefined') {
      setIsLoggedIn(false);
      redirectToHome();
    }
    else {
      const decodedToken = jwtDecode(userToken);
      if (decodedToken.exp * 1000 < Date.now()) {
        // window.location.href = "/sign-in"
        setIsLoggedIn(false);
        redirectToHome();
      }
      else
        checkEmailIsAdmin().then(isAdmin => {
          if (isAdmin)
            setIsLoggedIn(true);
          else {
            setIsLoggedIn(false);
            redirectToHome();
          }

        });

    }

  }

  useEffect(() => {
    checkUserToken();
  }, []);



  return (
    <React.Fragment>
      {
        isLoggedIn ? props.children : null
      }
    </React.Fragment>
  );
}
export default AdminProtectedRoute;