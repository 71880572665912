import React, { useEffect } from "react";
import SignedInNavbar from "../components/navbar/signed-in-navbar";
import Footer from "../components/footer";

export default function Ivote() {
  useEffect(() => {
    // inject popup script
    const script = document.createElement("script");
    script.src = "js/ivote-popup.js";
    script.async = true;
    document.body.appendChild(script);

    let shareButton = document.getElementById("screening-room-share");
    shareButton.href =
      "mailto:?subject=iVote%20(2012)&body=https://festivmedia.com/screening-room-ivote";

    window.mobileAndTabletCheck = function () {
      let check = false;
      (function (a) {
        if (
          /(android|bb\d+|meego).+mobile|avantgo|bada\/|blackberry|blazer|compal|elaine|fennec|hiptop|iemobile|ip(hone|od)|iris|kindle|lge |maemo|midp|mmp|mobile.+firefox|netfront|opera m(ob|in)i|palm( os)?|phone|p(ixi|re)\/|plucker|pocket|psp|series(4|6)0|symbian|treo|up\.(browser|link)|vodafone|wap|windows ce|xda|xiino|android|ipad|playbook|silk/i.test(
            a
          ) ||
          /1207|6310|6590|3gso|4thp|50[1-6]i|770s|802s|a wa|abac|ac(er|oo|s\-)|ai(ko|rn)|al(av|ca|co)|amoi|an(ex|ny|yw)|aptu|ar(ch|go)|as(te|us)|attw|au(di|\-m|r |s )|avan|be(ck|ll|nq)|bi(lb|rd)|bl(ac|az)|br(e|v)w|bumb|bw\-(n|u)|c55\/|capi|ccwa|cdm\-|cell|chtm|cldc|cmd\-|co(mp|nd)|craw|da(it|ll|ng)|dbte|dc\-s|devi|dica|dmob|do(c|p)o|ds(12|\-d)|el(49|ai)|em(l2|ul)|er(ic|k0)|esl8|ez([4-7]0|os|wa|ze)|fetc|fly(\-|_)|g1 u|g560|gene|gf\-5|g\-mo|go(\.w|od)|gr(ad|un)|haie|hcit|hd\-(m|p|t)|hei\-|hi(pt|ta)|hp( i|ip)|hs\-c|ht(c(\-| |_|a|g|p|s|t)|tp)|hu(aw|tc)|i\-(20|go|ma)|i230|iac( |\-|\/)|ibro|idea|ig01|ikom|im1k|inno|ipaq|iris|ja(t|v)a|jbro|jemu|jigs|kddi|keji|kgt( |\/)|klon|kpt |kwc\-|kyo(c|k)|le(no|xi)|lg( g|\/(k|l|u)|50|54|\-[a-w])|libw|lynx|m1\-w|m3ga|m50\/|ma(te|ui|xo)|mc(01|21|ca)|m\-cr|me(rc|ri)|mi(o8|oa|ts)|mmef|mo(01|02|bi|de|do|t(\-| |o|v)|zz)|mt(50|p1|v )|mwbp|mywa|n10[0-2]|n20[2-3]|n30(0|2)|n50(0|2|5)|n7(0(0|1)|10)|ne((c|m)\-|on|tf|wf|wg|wt)|nok(6|i)|nzph|o2im|op(ti|wv)|oran|owg1|p800|pan(a|d|t)|pdxg|pg(13|\-([1-8]|c))|phil|pire|pl(ay|uc)|pn\-2|po(ck|rt|se)|prox|psio|pt\-g|qa\-a|qc(07|12|21|32|60|\-[2-7]|i\-)|qtek|r380|r600|raks|rim9|ro(ve|zo)|s55\/|sa(ge|ma|mm|ms|ny|va)|sc(01|h\-|oo|p\-)|sdk\/|se(c(\-|0|1)|47|mc|nd|ri)|sgh\-|shar|sie(\-|m)|sk\-0|sl(45|id)|sm(al|ar|b3|it|t5)|so(ft|ny)|sp(01|h\-|v\-|v )|sy(01|mb)|t2(18|50)|t6(00|10|18)|ta(gt|lk)|tcl\-|tdg\-|tel(i|m)|tim\-|t\-mo|to(pl|sh)|ts(70|m\-|m3|m5)|tx\-9|up(\.b|g1|si)|utst|v400|v750|veri|vi(rg|te)|vk(40|5[0-3]|\-v)|vm40|voda|vulc|vx(52|53|60|61|70|80|81|83|85|98)|w3c(\-| )|webc|whit|wi(g |nc|nw)|wmlb|wonu|x700|yas\-|your|zeto|zte\-/i.test(
            a.substr(0, 4)
          )
        )
          check = true;
      })(navigator.userAgent || navigator.vendor || window.opera);
      return check;
    };
    let btn = document.getElementById("screening-room-play-button");
    let fillerImage = document.getElementById("filler-image");
    let videoWrapper = document.getElementById("video-wrapper");
    function swapInVideo() {
      console.log("event triggered");
      btn.style.opacity = "0";
      setTimeout(function () {
        btn.style.display = "none";
      }, 550);
      var video = document.createElement("video");
      video.style.position = "relative";
      video.style.outline = "0";
      if (window.mobileAndTabletCheck()) {
        video.style.width = "90%";
        video.style.height = "100%";
      } else {
        if (window.screen.width < 1400) {
          video.style.width = "Auto";
          video.style.height = "480px";
        } else {
          video.style.width = "Auto";
          video.style.height = "600px";
        }
      }
      video.src = "https://festivfilms.s3.us-east-2.amazonaws.com/iVote.mp4";
      video.autoplay = "autoplay";
      video.setAttribute("controls", "controls");
      fillerImage.style.opacity = "0";
      setTimeout(function () {
        fillerImage.style.display = "none";
        videoWrapper.appendChild(video);
      }, 550);
      let elem = document.getElementsByClassName("div-block-57")[0];
      elem.remove();
    }
    btn.addEventListener("click", swapInVideo);
  });

  return (
    <div>
      <SignedInNavbar />
      <div className="section-3">
        <div id="video-wrapper" className="div-block-47">
          <img
            src="images/ivote-keyart-horizontal-hq.png"
            id="filler-image"
            sizes="(max-width: 991px) 90vw, (max-width: 1439px) 70vw, 900px"
            srcSet="images/ivote-keyart-horizontal-hq-p-500.png 500w, images/ivote-keyart-horizontal-hq-p-800.png 800w, images/ivote-keyart-horizontal-hq.png 1080w"
            alt=""
            className="image-35"
          />
          <div className="div-block-57">
            <img
              src="images/screening-room-play-button.png"
              width="104"
              id="screening-room-play-button"
              alt=""
              className="image-31"
            />
          </div>
        </div>
      </div>
      <div className="film-description-section">
        <div className="w-layout-grid grid-16">
          <div>
            <div className="w-layout-grid grid-17">
              <img
                src="images/ivote-keyart-vertical-hq.png"
                width="140"
                sizes="(max-width: 767px) 180px, 220px"
                srcSet="images/ivote-keyart-vertical-hq-p-500.png 500w, images/ivote-keyart-vertical-hq.png 738w"
                alt=""
                className="image-24"
              />
              <div className="div-block-38">
                <div className="text-block-25">
                  <h2>IVOTE</h2>
                </div>
                <div className="text-block-26 ivote-year-sc-room">
                  <h3>2012</h3>
                </div>
                <div className="text-block-27">
                  <p>
                    Academy Award nominee Eric Roberts (The Dark Knight), Dan
                    Lauria (The Wonder Years, Sullivan &amp; Son) &amp; Emmy
                    nominee Sharon Lawrence (NYPD Blue, Desperate Housewives)
                    star in &quot;iVote,&#x27; the true story of a typist who
                    exposes the sins of his own employer, all in the name of
                    democracy. During George W. Bush&#x27;s re-election
                    campaign, Steve is faced with a dilemma when he discovers
                    that the company he works for helped to hide that the
                    e-voting machines were fraudulent. &quot;iVote&quot; reveals
                    the personal sacrifice and courage it takes to serve the
                    greater good.
                  </p>
                </div>
                <div className="text-block-28">
                  <p>21 min  |  English  |  USA</p>
                </div>
              </div>
            </div>
          </div>
          <div className="div-block-34">
            <div className="text-block-24">
              <p>16,763 Views</p>
            </div>
            <img
              src="images/martini-rating-icons-no-mouse.png"
              width="117"
              sizes="(max-width: 991px) 100vw, (max-width: 1279px) 117px, 100vw"
              srcSet="images/martini-rating-icons-no-mouse-p-500.png 500w, images/martini-rating-icons-no-mouse-p-800.png 800w, images/martini-rating-icons-no-mouse.png 1140w"
              alt=""
              className="image-20"
            />
            <div
              data-w-id="fa1a8e90-3993-fe9c-0795-b2d328357eb1"
              style={{ opacity: "0" }}
              className="rate-this-film-popup"
            >
              <div className="text-block-31">
                <p>RATE THIS FILM</p>
              </div>
              <div className="div-block-44"></div>
              <div
                data-w-id="93e5ac38-5949-ee7f-7d86-27038b638bbc"
                className="text-block-32"
              >
                {" "}
                X{" "}
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="html-embed w-embed w-script"></div>
      <div className="cast-and-crew-section">
        <div className="cast-and-crew-title">
          <h1>CAST &amp; CREW</h1>
        </div>
        <div className="film-members-line">
          <div className="text-block-18">
            <h2>DIRECTORS:</h2>{" "}
          </div>
          <div className="actor-hyperlink adler-link-one">
            <h3>ROB ADLER</h3>
          </div>
        </div>
        <div className="film-members-line">
          <div className="text-block-18">
            <h2>WRITERS:</h2>
          </div>
          <div className="actor-hyperlink adler-link-two">
            <h3>ROB ADLER</h3>
          </div>
        </div>
        <div className="film-members-line">
          <div className="text-block-18">
            <h2>STARS:</h2>
          </div>
          <div className="actor-hyperlink roberts-link">
            <h3>ERIC ROBERTS</h3>
          </div>
          <div className="text-block-19">,</div>
          <div className="actor-hyperlink lauria-link">
            <h3>DAN LAURIA</h3>
          </div>
          <div className="text-block-19">,</div>
          <div className="actor-hyperlink lawrence-link">
            <h3>SHARON LAWRENCE</h3>
          </div>
          <div className="text-block-19 cast-and-crew-line"> | </div>
          <a
            href="https://www.imdb.com/title/tt2402308/"
            target="_blank"
            className="w-inline-block"
          >
            <div className="actor-hyperlink">
              <p>SEE FULL CAST &amp; CREW &gt;&gt;</p>
            </div>
          </a>
        </div>
        <div className="share-film-wrapper">
          <a
            href="mailto:ivote.film@gmail.com?subject=Inquiry%20About%20iVote%20(2012)"
            id="screening-room-contact"
            className="contact-filmmaker-button w-button"
          >
            <p>CONTACT FILMMAKER</p>
          </a>
          <a
            href="#"
            id="screening-room-share"
            className="share-film-button w-button"
          >
            <p>SHARE FILM</p>
          </a>
        </div>
        <img
          src="images/eric-roberts-headshot.png"
          width="68"
          sizes="100vw"
          srcSet="images/eric-roberts-headshot-p-500.png 500w, images/eric-roberts-headshot.png 785w"
          alt=""
          className="image-26"
        />
        <img
          src="images/dan-lauria-headshot.png"
          width="68"
          sizes="100vw"
          srcSet="images/dan-lauria-headshot-p-500.png 500w, images/dan-lauria-headshot.png 785w"
          alt=""
          className="image-27"
        />
        <img
          src="images/sharon-lawrence-headshot.png"
          width="68"
          sizes="100vw"
          srcSet="images/sharon-lawrence-headshot-p-500.png 500w, images/sharon-lawrence-headshot.png 785w"
          alt=""
          className="image-28"
        />
        <div id="follow-me" className="follow-me-card">
          <div className="w-layout-grid grid-14">
            <img
              src="images/rob-adler-headshot.png"
              width="103"
              id="profile-picture"
              alt=""
              className="image-23"
            />
            <div className="div-block-35">
              <div className="div-block-37"></div>
              <div id="follow-me-name" className="text-block-30">
                <h3>ROB ADLER</h3>
              </div>
              <div className="div-block-48">
                <div className="text-block-29">
                  <p>FOLLOW ME</p>
                </div>
              </div>
              <div className="div-block-36">
                <div className="div-block-42">
                  <a
                    id="imdb-link"
                    href="https://www.imdb.com/name/nm0012228/?ref_=fn_al_nm_1"
                    target="_blank"
                    className="link-block-8 w-inline-block"
                  >
                    <img
                      src="images/imdb-icon.png"
                      width="45"
                      sizes="(max-width: 1279px) 45px, (max-width: 1919px) 100vw, 45px"
                      srcSet="images/imdb-icon-p-500.png 500w, images/imdb-icon-p-800.png 800w, images/imdb-icon.png 1200w"
                      alt=""
                      className="image-22"
                    />
                  </a>
                  <a
                    href="#"
                    target="_blank"
                    className="follow-me-links w-inline-block"
                  >
                    <img src="images/blue-instagram-icon.png" alt="" />
                  </a>
                  <a href="#" className="follow-me-links w-inline-block">
                    <img src="images/blue-facebook-icon.png" alt="" />
                  </a>
                  <a
                    href="#"
                    target="_blank"
                    className="follow-me-links w-inline-block"
                  >
                    <img src="images/blue-twitter-icon.png" alt="" />
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="share-film-button-code w-embed w-script"></div>
        <div className="code-for-popup w-embed w-script"></div>
      </div>
      <Footer />
    </div>
  );
}
