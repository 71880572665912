import React, { useState, useEffect, useRef, useMemo } from "react";
import FormInput from "../form-input";
// import FileUpload from "./file-upload";
import { postToLaravel } from "../../util/apitest";
import { v4 as uuidv4 } from "uuid";
import UploadVideo from "../upload-video";
import "../../components/form.css";
import SignedOutNavbar from "../../components/navbar/signed-out-navbar";
import Footer from "../../components/footer";
import Dropdown from "../dropdown-list";
import Modal from "./formModal";
import { Upload } from "@aws-sdk/lib-storage";
import { S3Client, S3, PutObjectCommand } from "@aws-sdk/client-s3";
import { uploadFile } from "../../util/uploadFile";
import { regex } from "uuidv4";
import { getDisplayServerError } from "../../util/api-error-messages";
import { useLocation } from "react-router-dom";
import AdminFormModal from "./formModal";
import SignedInNavbar from "../../components/navbar/signed-in-navbar";
import AdminProtectedRoute from "./adminProtectedRotue";
import AppNavBar from "../../components/navbar/get-navbar";

// import axios from "axios";

const S3_BUCKET = "festiv-films"; // The name of the festiv media bucket on AWS
const REGION = "us-east-2"; // The region the bucket was created with
// AWS.config.update({
//   accessKeyId: "AKIAQOPTG43WUDCSZU6Z",
//   secretAccessKey: "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",
// });
// const myBucket = new AWS.S3({
//   params: { Bucket: S3_BUCKET },
//   region: REGION,
// });
// Renders a form where a filmaker can submit a film for approval. The video for the film is also uploaded here

//const MAX_CHUNK_SIZE =  5000000000; //Min limit 5 MB - 5GB

export default function AdminFilmSubmissionForm() {
  const location = useLocation();
  console.log(location.state);
  const { filmId, externalLink } = location.state[0];

  const [MAX_CHUNK_SIZE, setMaxChunkSize] = useState(100000000) //(5000000000);

  const [isCancelled, setIsCancelled] = useState(true);
  const [isPaid, setIsPaid] = useState(false);


  // States for the different form inputs
  const [file, setFile] = useState("");


  const [progress, setProgress] = useState(0); // progress of upload
  const [selectedFile, setSelectedFile] = useState(null); // file to be uploaded
  const [id, setId] = useState("");
  const [show, setShow] = useState(false);
  const [success, setSuccess] = useState("Submitting Form...");
  const [error, setError] = useState();

  const inputRef = useRef(null);

  useEffect(() => {
    var result = "";
    var characters =
      "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";
    var charactersLength = characters.length;
    for (var i = 0; i < 5; i++) {
      result += characters.charAt(Math.floor(Math.random() * charactersLength));
      setId(result);
    }
  }, []);

  const showModal = () => {
    setShow(true);
  };

  const hideModal = () => {
    setShow(false);
  };

  const handleFileInput = (e) => {
    const name = e.target.files[0].name;
    const newName = /\s/.test(name) ? name.replaceAll(" ", "_") : name;
    setSelectedFile(e.target.files[0]);
    console.log(id + "-" + newName);
    getFile(id + "-" + newName); // get the file name and store it


    const fileSize = e.target.files[0].size

    let avgSize
    let mb = 1024 * 1024

    if (fileSize > 1000 * mb * 100)
      avgSize = Math.floor(fileSize / 200) // fileSize / 200
    else if (fileSize / 100 > 100 * mb)
      avgSize = Math.floor(fileSize / 100) // 100 * mb
    else if (fileSize / 100 > 10 * mb)
      avgSize = Math.floor(fileSize / 100)// 10 * mb
    else if (fileSize / 50 > 10 * mb)
      avgSize = Math.floor(fileSize / 50) //10 * mb
    else
      avgSize = 5 * mb

    // splits to 100 > segments
    // if (fileSize > 1000 * mb * 100)
    //   avgSize = Math.floor(fileSize / 200)
    // else if (fileSize / 100 > 100 * mb)
    //   avgSize = 100 * mb
    // else if (fileSize / 100 > 10 * mb)
    //   avgSize = 10 * mb
    // else if (fileSize / 50 > 10 * mb)
    //   avgSize = 10 * mb
    // else
    //   avgSize = 5 * mb


    setMaxChunkSize(avgSize ?? 200000000)
    console.log('Max segment size', MAX_CHUNK_SIZE)
    console.log('File size', fileSize)
    // console.log('Number segments', fileSize / MAX_CHUNK_SIZE)
    // const fileSize = e.target.files[0].size
    // if (fileSize> MAX_CHUNK_SIZE * 10)
    // setMaxChunkSize(MAX_CHUNK_SIZE * 10)

    // if (fileSize > MAX_CHUNK_SIZE * 100)
    //   setMaxChunkSize(MAX_CHUNK_SIZE * 100)

    // if (fileSize > MAX_CHUNK_SIZE * 1000)
    //   setMaxChunkSize(MAX_CHUNK_SIZE * 1000)
  };

  const resetFileInput = () => {
    // 👇️ reset input value
    inputRef.current.value = null;
    setFile("");
  };



  const uploadFile = async (file) => {
    setError(undefined);

    console.log('Attempting File Upload...')
    const name = file.name;
    const newName = /\s/.test(name) ? name.replaceAll(" ", "_") : name;
    const key = id + "-" + newName;
    const target = { Bucket: S3_BUCKET, Key: key, Body: file };
    const credentials = {
      accessKeyId: "AKIAQOPTG43WUDCSZU6Z",
      secretAccessKey: "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",
    };


    // Segment file
    const fileSize = file.size
    const totalCount = fileSize % MAX_CHUNK_SIZE === 0 ? fileSize / MAX_CHUNK_SIZE : Math.floor(fileSize / MAX_CHUNK_SIZE) + 1; // Total count

    const uploadParts = [];
    const fileSegments = [];

    for (let i = 1; i <= totalCount; i++) {
      const endLength = i * MAX_CHUNK_SIZE > fileSize ? fileSize : i * MAX_CHUNK_SIZE

      fileSegments.push(file.slice((i - 1) * MAX_CHUNK_SIZE, endLength));
    }


    try {
      // Old working upload Method

      // const parallelUploads3 = new Upload({
      //   client: new S3Client({ region: REGION, credentials: credentials }),
      //   params: target,
      //   leavePartsOnError: false, // optional manually handle dropped parts
      //   // abortController : () =>{},

      // });

      // parallelUploads3.on("httpUploadProgress", (progress) => {
      //   console.log(progress);
      //   setProgress(Math.round((progress.loaded / progress.total) * 100));
      // });

      // parallelUploads3.done();



      // New Upload

      const abortController = new AbortController();

      const client = new S3({
        region: REGION,
        credentials: credentials,
        correctClockSkew: true, // whether to apply a clock skew correction and retry requests that fail because of an skewed client clock. Defaults to false.
        //systemClockOffset : getTomeZoneOffset(), //an offset value in milliseconds to apply to all signing times. Use this to compensate for clock skew when your system may be out of sync with the service time.
        httpOptions: {
          xhrAsync: false, //Set to false to send requests synchronously. Defaults to true (async on)
          timeout: 600000, //Sets the socket to timeout after timeout milliseconds of inactivity on the socket. Defaults to two minutes (120000).

        }
      });





      const bucketParams = { Bucket: S3_BUCKET, Key: key }; //, Body: file };


      // Initates the upload process returning {Bucket, key, uploadID}
      client.createMultipartUpload(bucketParams, async (err, data) => {
        // console.log('Data', data)
        if (err) {
          console.log('Error', err)
          setError('Failed to Establish connection to upload File \n Retrying....')

          // setError(prevState => `${prevState} \n ${err.name}: ${err.message}`);
        }
        else if (data) {

          console.log(' Success initiate Upload', data)


          // for each segment upload 


          const uploadTracker = {
            totalSegmentsSent: 0,
            freeSegments: 10
          }



          const uploadPartParams = {
            // Bucket: 'STRING_VALUE', /* required */
            // Key: 'STRING_VALUE', /* required */
            // PartNumber: 'NUMBER_VALUE', /* required */
            // UploadId: 'STRING_VALUE', /* required */
            ...bucketParams,
            Key: bucketParams.Key,
            Bucket: bucketParams.Bucket,
            Body: undefined,
            UploadId: data?.UploadId,
            // PartNumber: 1,
          }
          console.log(`File size ${fileSize}\n, total Segments: ${totalCount} \n ChunkSize : ${MAX_CHUNK_SIZE} \n\n\n`)

          //Custom Upload Fn
          const callUpload = (currPartNumber) => {
            console.log(`Calling Initial Part Upload for part #${currPartNumber}`)
            //Reducer free request
            uploadTracker['freeSegments'] -= 1
            uploadTracker['totalSegmentsSent'] += 1 //increase # of segments sent

            uploadPartParams['Body'] = fileSegments[currPartNumber - 1];
            uploadPartParams['PartNumber'] = currPartNumber;
            // console.log('Upload Part Params', uploadPartParams)

            client.uploadPart(uploadPartParams, (error, data, currPartNo = currPartNumber) => {
              if (error) {
                console.log('Upload Error', error)
                setError(`Upload Error : ${error.message === 'Failed to fetch' ? 'Failed to upload' : error.message}`)
                // setError('Upload Error: failed to upload file')

                // setError(prevState => `${prevState} \n ${error.name}: ${error.message}`);
                if (!isCancelled)
                  client.abortMultipartUpload(uploadPartParams, (err, data) => {
                    if (err) {
                      // console.log('Failed to Cancel Upload')
                      // setError(prevState => `Failed to  Upload Segment: \n ${err?.message}`);
                      setError(`Failed to Upload Video Parts`);
                    }

                    console.log('Cancelled Upload')
                    // setError("File Upload Cancelled: Failed to upload")
                    setIsCancelled(true)
                  })
                return
              }

              if (uploadParts.length <= totalCount - 1 || totalCount === 1)
                setProgress(totalCount === 1 ? 99 : Math.round(MAX_CHUNK_SIZE * uploadParts.length / fileSize * 100));

              console.log(`Successfully uploaded segment #${currPartNo}`, data)


              uploadParts.push({ ETag: data?.ETag, PartNumber: currPartNo });
              uploadTracker['freeSegments'] += 1

              if (uploadTracker['totalSegmentsSent'] < totalCount)
                callUpload(uploadTracker['totalSegmentsSent'] + 1)
              // trigger complete ?
              else if (uploadParts.length === totalCount) {
                const sortedParts = uploadParts.sort((a, b) => a.PartNumber - b.PartNumber);

                const completeParams = {
                  Bucket: bucketParams.Bucket, /* required */
                  Key: bucketParams.Key, /* required */
                  UploadId: uploadPartParams?.UploadId, /* required */
                  MultipartUpload: {
                    Parts: sortedParts//[{ ETag: data?.ETag, PartNumber: 1 }] // array of Completed Part 
                  }
                }

                console.log('Complete Params', completeParams)
                setTimeout(() => client.completeMultipartUpload(completeParams, (err, data) => {
                  if (err) {
                    console.log(err, err.stack); // an error occurred
                    // setError("Failed to complete")

                    // setError(prevState => `Failed to Complete Upload \n ${err?.message}`);
                    setError(prevState => `Failed to Complete Video Upload `);
                  }
                  else {
                    console.log('Upload Complete Response', data);           // successful response
                    setProgress(100);
                    updateFilmDB(key);
                  }
                  /*
                  data = {
                   Bucket: "acexamplebucket", 
                   ETag: "\"4d9031c7644d8081c2829f4ea23c55f7-2\"", 
                   Key: "bigobject", 
                   Location: "https://examplebucket.s3.<Region>.amazonaws.com/bigobject"
                  }
                  */
                }), 3000);


              }

            })// end of uploadPart ( )
          }
          // uploadFirst 10
          const initialUploads = totalCount < 10 ? totalCount : 10
          for (let i = 1; i <= initialUploads; i++) {

            callUpload(i)

          } //eND OF UPLOAD FOR LOOP 




        } // end of success createMultipartUpload

      }); // end of createMultiUpload

    }
    catch (error) {
      console.log(error)
      setError('Upload Request Failed')
      // const { requestId, cfId, extendedRequestId } = error.$$metadata;
      // console.log('File Upload Error', { requestId, cfId, extendedRequestId });
      /**
       * The keys within exceptions are also parsed.
       * You can access them by specifying exception names:
       * if (error.name === 'SomeServiceException') {
       *     const value = error.specialKeyInException;
       * }
       */
    }

    // const params = {
    //   ACL: "public-read",
    //   Body: file,
    //   Bucket: S3_BUCKET,
    //   Key: id + "-" + newName,
    // };

    // /**
    //  * Adds an object to the bucket. You must have write permissions on a bucket to
    //  * add an object to it. Amazon S3 never adds partial objects; if you receive
    //  * a success response, Amazon S3 added the entire object to the bucket.
    //  */
    // myBucket
    //   .putObject(params)
    //   .on("httpUploadProgress", (evt) => {
    //     setProgress(Math.round((evt.loaded / evt.total) * 100)); // Sets the progress dynamically
    //   })
    //   .send((err) => {
    //     if (err) console.log(err);
    //   });
  }
    ;



  const handleSubmit = async (e) => {
    e.preventDefault();
    setError(undefined);

    if (file === "") {
      let msg = "Please select your video file or enter video url.";
      alert(msg);
      return false;
    }

    showModal();

    // store the states in the form data
    const formData = new FormData();
    formData.append("film_file", file);

    try {
      // uploadFile(selectedFile);

      // make axios post request
      // console.log('FormData Request', formData)

      uploadFile(selectedFile);

      //End of try request
    } catch (error) {
      // setError("Failed to Submit, Try again later");
      if (error?.response)
        setError(await Promise.resolve(getDisplayServerError(error?.response?.data ?? '')))
      // else if (error?.message?.includes('undefined'))
      //   setError('Please resubmit using a unique email')
      else
        // setError(`Failed to Submit: ${error?.response}`);
        setError(`Failed to Submit: Please Refresh or Try Again`);
      console.log(error, error?.message, error?.response);
    }
  };


  const getFile = (fileName) => {
    setFile(fileName);
    console.log(fileName);
  };


  // function handleChange(e) {
  //   setImage(e.target.files[0]);
  // }

  // return  post(url, formData)
  //         .then(response => console.log(response))

  async function updateFilmDB(file_name) {

    // store the states in the form data
    const formData = new FormData();

    formData.append("id", filmId);
    formData.append("film_file", file_name);
    // formData.append("film_file", file);

    try {
      const filmResponse = postToLaravel("api/film/update", formData);

      console.log(`Film Logging `, filmResponse)

      if ((await filmResponse)?.status === 200) {

        setSuccess('Form Submitted Successfully');
      }
      else
        setError('Failed to update Film DB ');

    }
    catch (e) {
      console.log(e);
      if (error?.response)
        setError(await Promise.resolve(getDisplayServerError(error?.response?.data ?? '')))
      else
        setError(`Failed to Submit: Please Refresh or Try Again`);
      console.log(error, error?.message, error?.response);
    }

  }

  // const Navbar = useMemo( () => <AppNavBar /> , []);
  return (

    <AdminProtectedRoute >
      {/* <div>
        {Navbar}
      </div> */}
      <div>
        <SignedInNavbar />
      </div>

      <header className="header " style={{marginTop : '85px'}}>
        <h1 className="header-heading">Festiv - Admin Film Submission Form</h1>
        {/* <h4>
          Welcome! We are excited to showcase your amazing content on Festiv! On
          Festiv you can:
        </h4>
        <div>
          <ul>
            <li>
              Connect with Distributors, Filmakers, and Content Creators from
              around the world!
            </li>
            <li>Enjoy Unlimited Screenings for a Global Audience!</li>
            <li>Get Marketing and Promotional Services for your Content!</li>
            <li>Find out about upcoming virtual and live industry events</li>
            <li>New Films are Announced and Awarded each Month!</li>
            <li>Submission fees are discounted for a limited time! <br />{"("}Feature Films <s>$225</s>  $125, Short Film or TV Pilot <s>$129</s>  $75, Student Film <s>$85</s>  $47.50{")"} <br /> All types Free with Valid Promo Code!</li>
          </ul>
        </div>
        <p>
          By submitting this form, you affirm that you have read and agreed to
          Festiv's Film Submission Guidelines:{" "}
          <a href="/film-submission-guidelines.html">
            Festiv's Film Submission Guidelines
          </a>
        </p> */}
        <div>
          <ul>
            <li className="flex flex-row">
              <b>External Link </b>:
              {externalLink == null ? <span> No External Link provided. </span>
                : <span><a href={`${externalLink}`} target="_blank" rel="noreferrer"> {externalLink} </a></span>
              }
            </li>
          </ul>
        </div>
      </header>
      <form encType="multipart/form-data" className="form-style">
        <div>
          <h2 className="film-information-header">Film Information</h2>
        </div>
        {/* <strong>
          <em style={{ color: "red" }}>The (*) marks required fields.</em>
        </strong> */}


        <UploadVideo
          resetFileInput={resetFileInput}
          inputRef={inputRef}
          handleFileInput={handleFileInput}
          progress={progress}
        />


        <AdminFormModal
          show={show}
          handleClose={hideModal}
          formSuccess={success}
          formError={error}
          progress={progress}
        />
        <div className="button-wrapper">
          <button onClick={handleSubmit} className="form-button">
            Submit Film
          </button>
        </div>
      </form>
      <Footer />
    </AdminProtectedRoute  >
  );
}
