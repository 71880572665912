import React, { useState } from "react";

// This component handles everything related to choosing a video file to uploading the video file
// while showing the progress
const UploadVideo = ({ handleFileInput, resetFileInput, inputRef }) => {
  const [isHovering, setIsHovering] = useState(false);
  // Returns the interfcae to choose a file, upload the file and view the progress of the upload.
  return (
    <div className="form-input">
      <label className="form-input-label">Choose Film(Video File)*</label>

      <div
        className="input-styling "
        onTouchEnd={(e) => setIsHovering(false)}
        onTouchStart={(e) => setIsHovering(true)}
        onMouseOut={(e) => setIsHovering(false)}
        onMouseOver={(e) => setIsHovering(true)}
      >
        {/* <div>
      Your upload must be an mp4, mov or avi file that is <b>no larger than 2GB.</b> <br/>  
      Avoid special characters in file name. (Spaces allowed)<br/>
      Use capitals and underscores instead of periods, slashes or other special characters.
      </div> */}
        <div
          className={`tooltiptext ${isHovering ? "show" : ""}`}
          style={{ fontSize: "14px" }}
        >
          <p>
            To ensure a successful upload, please consider the following tips:
          </p>
          <ul>
            <li>
              Verify that your file is a video in the MP4, MOV, or AVI format
              and that it does not exceed a file size of 10GB.
            </li>
            <li>
              Utilize capital letters and underscores in the file name, instead
              of special characters, with the exception of spaces.
            </li>
            <li>
              If your file is large, please disable any power-saving settings on
              your computer to prevent interruption during the upload process.
            </li>
          </ul>
        </div>
        <input
          ref={inputRef}
          type="file"
          accept="video/*"
          onChange={handleFileInput}
          id="video-input"
        />
        <button onClick={resetFileInput} className="reset-file-input">
          Reset file input
        </button>
      </div>
    </div>
  );
};

export default UploadVideo;
