

function matchColumnName(text) {
  if (text === 'uuid')
    return 'uuid ';
  if (text === 'first_name')
    return 'first name';
  if (text === 'last_name')
    return 'last name';
  if (text === 'email')
    return 'email ';
  if (text === 'message')
    return 'message ';
  if (text === 'instagram')
    return 'instagram ';
  if (text === 'facebook')
    return 'facebook ';
  if (text === 'twitter')
    return 'twitter ';
  if (text === 'promo_code')
    return 'promo code';
  if (text === 'id')
    return 'id ';
  if (text === 'uuid')
    return 'uuid ';
  if (text === 'title')
    return 'title ';
  if (text === 'synopsis')
    return 'synopsis ';
  if (text === 'film_type')
    return 'film type';
  if (text === 'description')
    return 'description ';
  if (text === 'runtime')
    return 'runtime ';
  if (text === 'student_film')
    return 'student film';
  if (text === 'genre')
    return 'genre ';
  if (text === 'film_school')
    return 'film school';
  if (text === 'language')
    return 'language ';
  if (text === 'english_subbed')
    return 'english subbed';
  if (text === 'region')
    return 'region ';
  if (text === 'country')
    return 'country ';
  if (text === 'completion_year')
    return 'completion year';
  if (text === 'imdb')
    return 'imdb ';
  if (text === 'poster')
    return 'poster ';
  if (text === 'banner')
    return 'banner ';
  if (text === 'english_dubbed')
    return 'english dubbed';
  if (text === 'distributor_connect')
    return 'distributor connect';
  if (text === 'rights_agreement')
    return 'rights agreement';
  if (text === 'distribution_list')
    return 'distribution list';
  if (text === 'festival_screening')
    return 'festival screening';
  if (text === 'festival_list')
    return 'festival list';
  if (text === 'film_file')
    return 'film file';
  if (text === 'external_link')
    return 'external link';
  if (text === 'audience_type')
    return 'audience type';
  if (text === 'rights_agreement')
    return 'rights agreement';

}

export function getDisplayServerError(text) {
  const defaultMsg = 'Please Refresh and try again. (Contact the admin if error continues)'
  if (!text.includes('SQLSTATE') || text?.length === 0)
    return defaultMsg

  const pattern = /Column\s*'(\w+)'\s+([\w\s]+)\(*?/
  const regex = new RegExp(pattern, 'g')

  const matches = [...text.matchAll(regex)] 
  let res = matches[0] ?? []
  // console.log( matches)
  // console.log('Error', res)
  if ( res?.length === 0 )
    return defaultMsg
  let match1 = res[1]
  let match2 = res[2]


  //['matches whole pattern', 'first part', 'second part']
  
  const colName = matchColumnName(match1)

  return `Failed to Submit: ${colName} ${match2}`
} 