import React, { useState, useEffect } from "react";
import SignedOutNavbar from "../../components/navbar/signed-out-navbar";
import Footer from "../../components/footer";

export default function SignUp() {
  useEffect(() => {
    // Navbar Js
    let signInNavbar = document.getElementById("sign-in-navbar-button");
    let navbarImage = document.getElementById("festiv-logo-navbar");
    let navbar = document.getElementById("navbar-home-transparent");
    window.addEventListener("scroll", function () {
      // Set starting position
      if (window.pageYOffset >= 60) {
        navbar.style.backgroundColor = "rgba(255,255,255,1)";
      } else {
        navbar.style.backgroundColor = "rgba(255,255,255,0)";
      }
    });
  });

  return (
    <div className="body-2">
      <SignedOutNavbar />
      <div className="section">
        <div className="html-embed-4 w-embed w-script"></div>
        <h1 className="heading-2 select-your-plan-heading">
          SELECT YOUR MEMBERSHIP
        </h1>
        <div className="mobile-plan-title-grid">
          <div className="w-layout-grid grid-13">
            <div>
              <div className="mobile-plan-titles">DISTRIBUTOR</div>
            </div>
            <div>
              <div className="mobile-plan-titles">FILMMAKER</div>
            </div>
            <div>
              <div className="mobile-plan-titles">AUDIENCE</div>
            </div>
          </div>
        </div>
        <div className="mobile-buttons">
          <div className="w-layout-grid grid-12">
            <a
              href="#distributor-div-block"
              className="link-block-5 w-inline-block"
            >
              <div className="div-block-29"></div>
            </a>
            <a
              href="#filmmaker-div-block"
              className="link-block-4 w-inline-block"
            >
              <div className="div-block-30"></div>
            </a>
            <a
              href="#audience-div-block"
              className="link-block-3 w-inline-block"
            >
              <div className="div-block-31"></div>
            </a>
          </div>
          <div className="arrow-wrapper">
            <img
              src="/images/downfacing-arrow.png"
              width="24"
              alt="Downward facing arrow"
            />
          </div>
        </div>
        <div className="div-block-63">
          <div
            id="content-container-mobile"
            className="div-block-5 select-plan-card-wrapper-mobile"
          >
            <div className="w-layout-grid grid-2 select-membership-mobile-card-grid">
              <div
                id="distributor-div-block"
                className="plan-card-div w-node-fb576207-3e6b-c3d9-725d-24a2883a9075-ee9849ff"
              >
                <div className="plan-title-wrapper">
                  <div className="select-plan-title-text">DISTRIBUTOR</div>
                </div>
                <div className="select-plan-image">
                  <img
                    src="/images/business-image-cropped-2.png"
                    width="320"
                    sizes="(max-width: 479px) 300px, (max-width: 767px) 56vw, (max-width: 991px) 96vw, 950px"
                    srcSet="/images/business-image-cropped-2-p-500.png 500w, /images/business-image-cropped-2-p-800.png 800w, /images/business-image-cropped-2-p-1080.png 1080w, /images/business-image-cropped-2-p-2000.png 2000w, /images/business-image-cropped-2.png 2399w"
                    alt="Distributor plan image"
                    className="plan-image"
                  />
                  <div className="hidden-description">
                    <div className="plan-card-icon-wor">
                      <img
                        src="/images/distributor-icon.png"
                        alt="Distributor icon"
                        className="image-17"
                      />
                    </div>
                    <div className="plan-card-title-row">
                      <p className="text-block-20">
                        CLICK. <br />
                        SCREEN. BUY.
                      </p>
                    </div>
                    <div className="plan-card-description-row">
                      <div className="text-block-21 distributor-description">
                        <p>Exclusive access window to screen</p>
                        <p>Contact filmmakers directly</p>
                        <p>Easy brokerage, get deals done</p>
                        <p>Screen films missed at festivals</p>
                        <p>Movies from around the world</p>
                      </div>
                    </div>
                    <div className="plan-card-button-row">
                      <a
                        id="distributor-plan-button"
                        href="https://subscriptions.festivmedia.com/distributor"
                        className="select-plan-button w-button"
                      >
                        SELECT
                      </a>
                    </div>
                  </div>
                </div>
              </div>
              <div
                id="filmmaker-div-block"
                className="plan-card-div w-node-_63599ab1-c3d7-1a0d-4d0e-9d18383b2384-ee9849ff"
              >
                <div className="plan-title-wrapper">
                  <div
                    id="hidden-description-filmmaker"
                    className="select-plan-title-text"
                  >
                    FILMMAKER
                  </div>
                </div>
                <div className="select-plan-image">
                  <img
                    src="/images/filmmaker-image-cropped-2.png"
                    width="320"
                    sizes="(max-width: 479px) 300px, (max-width: 767px) 56vw, (max-width: 991px) 96vw, 950px"
                    srcSet="/images/filmmaker-image-cropped-2-p-500.png 500w, /images/filmmaker-image-cropped-2-p-800.png 800w, /images/filmmaker-image-cropped-2-p-1080.png 1080w, /images/filmmaker-image-cropped-2.png 1333w"
                    alt="Filmmaker plan image"
                    className="plan-image"
                  />
                  <div className="hidden-description">
                    <div className="plan-card-icon-wor">
                      <img
                        src="/images/filmmaker-icon.png"
                        alt="Filmmaker icon"
                        className="image-17"
                      />
                    </div>
                    <div className="plan-card-title-row">
                      <p className="text-block-20">
                        YOUR RED
                        <br />
                        CARPET AWAITS.
                      </p>
                    </div>
                    <div className="plan-card-description-row">
                      <div className="text-block-21">
                        <p>Access to top decision makers</p>
                        <p>2 months free for Cast &amp; Crew</p>
                        <p>Unlimited global screenings</p>
                        <p>Marketing support and promotion</p>
                        <p>Win cash awards</p>
                      </div>
                    </div>
                    <div className="plan-card-button-row">
                      <a
                        id="filmmaker-plan-button"
                        href="https://subscriptions.festivmedia.com/filmmaker"
                        className="select-plan-button w-button"
                      >
                        SELECT
                      </a>
                    </div>
                  </div>
                </div>
              </div>
              <div
                id="audience-div-block"
                className="plan-card-div w-node-dd6150bb-0d68-de08-ac87-43dfde75b9ee-ee9849ff"
              >
                <div className="plan-title-wrapper">
                  <div
                    id="hidden-description-audience"
                    className="select-plan-title-text"
                  >
                    AUDIENCE
                  </div>
                </div>
                <div className="select-plan-image">
                  <img
                    src="/images/new-audience-img.png"
                    width="320"
                    sizes="(max-width: 479px) 300px, (max-width: 767px) 56vw, (max-width: 991px) 96vw, 950px"
                    srcSet="/images/new-audience-img.png 500w, /images/new-audience-img.png 800w, /images/new-audience-img.png 810w"
                    alt="Audience plan image"
                    className="plan-image"
                  />
                  <div className="hidden-description">
                    <div className="plan-card-icon-wor">
                      <img
                        src="/images/audiences-icon.png"
                        alt="Audience icon"
                        className="image-17"
                      />
                    </div>
                    <div className="plan-card-title-row">
                      <p className="text-block-20">
                        SHOES OFF.
                        <br />
                        MOVIES ON.
                      </p>
                    </div>
                    <div className="plan-card-description-row">
                      <div className="text-block-21 audience-description">
                        <p>Stream from any device anytime</p>
                        <p>New films launched daily</p>
                        <p>Watch festival and student films</p>
                        <p>View films, get points, win prizes</p>
                        <p>Connect with global movie fans</p>
                      </div>
                    </div>
                    <div className="plan-card-button-row">
                      <a
                        href="https://subscriptions.festivmedia.com/audience"
                        className="select-plan-button w-button"
                      >
                        SELECT
                      </a>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </div>
  );
}
