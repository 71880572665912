import React, { useEffect } from "react";
import SignedOutNavbar from "../components/navbar/signed-out-navbar";
import Footer from "../components/footer";

export default function PrivacyPolicy() {
  return (
    <div>
      <SignedOutNavbar />

      <div>
        <div className="extra-documents-container w-container">
          <div className="extra-documents-title-wrapper">
            <div className="extra-documents-page-title">
              <h2>FESTIV PRIVACY POLICY</h2>
            </div>
          </div>
          <div className="extra-documents-text">
            <p>
              {" "}
              Festiv values the privacy of our users. This Privacy Policy
              describes the information that Festiv, and our affiliated
              companies (“Festiv,” “we,” or “us”) collect about consumers (“you”
              and “your household”) and how we use and share that information.
            </p>
          </div>
          <div className="extra-documents-small-header">
            <h2>SCOPE OF THIS PRIVACY POLICY</h2>
          </div>
          <div className="extra-documents-text">
            <p>
              This Privacy Policy applies to information collected by Festiv
              which includes: Festiv websites (<strong>“Festiv Sites”</strong>),
              Festiv’s services, products, and certain other products utilizing
              Festiv’s streaming platform or operating systems now and in the
              future (collectively, <strong>“Devices”</strong>), certain Festiv
              co-branded festivals (<strong>“Festiv Partner Festivals”</strong>
              ), Festiv’s mobile apps (including the Festiv Mobile App),
              Festiv’s advertising services, the Festiv emails and newsletters,
              and Festiv’s accounts on third-party social networking sites.Using
              our products and services, you can stream content provided by
              other companies (<strong>“Third-Party Festivals”</strong>), and
              Festiv co-branded festivals like the Festiv Partner Festivals.
              When you stream one of these festivals, you are interacting with
              that festival directly, and the festival provider and its
              analytics and advertising partners may collect information from
              your Device, including your device identifier and how you use that
              channel. This Privacy Policy does not cover the actions of these
              festival providers and their partners. For more information about
              how these festivals use your data, please see their privacy
              policies.
            </p>
          </div>
          <div className="extra-documents-small-header">
            <h2>PART I. INFORMATION COLLECTION</h2>
          </div>
          <div className="extra-documents-text">
            <p>
              Information is collected from or about you from various sources,
              as described below.
            </p>
          </div>
          <div className="extra-documents-small-header bulleted-text">
            <h2>A. INFORMATION YOU PROVIDE</h2>
          </div>
          <div className="extra-documents-small-header bulleted-text extra-bulleted-text">
            <h2>1. REGISTRATION INFORMATION</h2>
          </div>
          <div className="extra-documents-text extra-documents-bullet-points extra-documents-extra-bullet-points">
            <p>
              When you sign up for a Festiv account, we ask for information such
              as your name, email address, postal address, telephone number,
              birth date, and demographic information. If you sign up using a
              social media account, we may also receive information from those
              social networking services, for example, your name or username. If
              you register to receive copies of Festiv blog posts, we will also
              collect your email address.
            </p>
          </div>
          <div className="extra-documents-small-header bulleted-text extra-bulleted-text">
            <h2>2. PAYMENT AND SHIPPING INFORMATION</h2>
          </div>
          <div className="extra-documents-text extra-documents-bullet-points extra-documents-extra-bullet-points">
            <p>
              When you add a credit card or payment method to your account or
              place an order with us, we will collect your payment information
              and billing address, and (if you make a product purchase), your
              shipping information.
            </p>
          </div>
          <div className="extra-documents-small-header bulleted-text extra-bulleted-text">
            <h2>3. OTHER CONTACT INFORMATION</h2>
          </div>
          <div className="extra-documents-text extra-documents-bullet-points extra-documents-extra-bullet-points">
            <p>
              If you use product features with text messaging or email, Festiv
              will also collect the phone number, email address, and any other
              contact information you provide.
            </p>
          </div>
          <div className="extra-documents-small-header bulleted-text extra-bulleted-text">
            <h2> 4. COMMUNICATIONS</h2>
          </div>
          <div className="extra-documents-text extra-documents-bullet-points extra-documents-extra-bullet-points">
            <p>
              If you contact us directly, we may receive additional information
              you provide, such as your name, email address and phone number,
              and the contents of a message or attachments that you may send to
              us. You may also provide us with information if you subscribe to
              marketing communications from us or to obtain content such as
              product information, whitepapers and reports.
            </p>
          </div>
          <div className="extra-documents-small-header bulleted-text extra-bulleted-text">
            <h2>5. INFORMATION YOU PROVIDE ABOUT OTHERS</h2>
          </div>
          <div className="extra-documents-text extra-documents-bullet-points extra-documents-extra-bullet-points">
            <p>
              We also collect the personal information of other people, for
              example, if you provide email addresses of friends for referrals
              or postal addresses of recipients for gift purchases.
            </p>
          </div>
          <div className="extra-documents-small-header bulleted-text">
            <h2>
              B. INFORMATION WE COLLECT AUTOMATICALLY THROUGH THE VARIOUS FESTIV
              WEBSITES AND SERVICES
            </h2>
          </div>
          <div className="extra-documents-small-header bulleted-text extra-bulleted-text">
            <h2>1. DEVICE INFORMATION</h2>
          </div>
          <div className="extra-documents-text extra-documents-bullet-points extra-documents-extra-bullet-points">
            <p>
              We may receive information about the browser and devices you use
              to access the Internet, including our services, such as device
              types and models, unique identifiers (including, Devices, the
              Advertising Identifier associated with that device), IP address,
              operating system type and version, browser type and language,
              Wi-Fi network name and connection data, and information about
              other devices connected to the same network. For Devices, we may
              also collect the name of the retailer to whom your Device was
              shipped, various quality measures, error logs, software version
              numbers, and device status (including the status of
              battery-powered accessories). When you enable Bluetooth on a
              Device, we may collect your Bluetooth usage, such as connection
              quality, the name of the device connected to your Device, and the
              start and stop time of your connection.
            </p>
          </div>
          <div className="extra-documents-small-header bulleted-text extra-bulleted-text">
            <h2>2. INFORMATION FROM COOKIES AND SIMILAR TECHNOLOGIES</h2>
          </div>
          <div className="extra-documents-text extra-documents-bullet-points extra-documents-extra-bullet-points">
            <p>
              We and our business partners collect information on websites and
              in our emails using cookies, pixel tags, or similar technologies.
              We and our business partners, such as analytics and advertising
              partners, may use these technologies to collect information about
              your online activities over time and across different online
              services. For more information on how we and others use cookies
              and related technologies on Festiv Sites, and your choices
              regarding the use of these technologies for tracking on the Festiv
              Sites, please review the Festiv Cookies Policy.
            </p>
          </div>
          <div className="extra-documents-small-header bulleted-text extra-bulleted-text">
            <h2>
              3. ACTIVITY AND USAGE INFORMATION ON FESTIV SITES, FESTIV&#x27;S
              MOBILE APPS, FESTIV&#x27;S CHANNELS, AND DEVICES USED
            </h2>
          </div>
          <div className="extra-documents-text extra-documents-bullet-points extra-documents-extra-bullet-points">
            <p>
              We receive information about your interactions with the Festiv
              Services, such as your browsing history, search history, search
              results, audio information when you use voice-enabled features,
              channels you access (including usage statistics such as what
              channels you access, the time you access them, and how long you
              spend viewing them), interactions with content and ads, and
              settings and preferences.When you access Festiv and Festiv Partner
              Festivals, we receive information about your activities like the
              videos and other content you select and view within these
              channels. If you use the Festiv website to send your video or
              photo files or send your music files, Festiv will collect data
              about the files delivered within the Festiv website, such as
              codecs, and other metadata of the local files you send through the
              Festiv website.When you access Festiv via a Smart TV, we use
              Automatic Content Recognition (<strong>“ACR”</strong>) technology
              to receive information about what content you watch via the Festiv
              website, and what you watch via other devices with the Festiv app
              installed.  For example, we receive viewing information such as
              the films and other content you view, the date, time and duration
              of the viewing. We receive viewing information both when you
              access Festiv directly through your Smart TV’s interface and when
              you access Festiv via a Third-Party device. If the Festiv is
              enabled on your Smart TV, we will use this information to
              personalize your Festiv content viewing experience and
              ads.Festiv’s mobile apps may, or may not, need permissions to
              access the content and other information stored on your mobile
              device.
            </p>
          </div>
          <div className="extra-documents-small-header bulleted-text extra-bulleted-text">
            <h2>
              4. ACTIVITY, LOCATION AND USAGE INFORMATION THROUGH FESTIV&#x27;S
              ADVERTISING SERVICES
            </h2>
          </div>
          <div className="extra-documents-text extra-documents-bullet-points extra-documents-extra-bullet-points">
            <p>
              We may receive information about your activities on other
              websites, apps, and connected devices (including Smart TVs) to
              which Festiv may provide advertising or measurement and analytics
              services, including the content you view, the date and time of
              your visits, how you interact with these websites, apps and
              devices, and how you interact and respond to ads. We may also
              receive your precise geolocation information.
            </p>
          </div>
          <div className="extra-documents-small-header bulleted-text">
            C. INFORMATION WE RECEIVE FROM THIRD PARTIES
          </div>
          <div className="extra-documents-text extra-documents-bullet-points">
            <p>
              We may receive data about you from other sources like data
              providers and combine it with the data that we collect from you or
              your use of the Festiv Services. Advertisers that show ads through
              Festiv’s advertising services (our <strong>“Advertisers”</strong>)
              may also provide data about individuals to us to personalize and
              measure ads through Festiv’s advertising services.
            </p>{" "}
            We may also receive your personal information from others if they
            have referred you as described under Part I, Section A(5) above.
          </div>
          <div className="extra-documents-small-header bulleted-text">
            <h2>D. INFORMATION COLLECTED BY CHANNEL PROVIDERS</h2>
          </div>
          <div className="extra-documents-text extra-documents-bullet-points">
            <p>
              If you stream a Third-Party Festival or Festiv Partner Festival
              (i.e., a channel co-branded with the Festiv logo on the festival’s
              home page), or if you conduct a voice search on a device to access
              one of these festivals, you are interacting with that festival
              directly, and the festival provider and its advertising partners
              may collect information about your Devices (including unique
              identifiers, IP addresses, and device settings like language and
              time zone), your Festiv account registration information (if you
              choose to share that with them), and your viewing of that festival
              (e.g., films, ads and other content you search, select and view
              within their festival, and audio information when you use some of
              the voice-enabled features), as described in the privacy policy
              for that festival. You should review the privacy policy for the
              festival you want to stream to understand how your data is used.
              In addition, we also receive information about your interactions
              with the Festiv Partner Festivals, as described in Part I, Section
              B(3) above.
            </p>
          </div>
          <div className="extra-documents-small-header bulleted-text">
            <h2>E. INFORMATION COLLECTED BY VOICE ASSISTANT PROVIDERS</h2>
          </div>
          <div className="extra-documents-text extra-documents-bullet-points">
            <p>
              If you link your Device to a non-Festiv voice-enabled virtual
              assistant service (e.g., Alexa and Google Assistant), and use it
              to control your Device, you are interacting with that voice
              service directly and the provider of the service will record your
              voice and share this information with us, and we will use that
              information as described in this Privacy Policy. For more
              information about how these providers may use your voice data,
              please review their privacy policies.
            </p>
          </div>
          <div className="extra-documents-small-header">
            <h2>PART II. INFORMATION USAGE</h2>
          </div>
          <div className="extra-documents-text">
            <h3>
              Information collected by Festiv is used for the following
              purposes:
            </h3>
          </div>
          <div className="extra-documents-small-header bulleted-text">
            <h2>1. SUPPORT, MAINTENANCE AND FULFILLMENT.</h2>
          </div>
          <div className="extra-documents-text extra-documents-bullet-points">
            <p>
              We use your information to provide and maintain the Festiv
              Services, and to process and fulfill your requests or orders;
            </p>
          </div>
          <div className="extra-documents-small-header bulleted-text">
            <h2>2. IMPROVEMENT OF PRODUCTS AND SERVICES.</h2>
          </div>
          <div className="extra-documents-text extra-documents-bullet-points">
            <p>
              We use your information to understand and analyze our user base
              and how you use the Festiv Services, and to improve and enhance
              the Festiv Services (including building correlations in Festiv’s
              advertising services in order to better serve our Advertisers),
              and to develop new products, services, features and functionality;
            </p>
          </div>
          <div className="extra-documents-small-header bulleted-text">
            <h2>3. PERSONALIZATION.</h2>
          </div>
          <div className="extra-documents-text extra-documents-bullet-points">
            <p>
              We use your information to personalize your experience on the
              Festiv website, including suggesting and offering you relevant
              content and recommendations;
            </p>
          </div>
          <div className="extra-documents-small-header bulleted-text">
            <h2>4. ADVERTISING SERVICES.</h2>
          </div>
          <div className="extra-documents-text extra-documents-bullet-points">
            <p>
              We use your information to show you ads (including personalized
              ads) through the Festiv Services, on Third-Party Partners, and on
              third-party websites, mobile apps, platforms and devices. We use
              your information to measure and understand the reach, viewership,
              and effectiveness of advertising, and provide advertising
              analytics and reporting. We also help Advertisers and advertising
              partners reach the desired audience and understand and improve
              their ad campaigns. We associate the browsers and devices (such as
              smartphones, tablets, streaming players, connected TVs, and
              computers) used by the same individual or household for purposes
              of advertising to that individual or household on different
              browsers or devices. This allows, for example, ads you see on your
              tablet to be based on activities you engaged in on your Smart TV.
            </p>
          </div>
          <div className="extra-documents-small-header bulleted-text">
            <h2>5. MARKETING AND PROMOTIONS.</h2>
          </div>
          <div className="extra-documents-text extra-documents-bullet-points">
            <p>
              We use your information for marketing purposes, including sending
              you emails and text messages about products, events, promotions
              and offers from Festiv or its partners or advertisers, and
              understanding the effectiveness of our marketing;
            </p>
          </div>
          <div className="extra-documents-small-header bulleted-text">
            <h2>6. ANALYTICS AND PERFORMANCE.</h2>
          </div>
          <div className="extra-documents-text extra-documents-bullet-points">
            <p>
              We use your information to measure performance and analyze key
              metrics relevant to our business;
            </p>
          </div>
          <div className="extra-documents-small-header bulleted-text">
            <h2>7. SERVICE AND CUSTOMER CARE.</h2>
          </div>
          <div className="extra-documents-text extra-documents-bullet-points">
            <p>
              We use your information to communicate with you, including sending
              you service information such as confirmations, invoices, notices,
              updates, security alerts, user surveys, and support and
              administrative messages; and to respond to your comments and
              questions and provide customer service;
            </p>
          </div>
          <div className="extra-documents-small-header bulleted-text">
            <h2>8. PROTECT FESTIV, OUR USERS AND OTHERS.</h2>
          </div>
          <div className="extra-documents-text extra-documents-bullet-points">
            <p>
              We use your information to enforce our terms and conditions or
              protect our business, partners or users;
            </p>
          </div>
          <div className="extra-documents-small-header bulleted-text">
            <h2>9. SECURITY AND FRAUD PREVENTION.</h2>
          </div>
          <div className="extra-documents-text extra-documents-bullet-points">
            <p>
              We use your information to protect, investigate, and deter against
              fraudulent, unauthorized, infringing or illegal activity,
              including click fraud.
            </p>
          </div>
          <div className="extra-documents-small-header">
            PART III. INFORMATION SHARING
          </div>
          <div className="extra-documents-small-header bulleted-text">
            <h2>A. YOUR SHARING</h2>
          </div>
          <div className="extra-documents-text extra-documents-bullet-points">
            <p>
              You can share reviews or feedback on the Festiv Sites and comments
              and content on Festiv’s forums, blogs, message boards, community
              pages, and accounts on third-party social networking sites.
              Information posted in these venues may be available to the public.
              You can also share with us personal information of other people as
              described in “Information You Provide” under Part I, Section A
              above (e.g., for referrals or gift purchases). We rely on you to
              obtain the permission of those other people to allow us to collect
              their personal information. Please be mindful of your own privacy
              needs and those of others as you choose what to share and make
              public. We cannot control the privacy or security of information
              you choose to make public.
            </p>
            <p>
              You can also choose to share your Festiv account registration
              information with providers of Third-Party Partners and Festiv
              Partner Festivals.
            </p>
          </div>
          <div className="extra-documents-small-header bulleted-text">
            <h2>B. OUR SHARING</h2>
          </div>
          <div className="extra-documents-small-header bulleted-text extra-bulleted-text">
            <h2>1. SERVICE PROVIDERS AND VENDORS</h2>
          </div>
          <div className="extra-documents-text extra-documents-bullet-points extra-documents-extra-bullet-points">
            <p>
              We work with other businesses (including contractors and service
              providers) to provide services for us and to help with our
              operations, which may require that they access and use your
              information. For example, we may use other companies to
              communicate with you (via telephone, email, or letter) about our
              products or services, to send information to you, to process and
              collect payment via your credit card (if applicable), to provide
              customer support, to receive additional data about you, and to
              perform analytics and other work that we may need to outsource.
            </p>
          </div>
          <div className="extra-documents-small-header bulleted-text extra-bulleted-text">
            <h2>2. FESTIVAL CONTENT PROVIDERS</h2>
          </div>
          <div className="extra-documents-text extra-documents-bullet-points extra-documents-extra-bullet-points">
            <p>
              We share information with partners whose services are available
              through the Festiv website such as providers of Third-Party
              Partners and Festiv Partner Festivals. This information is shared
              for different purposes, including for reporting and analytics
              (e.g., report on the total number of channel installations or the
              number of hours streamed), providing service to you, tailoring
              your user experience, and personalizing ads to you.If you
              subscribe to a Festiv Partner Festival from these partners on your
              device or through the Festiv website and you do not wish to type
              in your information using a hand-held device, we can share your
              Festiv account registration information with the festival channel
              provider to save you time. For more information about how these
              channel providers will use your data, please consult their
              respective privacy policies.
            </p>
          </div>
          <div className="extra-documents-small-header bulleted-text extra-bulleted-text">
            <h2>3. ANALYTICS PROVIDERS</h2>
          </div>
          <div className="extra-documents-text extra-documents-bullet-points extra-documents-extra-bullet-points">
            <p>
              We use analytics services such as Google Analytics to collect and
              process certain analytics data for Festiv Services. These
              analytics services may also collect information regarding your use
              of other companies’ websites, apps, online services, and devices.
              For example, you can learn about Google’s practices by going to{" "}
              <a href="https://www.google.com/policies/privacy/partners/">
                https://www.google.com/policies/privacy/partners/
              </a>
              , and opt out of them by downloading the Google Analytics opt-out
              browser add-on, available at{" "}
              <a href="https://tools.google.com/dlpage/gaoptout">
                https://tools.google.com/dlpage/gaoptout
              </a>
              .
            </p>
          </div>
          <div className="extra-documents-small-header bulleted-text extra-bulleted-text">
            <h2>4. ADVERTISERS AND ADVERTISING PARTNERS</h2>
          </div>
          <div className="extra-documents-text extra-documents-bullet-points extra-documents-extra-bullet-points">
            <p>
              We work with advertising partners to show you ads that we think
              may interest you for Festiv’s products and services on Festiv
              Services and on other companies’ websites, apps, and devices. We
              also work with advertising partners in order to show you ads from
              Advertisers on Festiv Services and on other companies’ websites,
              apps, and devices. We share information with these advertising
              partners and our Advertisers, and they may set and access their
              own cookies, pixel tags and similar technologies on the Festiv
              Services and they may otherwise collect or have access to
              information about you which they collect over time and across
              different online services and devices.
            </p>
          </div>
          <div className="extra-documents-small-header bulleted-text extra-bulleted-text">
            <h2>5. ADVERTISING MEASUREMENT PROVIDERS AND SERVICES</h2>
          </div>
          <div className="extra-documents-text extra-documents-bullet-points extra-documents-extra-bullet-points">
            <p>
              We work with advertising measurement providers to help us and our
              Advertisers understand the effectiveness of ad campaigns,
              including the audience that saw their ads and how they responded.
              These measurement providers collect or receive information about
              your viewing of or interaction with ads, including ads that you
              view within Festiv Partner Festivals, Festiv’s website sections
              and Third-Party Partners, as well as ads included in content that
              you view in Festiv through your Smart TV’s antenna and connected
              devices (if you enabled Festiv’s Smart TV Experience as described
              above in Part I, Section B(3)), and ads you view on other
              websites, mobile apps, and connected devices (including Smart TVs)
              to which Festiv provides advertising services. We may also share
              your demographics data and audience segments (e.g. sports fans,
              sitcom enthusiasts, cord cutters, etc.) with advertising
              measurement providers.
            </p>
            <p>
              Measurement providers use your information to create statistics
              about what content and ads are being watched – for example, to
              help them and their clients understand the audience viewing the
              content or ads, and to measure the effectiveness of the ads.
            </p>

            <p>
              Festiv, through its advertising services, provides measurement and
              analytics solutions to other companies. In providing these
              services, we analyze and reorganize ad campaign data from these
              companies and provide analytics reports to help them better
              understand the audience that viewed the company’s ads and whether
              those viewers belong to the same household.
            </p>
          </div>
          <div className="extra-documents-small-header bulleted-text extra-bulleted-text">
            <h2>6. VOICE ASSISTANT PROVIDERS</h2>
          </div>
          <div className="extra-documents-text extra-documents-bullet-points extra-documents-extra-bullet-points">
            <p>
              If you link your Device to a non-Festiv voice-enabled virtual
              assistant (e.g., Alexa and Google Assistant), you are choosing to
              have us share limited device data with the voice assistant
              provider, such as device type, its powered state, and the names of
              its HDMI ports. For information about how these providers use this
              data, please review their privacy policies.
            </p>
          </div>
          <div className="extra-documents-small-header bulleted-text extra-bulleted-text">
            <h2>7. CORPORATE AFFILIATES</h2>
          </div>
          <div className="extra-documents-text extra-documents-bullet-points extra-documents-extra-bullet-points">
            <p>
              We may share your information with our current or future
              “affiliates” (which means a parent company, any subsidiaries,
              joint ventures, or other companies under common control), in which
              case we will require our affiliates to honor this Privacy Policy.
            </p>
          </div>
          <div className="extra-documents-small-header bulleted-text extra-bulleted-text">
            <h2>8. CONSNET</h2>
          </div>
          <div className="extra-documents-text extra-documents-bullet-points extra-documents-extra-bullet-points">
            <p>
              We also may share personally identifying information with your
              consent, for example, when you agree to our sharing of your
              personally identifying information with other third parties for
              their own marketing purposes, subject to their separate privacy
              policies.
            </p>
          </div>
          <div className="extra-documents-small-header bulleted-text extra-bulleted-text">
            <h2>9. MERGER, SALE, OR OTHER ASSET TRANSFERS</h2>
          </div>
          <div className="extra-documents-text extra-documents-bullet-points extra-documents-extra-bullet-points">
            <p>
              We may also share your information with others in connection with
              or during negotiation of any merger, financing, acquisition,
              bankruptcy, dissolution, transaction or proceeding involving sale,
              transfer, divestiture or disclosure of all or a portion of our
              business or assets to another company.
            </p>
          </div>
          <div className="extra-documents-small-header bulleted-text extra-bulleted-text">
            <h2>10. AS REQUIRED BY LAW AND SIMILAR DISCLOSURES</h2>
          </div>
          <div className="extra-documents-text extra-documents-bullet-points extra-documents-extra-bullet-points">
            <p>
              We may also share your information (1) to comply with laws or to
              respond to lawful requests and legal process, (2) to protect the
              rights and property of Festiv, our employees, agents, customers,
              and others, including to enforce our agreements, policies, and
              terms of use, (3) in an emergency to protect the personal safety
              of Festiv, its employees or customers, or any person, or (4) to
              investigate, prevent, or take action regarding illegal or
              suspected illegal activities.
            </p>
          </div>
          <div className="extra-documents-small-header bulleted-text extra-bulleted-text">
            <h2>11. AGGREGATED OR DE-IDENTIFIED INFORMATION</h2>
          </div>
          <div className="extra-documents-text extra-documents-bullet-points extra-documents-extra-bullet-points">
            <p>
              We may also share aggregated or de-identified information (i.e.,
              information that does not personally identify you directly), or
              statistical information about you, including demographics data and
              audience segments (e.g., sports fans, sitcom enthusiasts, cord
              cutters, etc.), with others for a variety of purposes, including
              for their own uses, for example, for improving their products and
              services for us and others, and to make ads more relevant to your
              interests.
            </p>
          </div>
          <div className="extra-documents-small-header">
            <h2>PART IV. INFORMATION AND ACCESS CHOICES</h2>
          </div>
          <div className="extra-documents-small-header bulleted-text">
            <h3>A. GENERAL REQUESTS</h3>
          </div>
          <div className="extra-documents-text extra-documents-bullet-points">
            <p>
              If you have a Festiv account, you may view and update certain
              contact and billing information we have about you by logging into
              your account on <a href="http://www.roku.com/">Festivmedia.com</a>
              . If you otherwise wish to ask for access, correction, or deletion
              of any of your personal information held by us, please contact us
              at <a href="mailto:info@festivmedia.com">info@festivmedia.com</a>.
              However, we may decline requests that are unreasonable, prohibited
              by law, or are not required to be honored by applicable law.
            </p>
          </div>
          <div className="extra-documents-small-header bulleted-text">
            <h3>B. EMAIL MARKETING CHOICES</h3>
          </div>
          <div className="extra-documents-text extra-documents-bullet-points">
            <p>
              Our marketing emails tell you how to opt out of receiving further
              marketing emails. If you opt out, we may still send you
              non-marketing emails, such as emails about your accounts and our
              business dealings with you, and, as allowed by applicable law,
              requests for your participation in surveys. You may also contact
              us at any time to let us know that you no longer wish to receive
              further marketing emails from us.
            </p>
          </div>
          <div className="extra-documents-small-header bulleted-text">
            <h3>C. PERSONALIZED ADVERTISING CHOICES</h3>
          </div>
          <div className="extra-documents-text extra-documents-bullet-points extra-documents-extra-bullet-points">
            <ul>
              <li>
                You can ask Festiv to stop personalizing your ads on mobile apps
                by accessing any settings offered by your mobile operating
                system to limit ad tracking.
              </li>
              <li>
                You can ask Festiv to stop personalizing your ads on websites by
                accessing the opt-out.
              </li>
              <li>
                You can ask Festiv to stop personalizing the ads during your
                Festiv streaming experience, by selecting “Limit Ad Tracking” in
                your Settings. If you are streaming a Third-Party Festival, that
                channel may not respond to the Limit Ad Tracking setting. You
                should review the privacy policy provided by the Third-Party
                Festival to understand your choices. You can also reset the
                Advertising Identifier of your Device by choosing “Reset
                Advertising Identifier” in your Device settings.
              </li>
              <li>
                To ask Festiv to stop personalizing your ads on Smart TVs, set
                top boxes, media streaming devices and other OTT platforms
                offered by other companies, you can use the Limit Ad Tracking
                setting on that platform, if one is available.
              </li>
            </ul>
          </div>
          <div className="extra-documents-text extra-documents-bullet-points">
            <p>
              Each of these opt-out mechanisms opts you out of Festiv’s
              personalizing of ads only for that specific device or browser and
              will not affect activities or personalized advertising on other
              browsers or different devices.You can opt out of personalized ads
              from third parties on the Festiv Site through our opt-out tool
              included in Festiv’s cookie policy. This tool opts you out of
              personalized advertising only for that specific browser from which
              you opt out and will not affect activities or targeted advertising
              on other browsers or different devices.
            </p>
          </div>
          <div className="extra-documents-small-header bulleted-text">
            <h3>
              D. CHOICES REGARDING &quot;SMART TV EXPERIENCE&quot; AND AUTOMATIC
              CONTENT RECOGNITION (ACR) ON FESTIV TV&#x27;S
            </h3>
          </div>
          <div className="extra-documents-text extra-documents-bullet-points">
            <p>
              If you would like to disable the Smart TV Experience on your
              Festiv website, please visit your Smart TV’s Settings menu and
              de-select “Use Info from TV Inputs”.
            </p>
          </div>
          <div className="extra-documents-small-header bulleted-text">
            <h3>E. VOICE FEATURE CHOICES</h3>
          </div>
          <div className="extra-documents-text extra-documents-bullet-points">
            <p>
              Visit the Settings menu of your Device with voice-activated
              features for controls regarding channel applications’ access to
              the microphone on your remote control or mobile device.
            </p>
          </div>
          <div className="extra-documents-small-header bulleted-text">
            <h3>F. DO NOT TRACK</h3>
          </div>
          <div className="extra-documents-text extra-documents-bullet-points">
            <p>
              At this time, there is no accepted standard for how to respond to
              Do Not Track signals, and we do not respond to such signals.
            </p>
          </div>
          <div className="extra-documents-small-header">
            <h2>PART V. DATA STORAGE, TRANSFERS, AND SECURITY</h2>
          </div>
          <div className="extra-documents-small-header bulleted-text">
            <h3>A. SECURITY:</h3>
          </div>
          <div className="extra-documents-text extra-documents-bullet-points">
            <p>
              We use industry-standard methods of securing electronic databases
              of personal information. However, you should know that no company,
              including Festiv, can fully eliminate security risks associated
              with personal information. To help protect yourself, please use a
              strong password, do not use the same password to access your
              Festiv account that you use with other accounts or services, and
              protect your usernames and passwords to help prevent others from
              accessing your accounts and services.
            </p>
          </div>
          <div className="extra-documents-small-header bulleted-text">
            <h3>B. TRANSFERS:</h3>
          </div>
          <div className="extra-documents-text extra-documents-bullet-points">
            <p>
              Festiv is a global company with affiliates, varied business
              processes, management structures and technical systems that cross
              borders. Information collected by Festiv or on our behalf may be
              stored on your Devices, on your mobile device if you use Festiv’s
              mobile apps, or on our servers, and may be transferred to,
              accessed from, or stored and processed globally in any other
              country where Festiv or its service providers maintain facilities
              or call centers, including jurisdictions that may not have data
              privacy laws that provide protections equivalent to those provided
              in your home country. While such information is outside of your
              country of residence, it is subject to the laws of the country in
              which it is held, and may be subject to disclosure to the
              governments, courts or law enforcement or regulatory agencies of
              such other country, pursuant to the laws of such country. We will
              protect all personal information we obtain in accordance with this
              Privacy Policy and take reasonable steps to ensure that it is
              treated lawfully.
            </p>
          </div>
          <div className="extra-documents-small-header bulleted-text">
            <h3>C. RETENTION</h3>
          </div>
          <div className="extra-documents-text extra-documents-bullet-points">
            <p>
              Personal information we collect may be retained for as long as
              needed to fulfill legitimate business purposes, including the
              purposes outlined in “Information Usage” under Part II, or for a
              time period specifically required or allowed by applicable
              regulations or laws.
            </p>
          </div>
          <div className="extra-documents-small-header">
            <h2>PART VI. ADDITIONAL NOTICE FOR CALIFORNIA RESIDENTS</h2>
          </div>
          <div className="extra-documents-small-header bulleted-text">
            <h3>A. CALIFORNIA CONSUMER PRIVACY ACT (&quot;CCPA&quot;)</h3>
          </div>
          <div className="extra-documents-text extra-documents-bullet-points">
            <p>
              The CCPA requires us to provide our consumers who reside in
              California with the additional notice below.In the prior 12 months
              before the date of this Privacy Policy, we may have collected and
              disclosed for a business purpose the following categories of
              personal information:
            </p>
          </div>
          <div className="extra-documents-text extra-documents-bullet-points extra-documents-extra-bullet-points">
            <ul>
              <li>
                Identifiers, including account IDs, household ID, device
                identifiers, internet protocol addresses, browser cookies, and
                other unique online identifiers.
              </li>
              <li>
                Account Records, including name, address, email address,
                telephone number, and payment card number.
              </li>
              <li>
                Protected Classifications under California or Federal Law.
              </li>
              <li>
                Commercial Information, including products or services
                purchased, obtained, or considered, or other purchasing or
                consuming histories or tendencies.
              </li>
              <li>
                Electronic Network Activity, Internet or other electronic
                network activity information, including browsing history, search
                history, and information regarding a consumer’s interaction with
                an Internet website, application, service or advertisement.
              </li>
              <li>Geolocation data.</li>
              <li>
                Profiles and Inferences, inferences drawn from any personal
                information to create a profile about a consumer reflecting the
                consumer’s preferences, characteristics, predispositions,
                behavior, and attitudes.
              </li>
              <li>
                Audio/Visual Information, including audio, electronic, visual,
                or similar information.
              </li>
              <li>Professional/Employment Information.</li>
            </ul>
          </div>
          <div className="extra-documents-text extra-documents-bullet-points">
            <p>
              But, we only disclose Account Records to contractors and service
              providers to help with our business operations and carry out work
              that we need to outsource (see Part III, Section B(1) above), and
              to business partners at your direction (for example, if you
              subscribe to a channel on device, you can authorize us to share
              your Festiv account information with the channel provider to avoid
              entering your information using the remote and to expedite the
              sign-up process).In the prior 12 months before the date of this
              Privacy Policy, we may have shared Identifiers, Protected
              Classifications, Commercial Information, Electronic Network
              Activity, Profiles and Inferences, and Professional/Employment
              Information with business partners to bring you ads that are more
              relevant and to measure and report on the effectiveness of ad
              campaigns. Under the CCPA, some of this data sharing may be
              broadly considered a “sale” of information even though these
              activities do not fit what most people would understand “sale” to
              mean. Except for this type of sharing, we do not sell your
              information.Part I of this Privacy Policy, “Information
              Collection”, describes the categories of sources from which we
              collect your personal information. Part II, Section A,
              “Information Usage, Use by or on Behalf of Festiv,” describes how
              we use your personal information. Part III, Section B,
              “Information Sharing, Festiv’s Sharing” sets forth the categories
              of service providers and business partners with whom we share your
              personal information.If you are a resident of California, you have
              the right to make the following requests, up to twice every 12
              months:
            </p>
          </div>
          <div className="extra-documents-text extra-documents-bullet-points extra-documents-extra-bullet-points">
            <ul>
              <li>
                The right to request a copy of the personal information that we
                have collected about you in the prior 12 months.
              </li>
              <li>
                The right to request details about the categories of personal
                information we collect, the categories of sources, the business
                or commercial purposes for collecting information, and the
                categories of third parties with which we share information.
              </li>
              <li>
                The right to request deletion of the personal information that
                we have collected about you, subject to certain exemptions.
              </li>
              <li>
                The right to opt-out of sale of your personal information.
              </li>
            </ul>
          </div>
          <div className="extra-documents-text extra-documents-bullet-points">
            <p>
              California consumers can submit a deletion and right-to-know
              request online by emailing us at info@festivmedia.com. To exercise
              the right to opt out, please visit our Do Not Sell page. Your
              rights are subject to certain exceptions under the CCPA.The CCPA
              prohibits discrimination against California consumers for
              exercising their rights under it and imposes requirements on any
              financial incentives offered to California consumers related to
              their personal information, unless the different prices, rates, or
              quality of goods or services are reasonably related to the value
              of the consumer’s data.
            </p>
          </div>
          <div className="extra-documents-small-header bulleted-text">
            <h3>B. CALIFORNIA&#x27;S SHINE-THE-LIGHT-LAW</h3>
          </div>
          <div className="extra-documents-text extra-documents-bullet-points">
            <p>
              California’s “Shine the Light” law (Cal. Civ. Code § 1798.83)
              permits California residents to request and receive, once per
              calendar year and free of charge, information about our sharing of
              your personal information (if any) with third parties for their
              own direct marketing use. If you are a California resident and
              would like to make such a request, please email{" "}
              <a href="mailto:info@festivmedia.com">info@festivmedia.com</a>. In
              your request, please include your name and attest to the fact that
              you are a California resident and provide a current California
              address. We may ask you for additional information to confirm your
              identity before responding to this request.
            </p>
          </div>
          <div className="extra-documents-small-header">
            <h2>PART VII. MODIFICATIONS</h2>
          </div>
          <div className="extra-documents-text">
            <p>
              We may amend this Privacy Policy at any time by posting the
              amended version on the applicable Festiv website or by providing
              such notice about or obtaining consent to changes as may be
              required by applicable law.
            </p>
          </div>
        </div>
      </div>
      <Footer />
    </div>
  );
}
